import React, { Fragment, FunctionComponent, useEffect } from 'react'
import { AdapterLink, ScheduledSurveyList, BusyCircular } from '../components'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core'
import { factSheetUrl, factUrl, surveyInstructionsUrl } from '../routes'
import { feedbackSurveyID, SCHEDULED_SURVEYS_FRAGMENT } from '../utils'
import { gql } from 'graphql.macro'
import { Lock, LockOpenVariant } from 'mdi-material-ui'
import {
  Maybe,
  useFactsheetModulesMutation,
  useFactsheetUserDashboardQuery,
} from '../types'

export const FACTSHEET_USER_DASHBOARD = gql`
  query FactsheetUserDashboard($surveyID: ID!) {
    whoami {
      id
      person {
        id
        firstName
      }
    }
    surveyStats(surveyID: $surveyID) {
      id
      completions
    }
    modulesStatus {
      id
      selected
      unlocked
      module {
        id
        name
      }
    }
    scheduledSurveys {
      ...scheduledSurveys
    }
  }
  ${SCHEDULED_SURVEYS_FRAGMENT}
`

export const FEEDBACK_USER_MODULE_SELECTION = gql`
  mutation FactsheetModules {
    selectModules(moduleIDs: [])
  }
`

export const FactDashboard: FunctionComponent<{ survey: Maybe<string> }> = ({
  survey,
}) => {
  const { data, loading, error } = useFactsheetUserDashboardQuery({
    variables: { surveyID: feedbackSurveyID },
  })
  const [selectModules] = useFactsheetModulesMutation({
    refetchQueries: ['FactsheetUserDashboard'],
  })

  const firstName = data?.whoami?.person?.firstName
  const surveyCount = data?.surveyStats.completions
  const modules = data?.modulesStatus
  const scheduledSurveys = data?.scheduledSurveys

  useEffect(() => {
    if (
      !loading &&
      surveyCount &&
      modules &&
      surveyCount > 0 &&
      modules.length === 0
    ) {
      selectModules()
    }
  }, [loading, surveyCount, modules, selectModules])

  const pageTitle = (
    <Typography variant="h1" paragraph>
      Welcome to your personal dashboard, {firstName}
    </Typography>
  )

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  if (
    process.env.REACT_APP_LEGACY_SURVEY_INSTRUCTIONS === 'true' &&
    surveyCount === 0
  ) {
    return (
      <Box py="2">
        {pageTitle}
        <Typography paragraph>
          To get started you need to complete a survey.
        </Typography>
        <Button
          component={AdapterLink}
          href={`${factUrl}${surveyInstructionsUrl}`}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          Click here to start the survey
        </Button>
      </Box>
    )
  }

  return (
    <Fragment>
      {pageTitle}
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      <ScheduledSurveyList baseUrl={factUrl} surveys={scheduledSurveys} />
      {survey ? (
        <Fragment>
          <Typography paragraph>
            Thank you for completing the survey! Below you can access the first
            of your 5 factsheets about teenagers’ development and well-being.
          </Typography>
          <Typography paragraph>
            After this, one factsheet will ‘unlock’ each week. To view the
            factsheets, click on the buttons below. Once a factsheet is
            ‘unlocked’, you can revisit it as many times as you like.
          </Typography>
          <Typography paragraph>
            Each factsheet takes about 5-10 minutes to read, and will cover a
            different topic each week. We encourage you to read one factsheet
            each week, to allow yourself time to absorb the information before
            moving on to the next topic.
          </Typography>
        </Fragment>
      ) : (
        <Fragment>
          <Typography paragraph>
            On this page, you can access your weekly factsheets. One factsheet
            will ‘unlock’ each week. To view the factsheets, click on the
            buttons below. Once a factsheet is ‘unlocked’, you can revisit it as
            many times as you like.
          </Typography>
          <Typography paragraph>
            Each factsheet takes about 5-10 minutes to read, and covers a
            different topic related to adolescent development and wellbeing. We
            encourage you to read one factsheet each week, to allow yourself
            time to absorb the information before moving on to the next topic.
          </Typography>
        </Fragment>
      )}
      <Box mb={2}>
        <Grid container spacing={3}>
          {modules?.map((module) => {
            const id = module.module?.id
            const name = module.module?.name
            return module.selected ? (
              <Grid key={module.id} item xs={6} sm={4} md={3}>
                <Card>
                  <CardActionArea
                    disabled={!module.unlocked}
                    component={AdapterLink}
                    href={`..${factSheetUrl}/${id}`}
                    target="__blank"
                  >
                    <Box clone minHeight={120}>
                      <CardHeader title={name} />
                    </Box>
                    <CardActions>
                      <Box ml="auto" mr="auto">
                        {!module.unlocked ? (
                          <Lock color="disabled" />
                        ) : (
                          <LockOpenVariant color="action" />
                        )}
                      </Box>
                    </CardActions>
                  </CardActionArea>
                </Card>
              </Grid>
            ) : null
          })}
        </Grid>
      </Box>
    </Fragment>
  )
}
