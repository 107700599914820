import React from 'react'
import { useField } from 'formik'
import { TextField } from '@material-ui/core'

export const FormikTextField = ({ error, helperText, ...props }) => {
  const [field, meta] = useField(props.name)
  const isError = meta.touched && meta.error ? true : false

  return (
    <TextField
      error={isError}
      helperText={isError ? meta.error : ' '}
      {...props}
      {...field}
    />
  )
}
