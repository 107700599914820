import * as Sentry from '@sentry/browser'
import * as serviceWorker from './serviceWorker'
import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { version } from '../package.json'
import 'react-app-polyfill/stable'

if (
  process.env.REACT_APP_TNO_MODE !== 'true' &&
  process.env.REACT_APP_TNO_MODE !== 'false'
) {
  throw new Error('REACT_APP_TNO_MODE does not appear to be set')
}

if (
  process.env.REACT_APP_CSV_EXPORT !== 'true' &&
  process.env.REACT_APP_CSV_EXPORT !== 'false'
) {
  throw new Error('REACT_APP_CSV_EXPORT does not appear to be set')
}

if (
  process.env.REACT_APP_EDIT_PAGES !== 'true' &&
  process.env.REACT_APP_EDIT_PAGES !== 'false'
) {
  throw new Error('REACT_APP_EDIT_PAGES does not appear to be set')
}

if (
  process.env.REACT_APP_EDIT_SURVEYS !== 'true' &&
  process.env.REACT_APP_EDIT_SURVEYS !== 'false'
) {
  throw new Error('REACT_APP_EDIT_SURVEYS does not appear to be set')
}

if (
  process.env.REACT_APP_EDIT_MODULES !== 'true' &&
  process.env.REACT_APP_EDIT_MODULES !== 'false'
) {
  throw new Error('REACT_APP_EDIT_MODULES does not appear to be set')
}

if (
  process.env.REACT_APP_HOW_TO !== 'true' &&
  process.env.REACT_APP_HOW_TO !== 'false'
) {
  throw new Error('REACT_APP_HOW_TO does not appear to be set')
}

if (
  process.env.REACT_APP_SINGLE_USER_TYPE_MODE !== 'true' &&
  process.env.REACT_APP_SINGLE_USER_TYPE_MODE !== 'false'
) {
  throw new Error('REACT_APP_SINGLE_USER_TYPE_MODE does not appear to be set')
}

if (
  process.env.REACT_APP_LEGACY_SURVEY_INSTRUCTIONS !== 'true' &&
  process.env.REACT_APP_LEGACY_SURVEY_INSTRUCTIONS !== 'false'
) {
  throw new Error(
    'REACT_APP_LEGACY_SURVEY_INSTRUCTIONS does not appear to be set',
  )
}

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://27522fe16a514c55b1f7fd905aadf586@sentry.io/1781011',
    environment: process.env.NODE_ENV,
    release: version,
  })
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
