import React from 'react'
import { Alert } from '@material-ui/lab'
import { feedbackSurveyID } from '../utils'
import { gql } from 'graphql.macro'
import { useLoadUserSurveyDataQuery } from '../types'

export const LOAD_USER_SURVEY_DATA = gql`
  query loadUserSurveyData($userID: ID!, $surveyID: ID!) {
    loadUserSurveyData(userID: $userID, surveyID: $surveyID) {
      id
      completed
      data
    }
  }
`

/**
 * Returns an <Alert/> detailing how many questions have been saved to the
 * database for the feedbackSurvey for a given userID. Used as a rough indicator
 * of how far through the initial survey a user is.
 */
export const InitialSurveyInfo = ({ userID }: { userID: string }) => {
  const { data, error, loading } = useLoadUserSurveyDataQuery({
    variables: { userID, surveyID: feedbackSurveyID },
  })

  const surveySavedDataKeyLength = Object.keys(
    JSON.parse(data?.loadUserSurveyData?.data ?? '{}'),
  ).length

  return (
    <div>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      {loading ? <Alert severity="info">Loading...</Alert> : null}
      {data ? (
        <Alert
          severity={data.loadUserSurveyData?.completed ? 'success' : 'warning'}
        >
          {surveySavedDataKeyLength} questions saved
        </Alert>
      ) : null}
    </div>
  )
}
