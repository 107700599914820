import React, { FunctionComponent, useContext } from 'react'
import { addDateKey } from '../../utils'
import { Card, CardContent, Fade, styled, Box } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { ModuleControlContext } from '../../pages'
import { TextCustom, TextCustomProps } from '.'

const HoverCard = styled(Card)({
  '&:hover': {
    background: grey[100],
    cursor: 'pointer',
  },
})

interface TextRevealProps extends TextCustomProps {
  id?: string
  header: JSX.Element
  mountOnEnter?: boolean
}

/**
 * React component for placing a single image in the document. Should scale the
 * image to fit the screen width if the screen is smaller than the image.
 */
export const TextReveal: FunctionComponent<TextRevealProps> = ({
  id,
  header,
  mountOnEnter,
  children,
  ...textCustomProps
}) => {
  const context = useContext(ModuleControlContext)
  const key = `TextReveal_${id ? id : header}`
  const keyData = context.moduleData?.[key]
  const clicked = keyData ? true : false

  function click() {
    if (!keyData) {
      addDateKey(key, context)
    }
  }

  return (
    <HoverCard onClick={click}>
      <CardContent>
        <Box mb={2}>
          <TextCustom variant="h5" {...textCustomProps}>
            {header}
          </TextCustom>
        </Box>
        <Fade in={clicked} timeout={1000} mountOnEnter={mountOnEnter}>
          <div>{children}</div>
        </Fade>
      </CardContent>
    </HoverCard>
  )
}
