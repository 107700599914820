import React, { useContext } from 'react'
import { Fade, Grid } from '@material-ui/core'
import { Img } from '.'
import { useInterval, ISOFormatString } from '../../utils'
import { ModuleControlContext } from '../../pages'
import { format } from 'date-fns'

interface ImageStepperItems {
  src: string
  alt?: string
  fullWidth?: boolean
}

interface ImageStepperProps {
  id?: string
  list: ImageStepperItems[]
  /** How long before the next image starts fading in. In Milliseconds. */
  sequenceSpeed: number
  /** How long the fade effect takes to transition from invisible to fully
   * visible. In milliseconds. */
  fadeSpeed: number
}

/**
 * React component for rendering a grid list of images where each item will fade
 * in after the previous one.
 */
export const ImageStepper = ({
  id,
  list,
  sequenceSpeed,
  fadeSpeed,
}: ImageStepperProps) => {
  const context = useContext(ModuleControlContext)
  const key = `ImageStepper_${id ? id : context.activeStep}`
  const keyData = context.moduleData?.[key]
  const [count, setCount] = React.useState(keyData ? list.length : 1)

  useInterval(() => {
    if (count <= list.length) {
      setCount(count + 1)
    }
    if (count === list.length && !keyData) {
      context.Update({
        [key]: format(new Date(), ISOFormatString),
        ...context.moduleData,
      })
    }
  }, sequenceSpeed)

  return (
    <Grid alignItems="center" justify="space-evenly" container spacing={3}>
      {list.map((item, index) => (
        <Grid
          key={index}
          xs={12}
          sm={item.fullWidth ? undefined : 6}
          md={item.fullWidth ? undefined : 4}
          item
        >
          <Fade in={index < count} timeout={fadeSpeed}>
            <div>
              <Img
                src={item.src}
                alt={item.alt ? item.alt : `Image in grid stepper ${index}`}
              />
            </div>
          </Fade>
        </Grid>
      ))}
    </Grid>
  )
}
