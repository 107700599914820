import React, { Fragment, FunctionComponent, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  Grid,
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { BusyCircular } from '.'
import { Check } from 'mdi-material-ui'
import { gql } from 'graphql.macro'
import { memberDashboardQueryName } from '../pages'
import { MODULES_FRAGMENT, useToggle } from '../utils'
import {
  ModuleType,
  useGetUserModulesQuery,
  ModuleDataFragment,
  useSelectModulesMutation,
} from '../types'

const useStyles = makeStyles({
  cardMedia: {
    padding: '20px',
  },
  cardHeading: {
    marginBottom: '15px',
  },
  cardBody: {
    fontSize: '15px',
    color: '#373737',
  },
  cardContent: {
    paddingTop: 0,
    paddingRight: '20px',
    paddingLeft: '20px',
    paddingBottom: '20px',
  },
  fontWhite: {
    color: '#fff',
  },
})

export const GET_USER_MODULES = gql`
  query getUserModules {
    moduleRecommendations {
      id
    }
    modules {
      ...moduleData
    }
  }
  ${MODULES_FRAGMENT}
`

export const SELECT_MODULES = gql`
  mutation selectModules($moduleIDs: [ID!]!) {
    selectModules(moduleIDs: $moduleIDs)
  }
`

export const ModulesSelect: FunctionComponent = () => {
  const theme = useTheme()
  const classes = useStyles()
  const notMobile = useMediaQuery(theme.breakpoints.up('sm'))
  const [open, toggleOpen] = useToggle(false)
  const [modulesList, setModulesList] = useState<ModuleDataFragment[]>([])
  const [selectedModules, setSelectedModules] = useState<string[]>([])
  const { data, loading, error } = useGetUserModulesQuery()
  const [selectModules] = useSelectModulesMutation({
    refetchQueries: [memberDashboardQueryName],
    awaitRefetchQueries: true,
  })

  const allModules = data?.modules
  const recommendedModules = data?.moduleRecommendations

  // Setup initial values
  useEffect(() => {
    const list: ModuleDataFragment[] = []
    const selected: string[] = []

    if (allModules) {
      allModules.forEach(function (module) {
        if (module.type === ModuleType.Module) {
          list.push(module)
          if (isModuleRecommended(module.id, recommendedModules)) {
            selected.push(module.id)
          }
        }
      })

      list.sort((a, b) => {
        if (a.number && b.number) {
          return a.number - b.number
        }
        return 0
      })

      setSelectedModules(selected)
      setModulesList(list)
    }
  }, [allModules, recommendedModules])

  function isModuleRecommended(
    moduleID: string,
    recommendedModules: { id: string }[] | undefined | null,
  ) {
    return recommendedModules &&
      recommendedModules.some((m) => {
        return m.id === moduleID
      })
      ? true
      : false
  }

  function isModuleSelected(moduleID: string) {
    return selectedModules.some((m) => {
      return m === moduleID
    })
  }

  function selectActiveModules() {
    if (!loading) {
      selectModules({ variables: { moduleIDs: selectedModules } })
    }
  }

  const toggleModuleSelection = (moduleID: string) => () => {
    const selectedClone = [...selectedModules]
    const found = selectedClone.findIndex((id) => {
      return id === moduleID
    })
    if (found !== -1) {
      selectedClone.splice(found, 1)
    } else {
      selectedClone.push(moduleID)
    }
    setSelectedModules(selectedClone)
  }

  if (error) {
    return (
      <Typography>
        There was an error while loading module selection: {error.message}
      </Typography>
    )
  }

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  return (
    <Fragment>
      <Typography paragraph>
        Welcome to your Partners in Parenting program.
      </Typography>
      <Typography paragraph>
        Based on your responses to the survey, we have recommended specific
        topics (also referred to as ‘modules’) in our program just for you --
        see titles below marked as ‘Recommended’. These modules correspond to
        the feedback you have just received, and are designed to support you in
        making the changes recommended in your feedback. However, this is your
        program, so feel free to choose other topics that are not included in
        our recommended list.
      </Typography>
      <Typography paragraph>
        Each module takes about 15-25 minutes to complete, depending on the
        topic and the way you choose to engage with it. We encourage you to
        focus on working through one module each week, to allow yourself time to
        apply some of the tips and work on your selected goal.
      </Typography>
      <Typography paragraph>
        Once you have decided on your modules, click “Confirm modules and start
        my program” and you’ll be able to check out your first module!
      </Typography>
      <Box my={3}>
        <Typography variant="h5" paragraph>
          Available Modules
        </Typography>
        <Grid container spacing={2}>
          {modulesList.map((module) => (
            <Grid key={module.id} item xs={12} sm={6} md={4} lg={3}>
              <Card>
                {isModuleRecommended(module.id, recommendedModules) ? (
                  <Box bgcolor="primary.main">
                    <CardActions>
                      <Typography
                        className={classes.fontWhite}
                        variant="body2"
                        color="inherit"
                      >
                        <i>Recommended</i>
                      </Typography>
                    </CardActions>
                  </Box>
                ) : (
                  <Box height={39} />
                )}
                <Box clone minHeight={notMobile ? '580px' : undefined}>
                  <CardActionArea onClick={toggleModuleSelection(module.id)}>
                    <CardMedia
                      className={classes.cardMedia}
                      component="img"
                      image={module.imageUrl ? module.imageUrl : ''}
                      title="Module Image"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography className={classes.cardHeading} variant="h5">
                        {module.name}
                      </Typography>
                      <Typography
                        className={classes.cardBody}
                        variant="body2"
                        color="textSecondary"
                      >
                        {module.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Box>
                <Box
                  bgcolor={
                    isModuleSelected(module.id) ? 'primary.main' : undefined
                  }
                >
                  <CardActions>
                    <Button
                      className={
                        isModuleSelected(module.id)
                          ? classes.fontWhite
                          : undefined
                      }
                      onClick={toggleModuleSelection(module.id)}
                    >
                      {isModuleSelected(module.id)
                        ? 'Selected'
                        : 'Not Selected'}
                    </Button>
                    <Typography
                      className={
                        isModuleSelected(module.id)
                          ? classes.fontWhite
                          : undefined
                      }
                      variant="body2"
                      color="inherit"
                    >
                      <i>
                        {isModuleSelected(module.id)
                          ? 'Click to deselect'
                          : 'Click to select'}
                      </i>
                    </Typography>
                  </CardActions>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box position="fixed" bottom={15} right={15} zIndex="drawer">
        <Fab onClick={toggleOpen} color="secondary" variant="extended">
          <Box clone mr={1}>
            <Check />
          </Box>
          Confirm modules and start my program
        </Fab>
      </Box>
      <Dialog open={open} onClose={toggleOpen}>
        {selectedModules.length > 0 ? (
          <Fragment>
            <DialogTitle>Confirm Selection?</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have selected {selectedModules.length} out of 9 modules.
                Please note that once you confirm your modules, you will not be
                able to change this. Click ‘Confirm’ to lock in your modules, or
                ‘Close’ to go back and change your selection.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={toggleOpen}>
                Close
              </Button>
              <Button color="primary" autoFocus onClick={selectActiveModules}>
                Confirm
              </Button>
            </DialogActions>
          </Fragment>
        ) : (
          <Fragment>
            <DialogTitle>Please select at least one module</DialogTitle>
            <DialogContent>
              <DialogContentText>
                You have not selected any modules. In order to continue your
                program, you need to lock in at least one module. Please click
                'Close' to go back and select your modules.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={toggleOpen}>
                Close
              </Button>
            </DialogActions>
          </Fragment>
        )}
      </Dialog>
    </Fragment>
  )
}
