import React, { FunctionComponent, MouseEvent } from 'react'
import { adminUrl, surveyUrl } from '../routes'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Card,
  Container,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { BusyCircular, NoPermission } from '../components'
import { gql } from 'graphql.macro'
import { superUserID } from '../utils'
import { useGetAllSurveysQuery } from '../types'
import { useNavigation } from 'react-navi'

export const GET_ALL_SURVEYS = gql`
  query GetAllSurveys {
    whoami {
      id
    }
    surveys {
      id
      name
      version
    }
  }
`

export const EditSurveys: FunctionComponent = () => {
  const navigation = useNavigation()
  const { data, loading, error } = useGetAllSurveysQuery()

  function goToPage(event: MouseEvent<HTMLDivElement>) {
    navigation.navigate(
      `${adminUrl}${surveyUrl}/${encodeURIComponent(event.currentTarget.id)}`,
    )
  }

  if (data?.whoami?.id !== superUserID && !loading) {
    return <NoPermission />
  }

  return (
    <Container maxWidth="sm">
      <Box mb={3}>
        <BusyCircular isBusy={loading} delayMs={200} />
        {error ? <Alert severity="error">{error.message}</Alert> : null}
        <Card>
          <List>
            {data?.surveys?.map((survey) => (
              <ListItem
                key={survey?.id}
                id={survey?.id}
                button
                onClick={goToPage}
              >
                <ListItemText
                  primary={survey?.name}
                  secondary={`Version: ${survey?.version}`}
                />
              </ListItem>
            ))}
          </List>
        </Card>
      </Box>
    </Container>
  )
}
