export const feedbackSurveyID = '78d836ce-a784-4882-8cbb-4a31426b699a'
export const siteName = 'Partners in Parenting'
export const participantMemberTypeChild = 'b6dc8fd5-c993-4e47-9b67-081cbe4fe455'
export const participantUser = 'PARTICIPANT'
export const optOutMessagePageID = 'b5756e39-ca51-4c5a-b883-9ca96a0ac37b'
export const superUserID = 'cbb345eb-26c3-4850-a732-b0bee215178b'

export enum UserType {
  Participant = 'PARTICIPANT',
  Admin = 'ADMIN',
  System = 'SYSTEM',
}
