import { gql } from 'graphql.macro'
import * as ApolloReactCommon from '@apollo/react-common'
import * as ApolloReactHooks from '@apollo/react-hooks'
export type Maybe<T> = T | null | undefined
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type CreateSurveyInput = {
  allowedAttempts?: Maybe<Scalars['Int']>
  content: Scalars['String']
  description: Scalars['String']
  name: Scalars['String']
  protected: Scalars['Boolean']
  savable: Scalars['Boolean']
}

export type FeedbackResult = {
  __typename?: 'FeedbackResult'
  data: Scalars['String']
}

export type Gender = {
  __typename?: 'Gender'
  id: Scalars['ID']
  name: Scalars['String']
  code: Scalars['String']
}

export type Goal = {
  __typename?: 'Goal'
  id: Scalars['ID']
  /** Returns completed time in RFC3999 */
  completed?: Maybe<Scalars['String']>
  description: Scalars['String']
  module: Module
  name: Scalars['String']
}

export enum GoalStatus {
  Complete = 'COMPLETE',
  Incomplete = 'INCOMPLETE',
}

export type KeyValue = {
  __typename?: 'KeyValue'
  key: Scalars['String']
  value: Scalars['String']
}

export type Module = {
  __typename?: 'Module'
  id: Scalars['ID']
  content?: Maybe<Scalars['String']>
  type?: Maybe<ModuleType>
  name?: Maybe<Scalars['String']>
  number?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
}

export type ModuleStatus = {
  __typename?: 'ModuleStatus'
  id: Scalars['ID']
  /** Returns completed time in RFC3999 */
  completed?: Maybe<Scalars['String']>
  data?: Maybe<Scalars['String']>
  position?: Maybe<Scalars['Int']>
  /** Returns time that module was locked in, in RFC3999 */
  locked_in?: Maybe<Scalars['String']>
  selected?: Maybe<Scalars['Boolean']>
  /** Returns unlocked time in RFC3999 */
  unlocked?: Maybe<Scalars['String']>
  user?: Maybe<User>
  module?: Maybe<Module>
}

export enum ModuleType {
  Factsheet = 'FACTSHEET',
  Module = 'MODULE',
}

export type Mutation = {
  __typename?: 'Mutation'
  /**
   * Logs an action to the database.  When user is not logged in, will return
   * an error.
   *
   * Name can be up to 64 characters long
   * Message can be up to 128 characters long
   */
  logEvent: Scalars['Boolean']
  /** Opts the current user out of the trial/platform. */
  optOut: Scalars['Boolean']
  /**
   * Mark module as completed.  If module is already completed, will return true
   * but will not change the recorded completion time.
   */
  moduleComplete: Scalars['Boolean']
  /**
   * Register a new participant user account.
   *
   * user: RegisterUser input
   * data: Registration surveyjs data
   */
  registerUser: Scalars['Boolean']
  /** Change password from current to new password. */
  resetPassword: Scalars['Boolean']
  /** Sends a reset password link to the provided email address.  If false is reported, try again later. */
  resetPasswordEmail: Scalars['Boolean']
  /** Use a token to reset password. */
  resetPasswordToken: Scalars['Boolean']
  /** Save module related data, an arbitrary json field. */
  saveModuleData: Scalars['Boolean']
  /**
   * Save survey result.
   *
   * surveyVersionID: ID for the survey version.
   * data: Survey data.
   * an existing survey.
   * completed: Specify if the survey is finished completely or saving answers in
   * progress.
   */
  saveSurveyData: Scalars['Boolean']
  /**
   * Select which modules this user will initially have access to.  Cannot be
   * changed once set, and will return false for subsequent attempts.
   */
  selectModules: Scalars['Boolean']
  /**
   * Set a goal for this account.  Will return an error if there is already a
   * goal with that name for that module.
   */
  setGoal: Scalars['Boolean']
  /** Sets the goal as completed or uncompleted. */
  setGoalStatus: Scalars['Boolean']
  temporarySendEmail: Scalars['Boolean']
  /** Update module with the provided input. */
  updateModule?: Maybe<Module>
  /** Verify account with the provided token. */
  verifyAccount: Scalars['Boolean']
  /** Update page with the provided input. */
  updatePage?: Maybe<Page>
  createSurvey?: Maybe<Survey>
  updateSurvey?: Maybe<Survey>
}

export type MutationLogEventArgs = {
  name: Scalars['String']
  message: Scalars['String']
}

export type MutationModuleCompleteArgs = {
  moduleID: Scalars['ID']
}

export type MutationRegisterUserArgs = {
  user: RegisterUser
}

export type MutationResetPasswordArgs = {
  current: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationResetPasswordEmailArgs = {
  email: Scalars['String']
}

export type MutationResetPasswordTokenArgs = {
  token: Scalars['String']
  newPassword: Scalars['String']
}

export type MutationSaveModuleDataArgs = {
  moduleID: Scalars['ID']
  data: Scalars['String']
}

export type MutationSaveSurveyDataArgs = {
  surveyVersionID: Scalars['ID']
  data: Scalars['String']
  completed: Scalars['Boolean']
}

export type MutationSelectModulesArgs = {
  moduleIDs: Array<Scalars['ID']>
}

export type MutationSetGoalArgs = {
  goal?: Maybe<SetGoalInput>
}

export type MutationSetGoalStatusArgs = {
  goalID: Scalars['ID']
  status: GoalStatus
}

export type MutationUpdateModuleArgs = {
  id: Scalars['ID']
  changes: UpdateModuleInput
}

export type MutationVerifyAccountArgs = {
  token: Scalars['String']
}

export type MutationUpdatePageArgs = {
  id: Scalars['ID']
  page: UpdatePageInput
}

export type MutationCreateSurveyArgs = {
  survey: CreateSurveyInput
}

export type MutationUpdateSurveyArgs = {
  id: Scalars['ID']
  survey: UpdateSurveyInput
}

export type Page = {
  __typename?: 'Page'
  id: Scalars['ID']
  content?: Maybe<Scalars['String']>
  menuName?: Maybe<Scalars['String']>
  route?: Maybe<Scalars['String']>
  showInMenu?: Maybe<Scalars['Boolean']>
}

export type PageInfo = {
  __typename?: 'PageInfo'
  hasNextPage: Scalars['Boolean']
  hasPreviousPage: Scalars['Boolean']
}

export type Participant = {
  __typename?: 'Participant'
  id: Scalars['ID']
  members?: Maybe<Array<ParticipantMember>>
  moduleType?: Maybe<ModuleType>
  data?: Maybe<Scalars['String']>
}

export type ParticipantMember = {
  __typename?: 'ParticipantMember'
  id: Scalars['ID']
  person?: Maybe<Person>
  participantMemberType?: Maybe<ParticipantMemberType>
}

export type ParticipantMemberType = {
  __typename?: 'ParticipantMemberType'
  id: Scalars['ID']
  name?: Maybe<Scalars['String']>
}

export type Person = {
  __typename?: 'Person'
  id: Scalars['ID']
  firstName?: Maybe<Scalars['String']>
  lastName?: Maybe<Scalars['String']>
  gender?: Maybe<Gender>
}

export type Query = {
  __typename?: 'Query'
  /** Get Environment Variable Settings */
  getSettings?: Maybe<Array<Maybe<KeyValue>>>
  feedbackResult?: Maybe<FeedbackResult>
  /** Returns all goals for current user. */
  goals: Array<Goal>
  /**
   * Loads survey results/data for the given survey.  Will load the most recent
   * entry, whether completed or not.  Returns nothing if there is no saved survey
   * data.
   *
   * id: ID for the survey
   */
  loadSurveyData?: Maybe<SurveyResult>
  /** Works like loadSurveyData, but allows selecting user. */
  loadUserSurveyData?: Maybe<SurveyResult>
  module?: Maybe<Module>
  /** Returns a list of recommended modules */
  moduleRecommendations: Array<Module>
  /** Returns a list of all modules. */
  modules?: Maybe<Array<Module>>
  /** Returns a list of the status for all modules for the current user */
  modulesStatus?: Maybe<Array<ModuleStatus>>
  /** Return the status of a single module */
  moduleStatus?: Maybe<ModuleStatus>
  /**
   * Returns participation details if the current user is a participant and such
   * details are available
   */
  myParticipation?: Maybe<Participant>
  /**
   * Returns any surveys that the user is currently scheduled to complete that
   * haven't yet been completed.
   */
  scheduledSurveys?: Maybe<Array<ScheduledSurvey>>
  /** Returns the page with the requested id */
  page?: Maybe<Page>
  /** Returns all pages */
  pages?: Maybe<Array<Maybe<Page>>>
  /** Fetches survey by ID */
  survey: Survey
  /**
   * Returns information about the survey as it relates to the current user.  For
   * example, how many times the survey has been completed.
   */
  surveyStats: SurveyStats
  /** Fetches all surveys */
  surveys?: Maybe<Array<Maybe<Survey>>>
  /** Returns all users */
  users: Array<User>
  whoami?: Maybe<User>
}

export type QueryFeedbackResultArgs = {
  password?: Maybe<Scalars['String']>
}

export type QueryLoadSurveyDataArgs = {
  id: Scalars['ID']
}

export type QueryLoadUserSurveyDataArgs = {
  userID: Scalars['ID']
  surveyID: Scalars['ID']
}

export type QueryModuleArgs = {
  id: Scalars['ID']
}

export type QueryModuleStatusArgs = {
  moduleID: Scalars['ID']
}

export type QueryPageArgs = {
  id: Scalars['ID']
}

export type QuerySurveyArgs = {
  id: Scalars['ID']
}

export type QuerySurveyStatsArgs = {
  surveyID: Scalars['ID']
}

export type RegisterUser = {
  email: Scalars['String']
  password: Scalars['String']
  data: Scalars['String']
  code?: Maybe<Scalars['String']>
}

export type ScheduledSurvey = {
  __typename?: 'ScheduledSurvey'
  id: Scalars['ID']
  survey: Survey
}

export type SetGoalInput = {
  moduleID: Scalars['ID']
  name: Scalars['String']
  description: Scalars['String']
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Survey = {
  __typename?: 'Survey'
  id: Scalars['ID']
  allowedAttempts?: Maybe<Scalars['Int']>
  content: Scalars['String']
  name: Scalars['String']
  /**
   * Protected surveys can be modified but cannot be removed.  Used for PRADAS
   * and registration surveys that we want to ensure always exist, preventing
   * them from being deleted entirely.
   */
  protected: Scalars['Boolean']
  /** Specifies the version of the survey, used when saving survey results. */
  version: Scalars['String']
}

export type SurveyResult = {
  __typename?: 'SurveyResult'
  id: Scalars['ID']
  completed: Scalars['Boolean']
  data: Scalars['String']
  survey: Survey
}

export type SurveyStats = {
  __typename?: 'SurveyStats'
  id: Scalars['ID']
  completions: Scalars['Int']
  surveyID: Scalars['ID']
}

export type UpdateModuleInput = {
  name?: Maybe<Scalars['String']>
  content?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  imageUrl?: Maybe<Scalars['String']>
}

export type UpdatePageInput = {
  content?: Maybe<Scalars['String']>
  menuName?: Maybe<Scalars['String']>
  route?: Maybe<Scalars['String']>
  showInMenu?: Maybe<Scalars['Boolean']>
}

export type UpdateSurveyInput = {
  allowedAttempts?: Maybe<Scalars['Int']>
  content?: Maybe<Scalars['String']>
  description?: Maybe<Scalars['String']>
  name?: Maybe<Scalars['String']>
  protected?: Maybe<Scalars['Boolean']>
  savable?: Maybe<Scalars['Boolean']>
}

export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  email?: Maybe<Scalars['String']>
  userType?: Maybe<UserType>
  person?: Maybe<Person>
  /** If this user is a participant, returns their selected goals. */
  goals?: Maybe<Array<Goal>>
  /**
   * If this user has access to modules as a participant, then returns the
   * status of those modules.
   */
  modulesStatus?: Maybe<Array<ModuleStatus>>
  /**
   * If this user is a participant, returns whether they are a module or factsheet
   * user.
   */
  participant?: Maybe<Participant>
  /**
   * Returns information about the survey as it relates to the current user.  For
   * example, how many times the survey has been completed.
   */
  surveysStats: Array<Maybe<SurveyStats>>
  verified?: Maybe<Scalars['Boolean']>
}

export type UserType = {
  __typename?: 'UserType'
  id: Scalars['ID']
  name: Scalars['String']
}

export type GetMyGoalsQueryVariables = {}

export type GetMyGoalsQuery = { __typename?: 'Query' } & {
  modulesStatus: Maybe<
    Array<
      { __typename?: 'ModuleStatus' } & Pick<
        ModuleStatus,
        'id' | 'position'
      > & { module: Maybe<{ __typename?: 'Module' } & Pick<Module, 'id'>> }
    >
  >
  goals: Array<
    { __typename?: 'Goal' } & Pick<Goal, 'id' | 'completed' | 'description'> & {
        module: { __typename?: 'Module' } & Pick<Module, 'id' | 'name'>
      }
  >
}

export type SetGoalStatusMutationVariables = {
  goalID: Scalars['ID']
  status: GoalStatus
}

export type SetGoalStatusMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setGoalStatus'
>

export type DynamicHeaderLinksQueryVariables = {}

export type DynamicHeaderLinksQuery = { __typename?: 'Query' } & {
  pages: Maybe<
    Array<
      Maybe<
        { __typename?: 'Page' } & Pick<
          Page,
          'id' | 'menuName' | 'route' | 'showInMenu'
        >
      >
    >
  >
}

export type LoadUserSurveyDataQueryVariables = {
  userID: Scalars['ID']
  surveyID: Scalars['ID']
}

export type LoadUserSurveyDataQuery = { __typename?: 'Query' } & {
  loadUserSurveyData: Maybe<
    { __typename?: 'SurveyResult' } & Pick<
      SurveyResult,
      'id' | 'completed' | 'data'
    >
  >
}

export type GetMyModulesQueryVariables = {}

export type GetMyModulesQuery = { __typename?: 'Query' } & {
  modulesStatus: Maybe<
    Array<
      { __typename?: 'ModuleStatus' } & Pick<
        ModuleStatus,
        'id' | 'position' | 'completed' | 'unlocked' | 'selected'
      > & { module: Maybe<{ __typename?: 'Module' } & ModuleDataFragment> }
    >
  >
}

export type GetUserModulesQueryVariables = {}

export type GetUserModulesQuery = { __typename?: 'Query' } & {
  moduleRecommendations: Array<{ __typename?: 'Module' } & Pick<Module, 'id'>>
  modules: Maybe<Array<{ __typename?: 'Module' } & ModuleDataFragment>>
}

export type SelectModulesMutationVariables = {
  moduleIDs: Array<Scalars['ID']>
}

export type SelectModulesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'selectModules'
>

export type GetSurveyQueryVariables = {
  survey: Scalars['ID']
}

export type GetSurveyQuery = { __typename?: 'Query' } & {
  survey: { __typename?: 'Survey' } & Pick<
    Survey,
    'id' | 'content' | 'name' | 'version' | 'allowedAttempts'
  >
  loadSurveyData: Maybe<
    { __typename?: 'SurveyResult' } & Pick<SurveyResult, 'id' | 'data'>
  >
  surveyStats: { __typename?: 'SurveyStats' } & Pick<
    SurveyStats,
    'id' | 'completions'
  >
  myParticipation: Maybe<
    { __typename?: 'Participant' } & Pick<Participant, 'id'> & {
        members: Maybe<
          Array<
            { __typename?: 'ParticipantMember' } & Pick<
              ParticipantMember,
              'id'
            > & {
                person: Maybe<
                  { __typename?: 'Person' } & Pick<
                    Person,
                    'id' | 'firstName'
                  > & {
                      gender: Maybe<
                        { __typename?: 'Gender' } & Pick<Gender, 'id' | 'code'>
                      >
                    }
                >
                participantMemberType: Maybe<
                  { __typename?: 'ParticipantMemberType' } & Pick<
                    ParticipantMemberType,
                    'id' | 'name'
                  >
                >
              }
          >
        >
      }
  >
}

export type GetPlainSurveyQueryVariables = {
  survey: Scalars['ID']
}

export type GetPlainSurveyQuery = { __typename?: 'Query' } & {
  survey: { __typename?: 'Survey' } & Pick<
    Survey,
    'id' | 'content' | 'name' | 'version'
  >
}

export type SaveSurveyMutationVariables = {
  surveyVersionID: Scalars['ID']
  data: Scalars['String']
  completed: Scalars['Boolean']
}

export type SaveSurveyMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveSurveyData'
>

export type FinishModuleMutationVariables = {
  moduleID: Scalars['ID']
}

export type FinishModuleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'moduleComplete'
>

export type AdminDashboardQueryVariables = {}

export type AdminDashboardQuery = { __typename?: 'Query' } & {
  users: Array<
    { __typename?: 'User' } & Pick<User, 'id' | 'email' | 'verified'> & {
        userType: Maybe<
          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'name'>
        >
        person: Maybe<{ __typename?: 'Person' } & Pick<Person, 'firstName'>>
        participant: Maybe<
          { __typename?: 'Participant' } & Pick<
            Participant,
            'id' | 'moduleType' | 'data'
          > & {
              members: Maybe<
                Array<
                  { __typename?: 'ParticipantMember' } & {
                    person: Maybe<
                      { __typename?: 'Person' } & Pick<
                        Person,
                        'firstName' | 'lastName'
                      > & {
                          gender: Maybe<
                            { __typename?: 'Gender' } & Pick<
                              Gender,
                              'id' | 'name'
                            >
                          >
                        }
                    >
                    participantMemberType: Maybe<
                      { __typename?: 'ParticipantMemberType' } & Pick<
                        ParticipantMemberType,
                        'id' | 'name'
                      >
                    >
                  }
                >
              >
            }
        >
        goals: Maybe<
          Array<
            { __typename?: 'Goal' } & Pick<
              Goal,
              'id' | 'completed' | 'name' | 'description'
            > & {
                module: { __typename?: 'Module' } & Pick<Module, 'id' | 'name'>
              }
          >
        >
        modulesStatus: Maybe<
          Array<
            { __typename?: 'ModuleStatus' } & Pick<
              ModuleStatus,
              'id' | 'completed' | 'selected' | 'unlocked' | 'locked_in'
            > & {
                module: Maybe<
                  { __typename?: 'Module' } & Pick<Module, 'id' | 'name'>
                >
              }
          >
        >
        surveysStats: Array<
          Maybe<
            { __typename?: 'SurveyStats' } & Pick<
              SurveyStats,
              'id' | 'completions' | 'surveyID'
            >
          >
        >
      }
  >
}

export type GetModuleQueryVariables = {
  id: Scalars['ID']
}

export type GetModuleQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  module: Maybe<{ __typename?: 'Module' } & ModuleUpdateFieldsFragment>
}

export type UpdateModuleMutationVariables = {
  id: Scalars['ID']
  changes: UpdateModuleInput
}

export type UpdateModuleMutation = { __typename?: 'Mutation' } & {
  updateModule: Maybe<{ __typename?: 'Module' } & ModuleUpdateFieldsFragment>
}

export type GetAllModulesQueryVariables = {}

export type GetAllModulesQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  modules: Maybe<Array<{ __typename?: 'Module' } & ModuleDataFragment>>
}

export type GetPageQueryVariables = {
  id: Scalars['ID']
}

export type GetPageQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  page: Maybe<{ __typename?: 'Page' } & PageDataFragment>
}

export type UpdatePageMutationVariables = {
  id: Scalars['ID']
  page: UpdatePageInput
}

export type UpdatePageMutation = { __typename?: 'Mutation' } & {
  updatePage: Maybe<{ __typename?: 'Page' } & PageDataFragment>
}

export type GetAllPagesQueryVariables = {}

export type GetAllPagesQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  pages: Maybe<Array<Maybe<{ __typename?: 'Page' } & PageDataFragment>>>
}

export type AdminFieldsFragment = { __typename?: 'Survey' } & Pick<
  Survey,
  'id' | 'allowedAttempts' | 'content' | 'name' | 'protected'
>

export type GetAdminSurveyFieldsQueryVariables = {
  id: Scalars['ID']
}

export type GetAdminSurveyFieldsQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  survey: { __typename?: 'Survey' } & AdminFieldsFragment
}

export type UpdateSurveyMutationVariables = {
  id: Scalars['ID']
  survey: UpdateSurveyInput
}

export type UpdateSurveyMutation = { __typename?: 'Mutation' } & {
  updateSurvey: Maybe<{ __typename?: 'Survey' } & AdminFieldsFragment>
}

export type GetAllSurveysQueryVariables = {}

export type GetAllSurveysQuery = { __typename?: 'Query' } & {
  whoami: Maybe<{ __typename?: 'User' } & Pick<User, 'id'>>
  surveys: Maybe<
    Array<
      Maybe<{ __typename?: 'Survey' } & Pick<Survey, 'id' | 'name' | 'version'>>
    >
  >
}

export type FactsheetUserDashboardQueryVariables = {
  surveyID: Scalars['ID']
}

export type FactsheetUserDashboardQuery = { __typename?: 'Query' } & {
  whoami: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        person: Maybe<
          { __typename?: 'Person' } & Pick<Person, 'id' | 'firstName'>
        >
      }
  >
  surveyStats: { __typename?: 'SurveyStats' } & Pick<
    SurveyStats,
    'id' | 'completions'
  >
  modulesStatus: Maybe<
    Array<
      { __typename?: 'ModuleStatus' } & Pick<
        ModuleStatus,
        'id' | 'selected' | 'unlocked'
      > & {
          module: Maybe<{ __typename?: 'Module' } & Pick<Module, 'id' | 'name'>>
        }
    >
  >
  scheduledSurveys: Maybe<
    Array<{ __typename?: 'ScheduledSurvey' } & ScheduledSurveysFragment>
  >
}

export type FactsheetModulesMutationVariables = {}

export type FactsheetModulesMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'selectModules'
>

export type GetFactsheetContentQueryVariables = {
  moduleID: Scalars['ID']
}

export type GetFactsheetContentQuery = { __typename?: 'Query' } & {
  module: Maybe<
    { __typename?: 'Module' } & Pick<Module, 'id' | 'name' | 'content'>
  >
}

export type GetFeedbackQueryVariables = {
  password?: Maybe<Scalars['String']>
}

export type GetFeedbackQuery = { __typename?: 'Query' } & {
  feedbackResult: Maybe<
    { __typename?: 'FeedbackResult' } & Pick<FeedbackResult, 'data'>
  >
}

export type GetSettingsQueryVariables = {}

export type GetSettingsQuery = { __typename?: 'Query' } & {
  getSettings: Maybe<
    Array<Maybe<{ __typename?: 'KeyValue' } & Pick<KeyValue, 'key' | 'value'>>>
  >
}

export type SendResetEmailMutationVariables = {
  email: Scalars['String']
}

export type SendResetEmailMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPasswordEmail'
>

export type GetMyParticipationQueryVariables = {
  surveyID: Scalars['ID']
}

export type GetMyParticipationQuery = { __typename?: 'Query' } & {
  whoami: Maybe<
    { __typename?: 'User' } & Pick<User, 'id'> & {
        person: Maybe<
          { __typename?: 'Person' } & Pick<Person, 'id' | 'firstName'>
        >
      }
  >
  surveyStats: { __typename?: 'SurveyStats' } & Pick<
    SurveyStats,
    'id' | 'completions'
  >
  modulesStatus: Maybe<
    Array<{ __typename?: 'ModuleStatus' } & Pick<ModuleStatus, 'id'>>
  >
  scheduledSurveys: Maybe<
    Array<{ __typename?: 'ScheduledSurvey' } & ScheduledSurveysFragment>
  >
}

export type GetModuleStatusQueryVariables = {
  moduleID: Scalars['ID']
}

export type GetModuleStatusQuery = { __typename?: 'Query' } & {
  moduleStatus: Maybe<
    { __typename?: 'ModuleStatus' } & Pick<
      ModuleStatus,
      'data' | 'completed'
    > & {
        module: Maybe<
          { __typename?: 'Module' } & Pick<Module, 'id' | 'name' | 'content'>
        >
      }
  >
}

export type SaveModuleMutationVariables = {
  moduleID: Scalars['ID']
  data: Scalars['String']
}

export type SaveModuleMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'saveModuleData'
>

export type SetGoalMutationVariables = {
  goal?: Maybe<SetGoalInput>
}

export type SetGoalMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'setGoal'
>

export type OptOutMutationVariables = {}

export type OptOutMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'optOut'
>

export type SubmitRegistrationMutationVariables = {
  user: RegisterUser
}

export type SubmitRegistrationMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'registerUser'
>

export type ResetPasswordMutationVariables = {
  current: Scalars['String']
  newPassword: Scalars['String']
}

export type ResetPasswordMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPassword'
>

export type ResetPasswordTokenMutationVariables = {
  token: Scalars['String']
  newPassword: Scalars['String']
}

export type ResetPasswordTokenMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'resetPasswordToken'
>

export type VerifyAccountMutationVariables = {
  token: Scalars['String']
}

export type VerifyAccountMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'verifyAccount'
>

export type GetWhoamiQueryVariables = {}

export type GetWhoamiQuery = { __typename?: 'Query' } & {
  whoami: Maybe<
    { __typename?: 'User' } & Pick<User, 'id' | 'email'> & {
        userType: Maybe<
          { __typename?: 'UserType' } & Pick<UserType, 'id' | 'name'>
        >
      }
  >
  myParticipation: Maybe<
    { __typename?: 'Participant' } & Pick<Participant, 'id' | 'moduleType'>
  >
}

export type ScheduledSurveysFragment = {
  __typename?: 'ScheduledSurvey'
} & Pick<ScheduledSurvey, 'id'> & {
    survey: { __typename?: 'Survey' } & Pick<Survey, 'id' | 'name'>
  }

export type ModuleDataFragment = { __typename?: 'Module' } & Pick<
  Module,
  'id' | 'description' | 'imageUrl' | 'name' | 'number' | 'type'
>

export type ModuleUpdateFieldsFragment = { __typename?: 'Module' } & Pick<
  Module,
  'id' | 'name' | 'content' | 'description' | 'imageUrl'
>

export type PageDataFragment = { __typename?: 'Page' } & Pick<
  Page,
  'id' | 'content' | 'menuName' | 'route' | 'showInMenu'
>

export type EventLogMutationVariables = {
  name: Scalars['String']
  message: Scalars['String']
}

export type EventLogMutation = { __typename?: 'Mutation' } & Pick<
  Mutation,
  'logEvent'
>

export const AdminFieldsFragmentDoc = gql`
  fragment adminFields on Survey {
    id
    allowedAttempts
    content
    name
    protected
  }
`
export const ScheduledSurveysFragmentDoc = gql`
  fragment scheduledSurveys on ScheduledSurvey {
    id
    survey {
      id
      name
    }
  }
`
export const ModuleDataFragmentDoc = gql`
  fragment moduleData on Module {
    id
    description
    imageUrl
    name
    number
    type
  }
`
export const ModuleUpdateFieldsFragmentDoc = gql`
  fragment moduleUpdateFields on Module {
    id
    name
    content
    description
    imageUrl
  }
`
export const PageDataFragmentDoc = gql`
  fragment pageData on Page {
    id
    content
    menuName
    route
    showInMenu
  }
`
export const GetMyGoalsDocument = gql`
  query GetMyGoals {
    modulesStatus {
      id
      position
      module {
        id
      }
    }
    goals {
      id
      completed
      description
      module {
        id
        name
      }
    }
  }
`

/**
 * __useGetMyGoalsQuery__
 *
 * To run a query within a React component, call `useGetMyGoalsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyGoalsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyGoalsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyGoalsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyGoalsQuery,
    GetMyGoalsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetMyGoalsQuery, GetMyGoalsQueryVariables>(
    GetMyGoalsDocument,
    baseOptions,
  )
}
export function useGetMyGoalsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyGoalsQuery,
    GetMyGoalsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetMyGoalsQuery,
    GetMyGoalsQueryVariables
  >(GetMyGoalsDocument, baseOptions)
}
export type GetMyGoalsQueryHookResult = ReturnType<typeof useGetMyGoalsQuery>
export type GetMyGoalsLazyQueryHookResult = ReturnType<
  typeof useGetMyGoalsLazyQuery
>
export type GetMyGoalsQueryResult = ApolloReactCommon.QueryResult<
  GetMyGoalsQuery,
  GetMyGoalsQueryVariables
>
export const SetGoalStatusDocument = gql`
  mutation setGoalStatus($goalID: ID!, $status: GoalStatus!) {
    setGoalStatus(goalID: $goalID, status: $status)
  }
`
export type SetGoalStatusMutationFn = ApolloReactCommon.MutationFunction<
  SetGoalStatusMutation,
  SetGoalStatusMutationVariables
>

/**
 * __useSetGoalStatusMutation__
 *
 * To run a mutation, you first call `useSetGoalStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGoalStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGoalStatusMutation, { data, loading, error }] = useSetGoalStatusMutation({
 *   variables: {
 *      goalID: // value for 'goalID'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useSetGoalStatusMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetGoalStatusMutation,
    SetGoalStatusMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SetGoalStatusMutation,
    SetGoalStatusMutationVariables
  >(SetGoalStatusDocument, baseOptions)
}
export type SetGoalStatusMutationHookResult = ReturnType<
  typeof useSetGoalStatusMutation
>
export type SetGoalStatusMutationResult = ApolloReactCommon.MutationResult<
  SetGoalStatusMutation
>
export type SetGoalStatusMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetGoalStatusMutation,
  SetGoalStatusMutationVariables
>
export const DynamicHeaderLinksDocument = gql`
  query DynamicHeaderLinks {
    pages {
      id
      menuName
      route
      showInMenu
    }
  }
`

/**
 * __useDynamicHeaderLinksQuery__
 *
 * To run a query within a React component, call `useDynamicHeaderLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useDynamicHeaderLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDynamicHeaderLinksQuery({
 *   variables: {
 *   },
 * });
 */
export function useDynamicHeaderLinksQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DynamicHeaderLinksQuery,
    DynamicHeaderLinksQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    DynamicHeaderLinksQuery,
    DynamicHeaderLinksQueryVariables
  >(DynamicHeaderLinksDocument, baseOptions)
}
export function useDynamicHeaderLinksLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DynamicHeaderLinksQuery,
    DynamicHeaderLinksQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    DynamicHeaderLinksQuery,
    DynamicHeaderLinksQueryVariables
  >(DynamicHeaderLinksDocument, baseOptions)
}
export type DynamicHeaderLinksQueryHookResult = ReturnType<
  typeof useDynamicHeaderLinksQuery
>
export type DynamicHeaderLinksLazyQueryHookResult = ReturnType<
  typeof useDynamicHeaderLinksLazyQuery
>
export type DynamicHeaderLinksQueryResult = ApolloReactCommon.QueryResult<
  DynamicHeaderLinksQuery,
  DynamicHeaderLinksQueryVariables
>
export const LoadUserSurveyDataDocument = gql`
  query loadUserSurveyData($userID: ID!, $surveyID: ID!) {
    loadUserSurveyData(userID: $userID, surveyID: $surveyID) {
      id
      completed
      data
    }
  }
`

/**
 * __useLoadUserSurveyDataQuery__
 *
 * To run a query within a React component, call `useLoadUserSurveyDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useLoadUserSurveyDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLoadUserSurveyDataQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      surveyID: // value for 'surveyID'
 *   },
 * });
 */
export function useLoadUserSurveyDataQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    LoadUserSurveyDataQuery,
    LoadUserSurveyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    LoadUserSurveyDataQuery,
    LoadUserSurveyDataQueryVariables
  >(LoadUserSurveyDataDocument, baseOptions)
}
export function useLoadUserSurveyDataLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    LoadUserSurveyDataQuery,
    LoadUserSurveyDataQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    LoadUserSurveyDataQuery,
    LoadUserSurveyDataQueryVariables
  >(LoadUserSurveyDataDocument, baseOptions)
}
export type LoadUserSurveyDataQueryHookResult = ReturnType<
  typeof useLoadUserSurveyDataQuery
>
export type LoadUserSurveyDataLazyQueryHookResult = ReturnType<
  typeof useLoadUserSurveyDataLazyQuery
>
export type LoadUserSurveyDataQueryResult = ApolloReactCommon.QueryResult<
  LoadUserSurveyDataQuery,
  LoadUserSurveyDataQueryVariables
>
export const GetMyModulesDocument = gql`
  query GetMyModules {
    modulesStatus {
      id
      position
      completed
      unlocked
      selected
      module {
        ...moduleData
      }
    }
  }
  ${ModuleDataFragmentDoc}
`

/**
 * __useGetMyModulesQuery__
 *
 * To run a query within a React component, call `useGetMyModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyModulesQuery,
    GetMyModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetMyModulesQuery,
    GetMyModulesQueryVariables
  >(GetMyModulesDocument, baseOptions)
}
export function useGetMyModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyModulesQuery,
    GetMyModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetMyModulesQuery,
    GetMyModulesQueryVariables
  >(GetMyModulesDocument, baseOptions)
}
export type GetMyModulesQueryHookResult = ReturnType<
  typeof useGetMyModulesQuery
>
export type GetMyModulesLazyQueryHookResult = ReturnType<
  typeof useGetMyModulesLazyQuery
>
export type GetMyModulesQueryResult = ApolloReactCommon.QueryResult<
  GetMyModulesQuery,
  GetMyModulesQueryVariables
>
export const GetUserModulesDocument = gql`
  query getUserModules {
    moduleRecommendations {
      id
    }
    modules {
      ...moduleData
    }
  }
  ${ModuleDataFragmentDoc}
`

/**
 * __useGetUserModulesQuery__
 *
 * To run a query within a React component, call `useGetUserModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetUserModulesQuery,
    GetUserModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetUserModulesQuery,
    GetUserModulesQueryVariables
  >(GetUserModulesDocument, baseOptions)
}
export function useGetUserModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetUserModulesQuery,
    GetUserModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetUserModulesQuery,
    GetUserModulesQueryVariables
  >(GetUserModulesDocument, baseOptions)
}
export type GetUserModulesQueryHookResult = ReturnType<
  typeof useGetUserModulesQuery
>
export type GetUserModulesLazyQueryHookResult = ReturnType<
  typeof useGetUserModulesLazyQuery
>
export type GetUserModulesQueryResult = ApolloReactCommon.QueryResult<
  GetUserModulesQuery,
  GetUserModulesQueryVariables
>
export const SelectModulesDocument = gql`
  mutation selectModules($moduleIDs: [ID!]!) {
    selectModules(moduleIDs: $moduleIDs)
  }
`
export type SelectModulesMutationFn = ApolloReactCommon.MutationFunction<
  SelectModulesMutation,
  SelectModulesMutationVariables
>

/**
 * __useSelectModulesMutation__
 *
 * To run a mutation, you first call `useSelectModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectModulesMutation, { data, loading, error }] = useSelectModulesMutation({
 *   variables: {
 *      moduleIDs: // value for 'moduleIDs'
 *   },
 * });
 */
export function useSelectModulesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SelectModulesMutation,
    SelectModulesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SelectModulesMutation,
    SelectModulesMutationVariables
  >(SelectModulesDocument, baseOptions)
}
export type SelectModulesMutationHookResult = ReturnType<
  typeof useSelectModulesMutation
>
export type SelectModulesMutationResult = ApolloReactCommon.MutationResult<
  SelectModulesMutation
>
export type SelectModulesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SelectModulesMutation,
  SelectModulesMutationVariables
>
export const GetSurveyDocument = gql`
  query getSurvey($survey: ID!) {
    survey(id: $survey) {
      id
      content
      name
      version
      allowedAttempts
    }
    loadSurveyData(id: $survey) {
      id
      data
    }
    surveyStats(surveyID: $survey) {
      id
      completions
    }
    myParticipation {
      id
      members {
        id
        person {
          id
          firstName
          gender {
            id
            code
          }
        }
        participantMemberType {
          id
          name
        }
      }
    }
  }
`

/**
 * __useGetSurveyQuery__
 *
 * To run a query within a React component, call `useGetSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSurveyQuery({
 *   variables: {
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useGetSurveyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    baseOptions,
  )
}
export function useGetSurveyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSurveyQuery,
    GetSurveyQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GetSurveyQuery, GetSurveyQueryVariables>(
    GetSurveyDocument,
    baseOptions,
  )
}
export type GetSurveyQueryHookResult = ReturnType<typeof useGetSurveyQuery>
export type GetSurveyLazyQueryHookResult = ReturnType<
  typeof useGetSurveyLazyQuery
>
export type GetSurveyQueryResult = ApolloReactCommon.QueryResult<
  GetSurveyQuery,
  GetSurveyQueryVariables
>
export const GetPlainSurveyDocument = gql`
  query getPlainSurvey($survey: ID!) {
    survey(id: $survey) {
      id
      content
      name
      version
    }
  }
`

/**
 * __useGetPlainSurveyQuery__
 *
 * To run a query within a React component, call `useGetPlainSurveyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlainSurveyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlainSurveyQuery({
 *   variables: {
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useGetPlainSurveyQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPlainSurveyQuery,
    GetPlainSurveyQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetPlainSurveyQuery,
    GetPlainSurveyQueryVariables
  >(GetPlainSurveyDocument, baseOptions)
}
export function useGetPlainSurveyLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPlainSurveyQuery,
    GetPlainSurveyQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetPlainSurveyQuery,
    GetPlainSurveyQueryVariables
  >(GetPlainSurveyDocument, baseOptions)
}
export type GetPlainSurveyQueryHookResult = ReturnType<
  typeof useGetPlainSurveyQuery
>
export type GetPlainSurveyLazyQueryHookResult = ReturnType<
  typeof useGetPlainSurveyLazyQuery
>
export type GetPlainSurveyQueryResult = ApolloReactCommon.QueryResult<
  GetPlainSurveyQuery,
  GetPlainSurveyQueryVariables
>
export const SaveSurveyDocument = gql`
  mutation saveSurvey(
    $surveyVersionID: ID!
    $data: String!
    $completed: Boolean!
  ) {
    saveSurveyData(
      surveyVersionID: $surveyVersionID
      data: $data
      completed: $completed
    )
  }
`
export type SaveSurveyMutationFn = ApolloReactCommon.MutationFunction<
  SaveSurveyMutation,
  SaveSurveyMutationVariables
>

/**
 * __useSaveSurveyMutation__
 *
 * To run a mutation, you first call `useSaveSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveSurveyMutation, { data, loading, error }] = useSaveSurveyMutation({
 *   variables: {
 *      surveyVersionID: // value for 'surveyVersionID'
 *      data: // value for 'data'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useSaveSurveyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveSurveyMutation,
    SaveSurveyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SaveSurveyMutation,
    SaveSurveyMutationVariables
  >(SaveSurveyDocument, baseOptions)
}
export type SaveSurveyMutationHookResult = ReturnType<
  typeof useSaveSurveyMutation
>
export type SaveSurveyMutationResult = ApolloReactCommon.MutationResult<
  SaveSurveyMutation
>
export type SaveSurveyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveSurveyMutation,
  SaveSurveyMutationVariables
>
export const FinishModuleDocument = gql`
  mutation finishModule($moduleID: ID!) {
    moduleComplete(moduleID: $moduleID)
  }
`
export type FinishModuleMutationFn = ApolloReactCommon.MutationFunction<
  FinishModuleMutation,
  FinishModuleMutationVariables
>

/**
 * __useFinishModuleMutation__
 *
 * To run a mutation, you first call `useFinishModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishModuleMutation, { data, loading, error }] = useFinishModuleMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *   },
 * });
 */
export function useFinishModuleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FinishModuleMutation,
    FinishModuleMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FinishModuleMutation,
    FinishModuleMutationVariables
  >(FinishModuleDocument, baseOptions)
}
export type FinishModuleMutationHookResult = ReturnType<
  typeof useFinishModuleMutation
>
export type FinishModuleMutationResult = ApolloReactCommon.MutationResult<
  FinishModuleMutation
>
export type FinishModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FinishModuleMutation,
  FinishModuleMutationVariables
>
export const AdminDashboardDocument = gql`
  query AdminDashboard {
    users {
      id
      email
      verified
      userType {
        id
        name
      }
      person {
        firstName
      }
      participant {
        id
        members {
          person {
            firstName
            lastName
            gender {
              id
              name
            }
          }
          participantMemberType {
            id
            name
          }
        }
        moduleType
        data
      }
      goals {
        id
        completed
        module {
          id
          name
        }
        name
        description
      }
      modulesStatus {
        id
        completed
        selected
        unlocked
        locked_in
        module {
          id
          name
        }
      }
      surveysStats {
        id
        completions
        surveyID
      }
    }
  }
`

/**
 * __useAdminDashboardQuery__
 *
 * To run a query within a React component, call `useAdminDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminDashboardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    AdminDashboardQuery,
    AdminDashboardQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    AdminDashboardQuery,
    AdminDashboardQueryVariables
  >(AdminDashboardDocument, baseOptions)
}
export function useAdminDashboardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    AdminDashboardQuery,
    AdminDashboardQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    AdminDashboardQuery,
    AdminDashboardQueryVariables
  >(AdminDashboardDocument, baseOptions)
}
export type AdminDashboardQueryHookResult = ReturnType<
  typeof useAdminDashboardQuery
>
export type AdminDashboardLazyQueryHookResult = ReturnType<
  typeof useAdminDashboardLazyQuery
>
export type AdminDashboardQueryResult = ApolloReactCommon.QueryResult<
  AdminDashboardQuery,
  AdminDashboardQueryVariables
>
export const GetModuleDocument = gql`
  query GetModule($id: ID!) {
    whoami {
      id
    }
    module(id: $id) {
      ...moduleUpdateFields
    }
  }
  ${ModuleUpdateFieldsFragmentDoc}
`

/**
 * __useGetModuleQuery__
 *
 * To run a query within a React component, call `useGetModuleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetModuleQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetModuleQuery,
    GetModuleQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions,
  )
}
export function useGetModuleLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetModuleQuery,
    GetModuleQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GetModuleQuery, GetModuleQueryVariables>(
    GetModuleDocument,
    baseOptions,
  )
}
export type GetModuleQueryHookResult = ReturnType<typeof useGetModuleQuery>
export type GetModuleLazyQueryHookResult = ReturnType<
  typeof useGetModuleLazyQuery
>
export type GetModuleQueryResult = ApolloReactCommon.QueryResult<
  GetModuleQuery,
  GetModuleQueryVariables
>
export const UpdateModuleDocument = gql`
  mutation UpdateModule($id: ID!, $changes: UpdateModuleInput!) {
    updateModule(id: $id, changes: $changes) {
      ...moduleUpdateFields
    }
  }
  ${ModuleUpdateFieldsFragmentDoc}
`
export type UpdateModuleMutationFn = ApolloReactCommon.MutationFunction<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      changes: // value for 'changes'
 *   },
 * });
 */
export function useUpdateModuleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateModuleMutation,
    UpdateModuleMutationVariables
  >(UpdateModuleDocument, baseOptions)
}
export type UpdateModuleMutationHookResult = ReturnType<
  typeof useUpdateModuleMutation
>
export type UpdateModuleMutationResult = ApolloReactCommon.MutationResult<
  UpdateModuleMutation
>
export type UpdateModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>
export const GetAllModulesDocument = gql`
  query GetAllModules {
    whoami {
      id
    }
    modules {
      ...moduleData
    }
  }
  ${ModuleDataFragmentDoc}
`

/**
 * __useGetAllModulesQuery__
 *
 * To run a query within a React component, call `useGetAllModulesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllModulesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllModulesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllModulesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllModulesQuery,
    GetAllModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetAllModulesQuery,
    GetAllModulesQueryVariables
  >(GetAllModulesDocument, baseOptions)
}
export function useGetAllModulesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllModulesQuery,
    GetAllModulesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllModulesQuery,
    GetAllModulesQueryVariables
  >(GetAllModulesDocument, baseOptions)
}
export type GetAllModulesQueryHookResult = ReturnType<
  typeof useGetAllModulesQuery
>
export type GetAllModulesLazyQueryHookResult = ReturnType<
  typeof useGetAllModulesLazyQuery
>
export type GetAllModulesQueryResult = ApolloReactCommon.QueryResult<
  GetAllModulesQuery,
  GetAllModulesQueryVariables
>
export const GetPageDocument = gql`
  query GetPage($id: ID!) {
    whoami {
      id
    }
    page(id: $id) {
      ...pageData
    }
  }
  ${PageDataFragmentDoc}
`

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetPageQuery,
    GetPageQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetPageQuery, GetPageQueryVariables>(
    GetPageDocument,
    baseOptions,
  )
}
export function useGetPageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetPageQuery,
    GetPageQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GetPageQuery, GetPageQueryVariables>(
    GetPageDocument,
    baseOptions,
  )
}
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>
export type GetPageQueryResult = ApolloReactCommon.QueryResult<
  GetPageQuery,
  GetPageQueryVariables
>
export const UpdatePageDocument = gql`
  mutation UpdatePage($id: ID!, $page: UpdatePageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageData
    }
  }
  ${PageDataFragmentDoc}
`
export type UpdatePageMutationFn = ApolloReactCommon.MutationFunction<
  UpdatePageMutation,
  UpdatePageMutationVariables
>

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useUpdatePageMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdatePageMutation,
    UpdatePageMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdatePageMutation,
    UpdatePageMutationVariables
  >(UpdatePageDocument, baseOptions)
}
export type UpdatePageMutationHookResult = ReturnType<
  typeof useUpdatePageMutation
>
export type UpdatePageMutationResult = ApolloReactCommon.MutationResult<
  UpdatePageMutation
>
export type UpdatePageMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdatePageMutation,
  UpdatePageMutationVariables
>
export const GetAllPagesDocument = gql`
  query GetAllPages {
    whoami {
      id
    }
    pages {
      ...pageData
    }
  }
  ${PageDataFragmentDoc}
`

/**
 * __useGetAllPagesQuery__
 *
 * To run a query within a React component, call `useGetAllPagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllPagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllPagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllPagesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllPagesQuery,
    GetAllPagesQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetAllPagesQuery, GetAllPagesQueryVariables>(
    GetAllPagesDocument,
    baseOptions,
  )
}
export function useGetAllPagesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllPagesQuery,
    GetAllPagesQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllPagesQuery,
    GetAllPagesQueryVariables
  >(GetAllPagesDocument, baseOptions)
}
export type GetAllPagesQueryHookResult = ReturnType<typeof useGetAllPagesQuery>
export type GetAllPagesLazyQueryHookResult = ReturnType<
  typeof useGetAllPagesLazyQuery
>
export type GetAllPagesQueryResult = ApolloReactCommon.QueryResult<
  GetAllPagesQuery,
  GetAllPagesQueryVariables
>
export const GetAdminSurveyFieldsDocument = gql`
  query GetAdminSurveyFields($id: ID!) {
    whoami {
      id
    }
    survey(id: $id) {
      ...adminFields
    }
  }
  ${AdminFieldsFragmentDoc}
`

/**
 * __useGetAdminSurveyFieldsQuery__
 *
 * To run a query within a React component, call `useGetAdminSurveyFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSurveyFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSurveyFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSurveyFieldsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAdminSurveyFieldsQuery,
    GetAdminSurveyFieldsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetAdminSurveyFieldsQuery,
    GetAdminSurveyFieldsQueryVariables
  >(GetAdminSurveyFieldsDocument, baseOptions)
}
export function useGetAdminSurveyFieldsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAdminSurveyFieldsQuery,
    GetAdminSurveyFieldsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetAdminSurveyFieldsQuery,
    GetAdminSurveyFieldsQueryVariables
  >(GetAdminSurveyFieldsDocument, baseOptions)
}
export type GetAdminSurveyFieldsQueryHookResult = ReturnType<
  typeof useGetAdminSurveyFieldsQuery
>
export type GetAdminSurveyFieldsLazyQueryHookResult = ReturnType<
  typeof useGetAdminSurveyFieldsLazyQuery
>
export type GetAdminSurveyFieldsQueryResult = ApolloReactCommon.QueryResult<
  GetAdminSurveyFieldsQuery,
  GetAdminSurveyFieldsQueryVariables
>
export const UpdateSurveyDocument = gql`
  mutation UpdateSurvey($id: ID!, $survey: UpdateSurveyInput!) {
    updateSurvey(id: $id, survey: $survey) {
      ...adminFields
    }
  }
  ${AdminFieldsFragmentDoc}
`
export type UpdateSurveyMutationFn = ApolloReactCommon.MutationFunction<
  UpdateSurveyMutation,
  UpdateSurveyMutationVariables
>

/**
 * __useUpdateSurveyMutation__
 *
 * To run a mutation, you first call `useUpdateSurveyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSurveyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSurveyMutation, { data, loading, error }] = useUpdateSurveyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      survey: // value for 'survey'
 *   },
 * });
 */
export function useUpdateSurveyMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    UpdateSurveyMutation,
    UpdateSurveyMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    UpdateSurveyMutation,
    UpdateSurveyMutationVariables
  >(UpdateSurveyDocument, baseOptions)
}
export type UpdateSurveyMutationHookResult = ReturnType<
  typeof useUpdateSurveyMutation
>
export type UpdateSurveyMutationResult = ApolloReactCommon.MutationResult<
  UpdateSurveyMutation
>
export type UpdateSurveyMutationOptions = ApolloReactCommon.BaseMutationOptions<
  UpdateSurveyMutation,
  UpdateSurveyMutationVariables
>
export const GetAllSurveysDocument = gql`
  query GetAllSurveys {
    whoami {
      id
    }
    surveys {
      id
      name
      version
    }
  }
`

/**
 * __useGetAllSurveysQuery__
 *
 * To run a query within a React component, call `useGetAllSurveysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSurveysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSurveysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSurveysQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >(GetAllSurveysDocument, baseOptions)
}
export function useGetAllSurveysLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetAllSurveysQuery,
    GetAllSurveysQueryVariables
  >(GetAllSurveysDocument, baseOptions)
}
export type GetAllSurveysQueryHookResult = ReturnType<
  typeof useGetAllSurveysQuery
>
export type GetAllSurveysLazyQueryHookResult = ReturnType<
  typeof useGetAllSurveysLazyQuery
>
export type GetAllSurveysQueryResult = ApolloReactCommon.QueryResult<
  GetAllSurveysQuery,
  GetAllSurveysQueryVariables
>
export const FactsheetUserDashboardDocument = gql`
  query FactsheetUserDashboard($surveyID: ID!) {
    whoami {
      id
      person {
        id
        firstName
      }
    }
    surveyStats(surveyID: $surveyID) {
      id
      completions
    }
    modulesStatus {
      id
      selected
      unlocked
      module {
        id
        name
      }
    }
    scheduledSurveys {
      ...scheduledSurveys
    }
  }
  ${ScheduledSurveysFragmentDoc}
`

/**
 * __useFactsheetUserDashboardQuery__
 *
 * To run a query within a React component, call `useFactsheetUserDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useFactsheetUserDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFactsheetUserDashboardQuery({
 *   variables: {
 *      surveyID: // value for 'surveyID'
 *   },
 * });
 */
export function useFactsheetUserDashboardQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    FactsheetUserDashboardQuery,
    FactsheetUserDashboardQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    FactsheetUserDashboardQuery,
    FactsheetUserDashboardQueryVariables
  >(FactsheetUserDashboardDocument, baseOptions)
}
export function useFactsheetUserDashboardLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    FactsheetUserDashboardQuery,
    FactsheetUserDashboardQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    FactsheetUserDashboardQuery,
    FactsheetUserDashboardQueryVariables
  >(FactsheetUserDashboardDocument, baseOptions)
}
export type FactsheetUserDashboardQueryHookResult = ReturnType<
  typeof useFactsheetUserDashboardQuery
>
export type FactsheetUserDashboardLazyQueryHookResult = ReturnType<
  typeof useFactsheetUserDashboardLazyQuery
>
export type FactsheetUserDashboardQueryResult = ApolloReactCommon.QueryResult<
  FactsheetUserDashboardQuery,
  FactsheetUserDashboardQueryVariables
>
export const FactsheetModulesDocument = gql`
  mutation FactsheetModules {
    selectModules(moduleIDs: [])
  }
`
export type FactsheetModulesMutationFn = ApolloReactCommon.MutationFunction<
  FactsheetModulesMutation,
  FactsheetModulesMutationVariables
>

/**
 * __useFactsheetModulesMutation__
 *
 * To run a mutation, you first call `useFactsheetModulesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFactsheetModulesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [factsheetModulesMutation, { data, loading, error }] = useFactsheetModulesMutation({
 *   variables: {
 *   },
 * });
 */
export function useFactsheetModulesMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    FactsheetModulesMutation,
    FactsheetModulesMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    FactsheetModulesMutation,
    FactsheetModulesMutationVariables
  >(FactsheetModulesDocument, baseOptions)
}
export type FactsheetModulesMutationHookResult = ReturnType<
  typeof useFactsheetModulesMutation
>
export type FactsheetModulesMutationResult = ApolloReactCommon.MutationResult<
  FactsheetModulesMutation
>
export type FactsheetModulesMutationOptions = ApolloReactCommon.BaseMutationOptions<
  FactsheetModulesMutation,
  FactsheetModulesMutationVariables
>
export const GetFactsheetContentDocument = gql`
  query getFactsheetContent($moduleID: ID!) {
    module(id: $moduleID) {
      id
      name
      content
    }
  }
`

/**
 * __useGetFactsheetContentQuery__
 *
 * To run a query within a React component, call `useGetFactsheetContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFactsheetContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFactsheetContentQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *   },
 * });
 */
export function useGetFactsheetContentQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFactsheetContentQuery,
    GetFactsheetContentQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetFactsheetContentQuery,
    GetFactsheetContentQueryVariables
  >(GetFactsheetContentDocument, baseOptions)
}
export function useGetFactsheetContentLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFactsheetContentQuery,
    GetFactsheetContentQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetFactsheetContentQuery,
    GetFactsheetContentQueryVariables
  >(GetFactsheetContentDocument, baseOptions)
}
export type GetFactsheetContentQueryHookResult = ReturnType<
  typeof useGetFactsheetContentQuery
>
export type GetFactsheetContentLazyQueryHookResult = ReturnType<
  typeof useGetFactsheetContentLazyQuery
>
export type GetFactsheetContentQueryResult = ApolloReactCommon.QueryResult<
  GetFactsheetContentQuery,
  GetFactsheetContentQueryVariables
>
export const GetFeedbackDocument = gql`
  query getFeedback($password: String) {
    feedbackResult(password: $password) {
      data
    }
  }
`

/**
 * __useGetFeedbackQuery__
 *
 * To run a query within a React component, call `useGetFeedbackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeedbackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeedbackQuery({
 *   variables: {
 *      password: // value for 'password'
 *   },
 * });
 */
export function useGetFeedbackQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetFeedbackQuery, GetFeedbackQueryVariables>(
    GetFeedbackDocument,
    baseOptions,
  )
}
export function useGetFeedbackLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetFeedbackQuery,
    GetFeedbackQueryVariables
  >(GetFeedbackDocument, baseOptions)
}
export type GetFeedbackQueryHookResult = ReturnType<typeof useGetFeedbackQuery>
export type GetFeedbackLazyQueryHookResult = ReturnType<
  typeof useGetFeedbackLazyQuery
>
export type GetFeedbackQueryResult = ApolloReactCommon.QueryResult<
  GetFeedbackQuery,
  GetFeedbackQueryVariables
>
export const GetSettingsDocument = gql`
  query getSettings {
    getSettings {
      key
      value
    }
  }
`

/**
 * __useGetSettingsQuery__
 *
 * To run a query within a React component, call `useGetSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSettingsQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetSettingsQuery, GetSettingsQueryVariables>(
    GetSettingsDocument,
    baseOptions,
  )
}
export function useGetSettingsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetSettingsQuery,
    GetSettingsQueryVariables
  >(GetSettingsDocument, baseOptions)
}
export type GetSettingsQueryHookResult = ReturnType<typeof useGetSettingsQuery>
export type GetSettingsLazyQueryHookResult = ReturnType<
  typeof useGetSettingsLazyQuery
>
export type GetSettingsQueryResult = ApolloReactCommon.QueryResult<
  GetSettingsQuery,
  GetSettingsQueryVariables
>
export const SendResetEmailDocument = gql`
  mutation SendResetEmail($email: String!) {
    resetPasswordEmail(email: $email)
  }
`
export type SendResetEmailMutationFn = ApolloReactCommon.MutationFunction<
  SendResetEmailMutation,
  SendResetEmailMutationVariables
>

/**
 * __useSendResetEmailMutation__
 *
 * To run a mutation, you first call `useSendResetEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendResetEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendResetEmailMutation, { data, loading, error }] = useSendResetEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendResetEmailMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SendResetEmailMutation,
    SendResetEmailMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SendResetEmailMutation,
    SendResetEmailMutationVariables
  >(SendResetEmailDocument, baseOptions)
}
export type SendResetEmailMutationHookResult = ReturnType<
  typeof useSendResetEmailMutation
>
export type SendResetEmailMutationResult = ApolloReactCommon.MutationResult<
  SendResetEmailMutation
>
export type SendResetEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SendResetEmailMutation,
  SendResetEmailMutationVariables
>
export const GetMyParticipationDocument = gql`
  query getMyParticipation($surveyID: ID!) {
    whoami {
      id
      person {
        id
        firstName
      }
    }
    surveyStats(surveyID: $surveyID) {
      id
      completions
    }
    modulesStatus {
      id
    }
    scheduledSurveys {
      ...scheduledSurveys
    }
  }
  ${ScheduledSurveysFragmentDoc}
`

/**
 * __useGetMyParticipationQuery__
 *
 * To run a query within a React component, call `useGetMyParticipationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyParticipationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyParticipationQuery({
 *   variables: {
 *      surveyID: // value for 'surveyID'
 *   },
 * });
 */
export function useGetMyParticipationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetMyParticipationQuery,
    GetMyParticipationQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetMyParticipationQuery,
    GetMyParticipationQueryVariables
  >(GetMyParticipationDocument, baseOptions)
}
export function useGetMyParticipationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetMyParticipationQuery,
    GetMyParticipationQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetMyParticipationQuery,
    GetMyParticipationQueryVariables
  >(GetMyParticipationDocument, baseOptions)
}
export type GetMyParticipationQueryHookResult = ReturnType<
  typeof useGetMyParticipationQuery
>
export type GetMyParticipationLazyQueryHookResult = ReturnType<
  typeof useGetMyParticipationLazyQuery
>
export type GetMyParticipationQueryResult = ApolloReactCommon.QueryResult<
  GetMyParticipationQuery,
  GetMyParticipationQueryVariables
>
export const GetModuleStatusDocument = gql`
  query getModuleStatus($moduleID: ID!) {
    moduleStatus(moduleID: $moduleID) {
      data
      completed
      module {
        id
        name
        content
      }
    }
  }
`

/**
 * __useGetModuleStatusQuery__
 *
 * To run a query within a React component, call `useGetModuleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModuleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModuleStatusQuery({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *   },
 * });
 */
export function useGetModuleStatusQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetModuleStatusQuery,
    GetModuleStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<
    GetModuleStatusQuery,
    GetModuleStatusQueryVariables
  >(GetModuleStatusDocument, baseOptions)
}
export function useGetModuleStatusLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetModuleStatusQuery,
    GetModuleStatusQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<
    GetModuleStatusQuery,
    GetModuleStatusQueryVariables
  >(GetModuleStatusDocument, baseOptions)
}
export type GetModuleStatusQueryHookResult = ReturnType<
  typeof useGetModuleStatusQuery
>
export type GetModuleStatusLazyQueryHookResult = ReturnType<
  typeof useGetModuleStatusLazyQuery
>
export type GetModuleStatusQueryResult = ApolloReactCommon.QueryResult<
  GetModuleStatusQuery,
  GetModuleStatusQueryVariables
>
export const SaveModuleDocument = gql`
  mutation saveModule($moduleID: ID!, $data: String!) {
    saveModuleData(moduleID: $moduleID, data: $data)
  }
`
export type SaveModuleMutationFn = ApolloReactCommon.MutationFunction<
  SaveModuleMutation,
  SaveModuleMutationVariables
>

/**
 * __useSaveModuleMutation__
 *
 * To run a mutation, you first call `useSaveModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveModuleMutation, { data, loading, error }] = useSaveModuleMutation({
 *   variables: {
 *      moduleID: // value for 'moduleID'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSaveModuleMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SaveModuleMutation,
    SaveModuleMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SaveModuleMutation,
    SaveModuleMutationVariables
  >(SaveModuleDocument, baseOptions)
}
export type SaveModuleMutationHookResult = ReturnType<
  typeof useSaveModuleMutation
>
export type SaveModuleMutationResult = ApolloReactCommon.MutationResult<
  SaveModuleMutation
>
export type SaveModuleMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SaveModuleMutation,
  SaveModuleMutationVariables
>
export const SetGoalDocument = gql`
  mutation setGoal($goal: SetGoalInput) {
    setGoal(goal: $goal)
  }
`
export type SetGoalMutationFn = ApolloReactCommon.MutationFunction<
  SetGoalMutation,
  SetGoalMutationVariables
>

/**
 * __useSetGoalMutation__
 *
 * To run a mutation, you first call `useSetGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setGoalMutation, { data, loading, error }] = useSetGoalMutation({
 *   variables: {
 *      goal: // value for 'goal'
 *   },
 * });
 */
export function useSetGoalMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SetGoalMutation,
    SetGoalMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SetGoalMutation,
    SetGoalMutationVariables
  >(SetGoalDocument, baseOptions)
}
export type SetGoalMutationHookResult = ReturnType<typeof useSetGoalMutation>
export type SetGoalMutationResult = ApolloReactCommon.MutationResult<
  SetGoalMutation
>
export type SetGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SetGoalMutation,
  SetGoalMutationVariables
>
export const OptOutDocument = gql`
  mutation OptOut {
    optOut
  }
`
export type OptOutMutationFn = ApolloReactCommon.MutationFunction<
  OptOutMutation,
  OptOutMutationVariables
>

/**
 * __useOptOutMutation__
 *
 * To run a mutation, you first call `useOptOutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOptOutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [optOutMutation, { data, loading, error }] = useOptOutMutation({
 *   variables: {
 *   },
 * });
 */
export function useOptOutMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    OptOutMutation,
    OptOutMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<OptOutMutation, OptOutMutationVariables>(
    OptOutDocument,
    baseOptions,
  )
}
export type OptOutMutationHookResult = ReturnType<typeof useOptOutMutation>
export type OptOutMutationResult = ApolloReactCommon.MutationResult<
  OptOutMutation
>
export type OptOutMutationOptions = ApolloReactCommon.BaseMutationOptions<
  OptOutMutation,
  OptOutMutationVariables
>
export const SubmitRegistrationDocument = gql`
  mutation submitRegistration($user: RegisterUser!) {
    registerUser(user: $user)
  }
`
export type SubmitRegistrationMutationFn = ApolloReactCommon.MutationFunction<
  SubmitRegistrationMutation,
  SubmitRegistrationMutationVariables
>

/**
 * __useSubmitRegistrationMutation__
 *
 * To run a mutation, you first call `useSubmitRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitRegistrationMutation, { data, loading, error }] = useSubmitRegistrationMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useSubmitRegistrationMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SubmitRegistrationMutation,
    SubmitRegistrationMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    SubmitRegistrationMutation,
    SubmitRegistrationMutationVariables
  >(SubmitRegistrationDocument, baseOptions)
}
export type SubmitRegistrationMutationHookResult = ReturnType<
  typeof useSubmitRegistrationMutation
>
export type SubmitRegistrationMutationResult = ApolloReactCommon.MutationResult<
  SubmitRegistrationMutation
>
export type SubmitRegistrationMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SubmitRegistrationMutation,
  SubmitRegistrationMutationVariables
>
export const ResetPasswordDocument = gql`
  mutation ResetPassword($current: String!, $newPassword: String!) {
    resetPassword(current: $current, newPassword: $newPassword)
  }
`
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      current: // value for 'current'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions)
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordMutation
>
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>
export const ResetPasswordTokenDocument = gql`
  mutation ResetPasswordToken($token: String!, $newPassword: String!) {
    resetPasswordToken(token: $token, newPassword: $newPassword)
  }
`
export type ResetPasswordTokenMutationFn = ApolloReactCommon.MutationFunction<
  ResetPasswordTokenMutation,
  ResetPasswordTokenMutationVariables
>

/**
 * __useResetPasswordTokenMutation__
 *
 * To run a mutation, you first call `useResetPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordTokenMutation, { data, loading, error }] = useResetPasswordTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useResetPasswordTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    ResetPasswordTokenMutation,
    ResetPasswordTokenMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    ResetPasswordTokenMutation,
    ResetPasswordTokenMutationVariables
  >(ResetPasswordTokenDocument, baseOptions)
}
export type ResetPasswordTokenMutationHookResult = ReturnType<
  typeof useResetPasswordTokenMutation
>
export type ResetPasswordTokenMutationResult = ApolloReactCommon.MutationResult<
  ResetPasswordTokenMutation
>
export type ResetPasswordTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  ResetPasswordTokenMutation,
  ResetPasswordTokenMutationVariables
>
export const VerifyAccountDocument = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token)
  }
`
export type VerifyAccountMutationFn = ApolloReactCommon.MutationFunction<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>

/**
 * __useVerifyAccountMutation__
 *
 * To run a mutation, you first call `useVerifyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyAccountMutation, { data, loading, error }] = useVerifyAccountMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyAccountMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    VerifyAccountMutation,
    VerifyAccountMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    VerifyAccountMutation,
    VerifyAccountMutationVariables
  >(VerifyAccountDocument, baseOptions)
}
export type VerifyAccountMutationHookResult = ReturnType<
  typeof useVerifyAccountMutation
>
export type VerifyAccountMutationResult = ApolloReactCommon.MutationResult<
  VerifyAccountMutation
>
export type VerifyAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>
export const GetWhoamiDocument = gql`
  query getWhoami {
    whoami {
      id
      email
      userType {
        id
        name
      }
    }
    myParticipation {
      id
      moduleType
    }
  }
`

/**
 * __useGetWhoamiQuery__
 *
 * To run a query within a React component, call `useGetWhoamiQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWhoamiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWhoamiQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWhoamiQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetWhoamiQuery,
    GetWhoamiQueryVariables
  >,
) {
  return ApolloReactHooks.useQuery<GetWhoamiQuery, GetWhoamiQueryVariables>(
    GetWhoamiDocument,
    baseOptions,
  )
}
export function useGetWhoamiLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetWhoamiQuery,
    GetWhoamiQueryVariables
  >,
) {
  return ApolloReactHooks.useLazyQuery<GetWhoamiQuery, GetWhoamiQueryVariables>(
    GetWhoamiDocument,
    baseOptions,
  )
}
export type GetWhoamiQueryHookResult = ReturnType<typeof useGetWhoamiQuery>
export type GetWhoamiLazyQueryHookResult = ReturnType<
  typeof useGetWhoamiLazyQuery
>
export type GetWhoamiQueryResult = ApolloReactCommon.QueryResult<
  GetWhoamiQuery,
  GetWhoamiQueryVariables
>
export const EventLogDocument = gql`
  mutation eventLog($name: String!, $message: String!) {
    logEvent(name: $name, message: $message)
  }
`
export type EventLogMutationFn = ApolloReactCommon.MutationFunction<
  EventLogMutation,
  EventLogMutationVariables
>

/**
 * __useEventLogMutation__
 *
 * To run a mutation, you first call `useEventLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEventLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [eventLogMutation, { data, loading, error }] = useEventLogMutation({
 *   variables: {
 *      name: // value for 'name'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useEventLogMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    EventLogMutation,
    EventLogMutationVariables
  >,
) {
  return ApolloReactHooks.useMutation<
    EventLogMutation,
    EventLogMutationVariables
  >(EventLogDocument, baseOptions)
}
export type EventLogMutationHookResult = ReturnType<typeof useEventLogMutation>
export type EventLogMutationResult = ApolloReactCommon.MutationResult<
  EventLogMutation
>
export type EventLogMutationOptions = ApolloReactCommon.BaseMutationOptions<
  EventLogMutation,
  EventLogMutationVariables
>
