import React from 'react'
import { Paper as MaterialPaper, Box } from '@material-ui/core'

export const Paper = (props) => (
  <Box clone m={1} p={2}>
    <MaterialPaper elevation={props.elevation} square={props.square}>
      {props.children}
    </MaterialPaper>
  </Box>
)
