import React, { FunctionComponent, useEffect, useMemo } from 'react'
import { BusyCircular, MemoMDX } from '../components'
import { Container, Typography } from '@material-ui/core'
import { gql } from 'graphql.macro'
import { ModuleJSON } from './Module'
import {
  useFinishModuleMutation,
  useGetFactsheetContentQuery,
  useEventLogMutation,
} from '../types'

export const FACTSHEET_CONTENT = gql`
  query getFactsheetContent($moduleID: ID!) {
    module(id: $moduleID) {
      id
      name
      content
    }
  }
`
interface FactsheetProps {
  moduleID: string
}

export const Factsheet: FunctionComponent<FactsheetProps> = ({ moduleID }) => {
  const { data, loading, error: queryError } = useGetFactsheetContentQuery({
    variables: { moduleID },
  })
  const [finishModule, { error: mutationError }] = useFinishModuleMutation({
    variables: { moduleID },
  })
  const [eventLog] = useEventLogMutation({
    errorPolicy: 'ignore',
    variables: { name: 'factsheet_opened', message: moduleID },
  })

  const moduleContent = useMemo(() => {
    try {
      return JSON.parse(data?.module?.content ?? '{}') as ModuleJSON
    } catch {
      return undefined
    }
  }, [data])

  useEffect(() => {
    finishModule()
    eventLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container maxWidth="md">
      {mutationError ? (
        <Typography>
          There was an error trying to save your progress {mutationError}
        </Typography>
      ) : null}
      {queryError ? (
        <Typography>
          There was an error trying to load the factsheet {queryError}
        </Typography>
      ) : null}
      {loading ? (
        <BusyCircular isBusy={loading} delayMs={200} />
      ) : (
        <MemoMDX mdx={moduleContent?.pages?.[0].page ?? ''} />
      )}
    </Container>
  )
}
