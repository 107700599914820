import Carousel from 'react-multi-carousel'
import React, { Fragment, FunctionComponent, MouseEvent } from 'react'
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Check, Lock, LockOpenVariant } from 'mdi-material-ui'
import { gql } from 'graphql.macro'
import { memberUrl, moduleUrl } from '../routes'
import { MODULES_FRAGMENT } from '../utils'
import { useGetMyModulesQuery } from '../types'
import { useNavigation } from 'react-navi'
import 'react-multi-carousel/lib/styles.css'

const useStyles = makeStyles({
  cardMedia: {
    padding: '20px',
  },
  cardHeading: {
    marginBottom: '15px',
  },
  cardBody: {
    color: '#373737',
  },
  cardContent: {
    paddingTop: 0,
  },
})

export const myActiveModulesQueryName = 'GetMyModules'

export const GET_MY_MODULES = gql`
  query GetMyModules {
    modulesStatus {
      id
      position
      completed
      unlocked
      selected
      module {
        ...moduleData
      }
    }
  }
  ${MODULES_FRAGMENT}
`

export const ModulesList: FunctionComponent = () => {
  const classes = useStyles()
  const navigation = useNavigation()
  const theme = useTheme()
  const { data } = useGetMyModulesQuery()

  // idx calls to nested graphql data
  const activeModulesList = data?.modulesStatus

  function goToModule(event: MouseEvent<HTMLButtonElement>) {
    navigation.navigate(
      `${memberUrl}${moduleUrl}/${encodeURIComponent(event.currentTarget.id)}`,
    )
  }

  if (!activeModulesList || activeModulesList.length <= 0) {
    return (
      <Typography variant="h5" paragraph>
        No Available Modules
      </Typography>
    )
  }

  const filteredModulesList = activeModulesList
    .filter((module) => (module.selected || module.unlocked) && module.module)
    .sort((a, b) => (a.position ?? 0) - (b.position ?? 0))

  return (
    <Fragment>
      <Typography variant="h5" paragraph>
        Your modules
      </Typography>
      <Carousel
        showDots={true}
        swipeable={false}
        draggable={false}
        responsive={{
          sm: {
            breakpoint: { max: theme.breakpoints.width('sm'), min: 1 },
            items: 1,
          },
          md: {
            breakpoint: {
              max: theme.breakpoints.width('md'),
              min: theme.breakpoints.width('sm') + 1,
            },
            items: 2,
            slidesToSlide: 2,
          },
          lg: {
            breakpoint: {
              max: theme.breakpoints.width('lg'),
              min: theme.breakpoints.width('md') + 1,
            },
            items: 3,
            slidesToSlide: 3,
          },
          xl: {
            breakpoint: {
              max: 12000,
              min: theme.breakpoints.width('lg') + 1,
            },
            items: 4,
            slidesToSlide: 4,
          },
        }}
      >
        {filteredModulesList.map((module) => {
          const actionAreaContent = (
            <Fragment>
              <Box minHeight={540}>
                <CardMedia
                  className={classes.cardMedia}
                  component="img"
                  image={module.module?.imageUrl ?? ''}
                  title={`${module.module?.name} image`}
                />
                <CardContent className={classes.cardContent}>
                  <Typography className={classes.cardHeading} variant="h5">
                    {module.module?.name}
                  </Typography>
                  <Typography
                    className={classes.cardBody}
                    color="textSecondary"
                    variant="body2"
                  >
                    {module.module?.description}
                  </Typography>
                </CardContent>
              </Box>
              <Box
                clone
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <CardActions>
                  {module.unlocked ? (
                    module.completed ? (
                      <Check color="primary" />
                    ) : (
                      <LockOpenVariant color="action" />
                    )
                  ) : (
                    <Lock color="disabled" />
                  )}
                </CardActions>
              </Box>
            </Fragment>
          )
          return (
            <Box m={1} key={module.module?.id}>
              <Card>
                {module.unlocked ? (
                  <CardActionArea id={module.module?.id} onClick={goToModule}>
                    {actionAreaContent}
                  </CardActionArea>
                ) : (
                  actionAreaContent
                )}
              </Card>
            </Box>
          )
        })}
      </Carousel>
    </Fragment>
  )
}
