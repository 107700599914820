import React, { FunctionComponent, useMemo } from 'react'
import { Button } from '@material-ui/core'
import { Fragment } from 'react'
import { gql } from 'graphql.macro'
import { logoutUrl } from '../routes'
import { useApolloClient } from '@apollo/react-hooks'
import { useGetWhoamiQuery, useOptOutMutation, useGetPageQuery } from '../types'
import { useNavigation } from 'react-navi'
import { useSnackbar } from 'notistack'
import { optOutMessagePageID } from '../utils'
import { ModuleJSON } from '.'
import { Alert } from '@material-ui/lab'
import { BusyCircular, MemoMDX } from '../components'

export const OPTOUT = gql`
  mutation OptOut {
    optOut
  }
`

export const Optout: FunctionComponent = () => {
  const snackbar = useSnackbar()
  const navigation = useNavigation()
  const client = useApolloClient()
  const {
    data: messageData,
    loading: messageLoading,
    error: messageError,
  } = useGetPageQuery({ variables: { id: optOutMessagePageID } })
  const { data, loading } = useGetWhoamiQuery()
  const [optOut] = useOptOutMutation({
    onCompleted: async (data) => {
      if (data.optOut) {
        snackbar.enqueueSnackbar('Opt out complete')
        client.stop()
        await client.resetStore().catch(() => {})
        navigation.navigate(logoutUrl)
      }
    },
    onError: (error) =>
      snackbar.enqueueSnackbar(error.message, { variant: 'error' }),
  })

  const userType = data?.whoami?.userType?.name
  const busy = loading || messageLoading

  const pageJSON = useMemo(() => {
    try {
      return JSON.parse(messageData?.page?.content ?? '{}') as ModuleJSON
    } catch {
      return undefined
    }
  }, [messageData])

  function optOutUser() {
    optOut()
  }

  return (
    <Fragment>
      {messageError ? (
        <Alert severity="error">{messageError.message}</Alert>
      ) : null}
      {busy ? (
        <BusyCircular isBusy={busy} delayMs={200} />
      ) : (
        <Fragment>
          {pageJSON?.pages?.map((page, index) => (
            <MemoMDX key={index} mdx={page.page} />
          ))}
          {userType ? (
            <Button color="primary" variant="contained" onClick={optOutUser}>
              Yes, I want to opt out
            </Button>
          ) : (
            <Button color="primary" disabled variant="contained">
              You must be logged in to opt out
            </Button>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}
