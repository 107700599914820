import React, { FunctionComponent } from 'react'
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { useToggle } from '../../utils'

export const AutoDialog: FunctionComponent = ({ children }) => {
  const [open, toggle] = useToggle(true)

  return (
    <Dialog open={open} onClose={toggle}>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={toggle}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
