import React, { Fragment } from 'react'
import { AdapterLink } from '.'
import { Box, Button, Typography } from '@material-ui/core'
import { Maybe, ScheduledSurveysFragment } from '../types'
import { surveyUrl } from '../routes'

export const ScheduledSurveyList = ({
  surveys,
  baseUrl,
}: {
  surveys: Maybe<ScheduledSurveysFragment[]>
  baseUrl: string
}) => (
  <Fragment>
    {surveys?.map((survey) => (
      <Box key={survey.id} py={5}>
        <Typography>You have a new survey to complete.</Typography>
        <Button
          color="primary"
          component={AdapterLink}
          fullWidth
          href={`${baseUrl}${surveyUrl}/${survey.survey.id}`}
          size="large"
          variant="contained"
        >
          Click here to start the {survey.survey.name} survey
        </Button>
      </Box>
    ))}
  </Fragment>
)
