import { useRef, useEffect, useState, useCallback } from 'react'

/**
 * @see https://overreacted.io/making-setinterval-declarative-with-react-hooks/
 */
export function useInterval(callback: any, delay: number) {
  const savedCallback = useRef<undefined | any>(undefined)

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      let id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

/**
 * @see https://github.com/streamich/react-use
 */
export const useToggle = (
  initialValue: boolean,
): [boolean, (nextValue?: any) => void] => {
  const [value, setValue] = useState<boolean>(initialValue)

  const toggle = useCallback(
    (nextValue?: any) => {
      if (typeof nextValue === 'boolean') {
        setValue(nextValue)
      } else {
        setValue((currentValue) => !currentValue)
      }
    },
    [setValue],
  )

  return [value, toggle]
}
