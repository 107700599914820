import React, { FunctionComponent } from 'react'
import { Box, Grid } from '@material-ui/core'
import { GridSize } from '@material-ui/core/Grid'
import { Img } from '.'

interface SingleImageProps {
  /** The url of the image you want to display */
  src: string
  /** This prop defines an alternative text description of the image. */
  alt: string
  /** This defines how much space the image should take up on the page. On
   * mobile the gridSize will always be 12 (full width). For larger screens you
   * can set this to a value less than 12. For example setting 6 will mean the
   * image's width will be set to 50% of the page. */
  gridSize: GridSize
  smallGridSize?: GridSize
}

/**
 * React component for placing a single image in the document. Should scale the
 * image to fit the screen width if the screen is smaller than the image.
 */
export const SingleImage: FunctionComponent<SingleImageProps> = ({
  src,
  alt,
  gridSize,
  smallGridSize,
}) => {
  return (
    <Box py={2}>
      <Grid container justify="center" alignItems="center">
        <Grid xs={12} sm={smallGridSize} md={gridSize} item>
          <Img src={src} alt={alt} />
        </Grid>
      </Grid>
    </Box>
  )
}
