import React, { Fragment, FunctionComponent, useMemo } from 'react'
import { Alert } from '@material-ui/lab'
import { BusyCircular, MemoMDX } from '../components'
import { ModuleJSON } from '.'
import { useGetPageQuery } from '../types'

export const Page: FunctionComponent<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useGetPageQuery({ variables: { id } })

  const pageJSON = useMemo(() => {
    try {
      return JSON.parse(data?.page?.content ?? '{}') as ModuleJSON
    } catch {
      return undefined
    }
  }, [data])

  return (
    <Fragment>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      {loading ? (
        <BusyCircular isBusy={loading} delayMs={200} />
      ) : (
        pageJSON?.pages?.map((page, index) => (
          <MemoMDX key={index} mdx={page.page} />
        ))
      )}
    </Fragment>
  )
}
