import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { Skull } from 'mdi-material-ui'

export const NoPermission = () => (
  <Box mb={3} display="flex" alignItems="center" flexDirection="column">
    <Skull fontSize="large" />
    <Typography variant="button">No Permission</Typography>
  </Box>
)
