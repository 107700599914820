import React, { useContext, ReactNode, FunctionComponent } from 'react'
import { Card, CardContent, Fade } from '@material-ui/core'
import { Img } from '.'
import { addDateKey } from '../../utils'
import { ModuleControlContext } from '../../pages'

interface ImageRevealItem {
  src: string
  alt: string
}

interface ImageRevealProps {
  id: string
  mainDescription: ReactNode
  mainItem: ImageRevealItem
  aboveReveal?: ImageRevealItem
  belowReveal?: ImageRevealItem
  /** How long the fade effect takes to transition from invisible to fully
   * visible. In milliseconds. */
  fadeSpeed: number
}

/**
 * React component for rendering a grid list of images where each item will fade
 * in after the previous one.
 */
export const ImageReveal: FunctionComponent<ImageRevealProps> = ({
  fadeSpeed,
  mainItem,
  mainDescription,
  aboveReveal,
  belowReveal,
  id,
}) => {
  const context = useContext(ModuleControlContext)
  const key = `TextReveal_${id}`
  const keyData = context.moduleData?.[key]
  const clicked = keyData ? true : false

  function click() {
    if (!keyData) {
      addDateKey(key, context)
    }
  }

  return (
    <Card onClick={click}>
      <CardContent>
        {aboveReveal ? (
          <Fade in={clicked} timeout={fadeSpeed}>
            <Img src={aboveReveal.src} alt={aboveReveal.alt} />
          </Fade>
        ) : null}
        {mainDescription}
        <Img src={mainItem.src} alt={mainItem.alt} />
        {belowReveal ? (
          <Fade in={clicked} timeout={fadeSpeed}>
            <Img src={belowReveal.src} alt={belowReveal.alt} />
          </Fade>
        ) : null}
      </CardContent>
    </Card>
  )
}
