import React, { useState } from 'react'
import { Box, CircularProgress, LinearProgress } from '@material-ui/core'
import { CircularProgressProps } from '@material-ui/core/CircularProgress'
import { LinearProgressProps } from '@material-ui/core/LinearProgress'
import { useEffect } from 'react'

interface UseBusyProps {
  delayMs: number
  isBusy: boolean
}

function useBusyTrigger(isBusy: boolean, delayMs: number) {
  const [trigger, setTrigger] = useState(false)

  useEffect(() => {
    function tick() {
      setTrigger(true)
    }
    if (isBusy) {
      let id = setTimeout(tick, delayMs)
      return () => clearTimeout(id)
    } else {
      setTrigger(false)
    }
  }, [isBusy, delayMs])

  return trigger
}

export const LoadingSpinner = (props: CircularProgressProps) => (
  <Box display="flex" justifyContent="center" m={2}>
    <CircularProgress {...props} />
  </Box>
)

export const FixedLinearLoading = (props: LinearProgressProps) => (
  <Box position="fixed" width="100%" zIndex="modal">
    <LinearProgress {...props} />
  </Box>
)

export function BusyLinear({
  isBusy,
  delayMs,
  ...otherProps
}: LinearProgressProps & UseBusyProps) {
  const trigger = useBusyTrigger(isBusy, delayMs)

  return trigger && isBusy ? <FixedLinearLoading {...otherProps} /> : null
}

export function BusyCircular({
  isBusy,
  delayMs,
  ...otherProps
}: CircularProgressProps & UseBusyProps) {
  const trigger = useBusyTrigger(isBusy, delayMs)

  return trigger && isBusy ? <LoadingSpinner {...otherProps} /> : null
}
