import React from 'react'
import {
  createStyles,
  makeStyles,
  Paper,
  Table,
  TableCell,
  TableRow,
  Theme,
  withStyles,
} from '@material-ui/core'
import { FunctionComponent } from 'react'

export const StyledTableCell = withStyles((theme: Theme) =>
  createStyles({
    head: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
  }),
)(TableCell)

export const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.background.default,
      },
    },
  }),
)(TableRow)

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      marginTop: theme.spacing(3),
      overflowX: 'auto',
    },
  }),
)

export const SimpleTable: FunctionComponent = (props) => {
  const classes = useStyles()

  return (
    <Paper className={classes.root}>
      <Table>{props.children}</Table>
    </Paper>
  )
}
