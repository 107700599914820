import React, {
  ChangeEvent,
  Fragment,
  FunctionComponent,
  ReactNode,
  useContext,
  useState,
} from 'react'
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core'
import { CheckboxProps } from '@material-ui/core/Checkbox'
import { CheckCircle, CloseBox, CloseCircle } from 'mdi-material-ui'
import { format } from 'date-fns'
import { green, red } from '@material-ui/core/colors'
import { ISOFormatString } from '../../utils'
import { ModuleControlContext } from '../../pages'

const CorrectCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green['A400'],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />)

const WrongCheckbox = withStyles({
  root: {
    '&$checked': {
      color: red['A400'],
    },
  },
  checked: {},
})((props: CheckboxProps) => (
  <Checkbox color="default" checkedIcon={<CloseBox />} {...props} />
))

interface AnswerProps extends AnswerData {
  clicked: boolean
  disabled: boolean
  question: ReactNode
  setClicked: (answerName: string) => void
}

interface AnswerData {
  name: string
  correct: boolean
  response?: ReactNode
}

interface QuizProps {
  id: string
  /**
   * If this is set to true then the user will only be able to attempt to answer
   * this question a single time. After their first attempt all answers will be
   * disabled. */
  once: boolean
  /**
   * If singleAnswer is set then the question will only save the last answered question.
   */
  singleAnswer: boolean
  /**
   * If true response will be displayed below the quiz instead of in a dialog.
   * */
  inline: boolean
  question: ReactNode
  fallbackResponse?: ReactNode
  answers: AnswerData[]
}

export const Quiz: FunctionComponent<QuizProps> = (props) => {
  const context = useContext(ModuleControlContext)
  const key = `Quiz_${props.id}`
  const keyData = context.moduleData?.[key]
  const anyAnswerGiven =
    typeof keyData === 'object' &&
    Object.keys(keyData ? keyData : {}).length > 0

  function saveAnswer(answerName: string) {
    if (!(anyAnswerGiven && props.once)) {
      let existingData: { [childKey: string]: string } = {}
      if (!props.singleAnswer) {
        existingData = keyData && typeof keyData === 'object' ? keyData : {}
      }
      existingData[answerName] = format(new Date(), ISOFormatString)

      context.Update({
        ...context.moduleData,
        [key]: existingData,
      })
    }
  }

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid xs item>
          <Typography>{props.question}</Typography>
        </Grid>
        <Grid item>
          <FormGroup row>
            {props.answers.map((answer, index) => {
              let answerChecked = false
              if (keyData && typeof keyData === 'object') {
                answerChecked = keyData[answer.name] ? true : false
              }

              return (
                <Answer
                  key={index}
                  name={answer.name}
                  correct={answer.correct}
                  question={props.question}
                  response={
                    props.inline
                      ? undefined
                      : answer.response
                      ? answer.response
                      : props.fallbackResponse
                  }
                  clicked={answerChecked}
                  disabled={answerChecked || (anyAnswerGiven && props.once)}
                  setClicked={saveAnswer}
                />
              )
            })}
          </FormGroup>
        </Grid>
      </Grid>
      {props.inline ? (
        <Fade in={anyAnswerGiven} timeout={2000}>
          <Box mt={2}>{props.fallbackResponse}</Box>
        </Fade>
      ) : null}
    </Fragment>
  )
}

export const Answer = ({
  correct,
  response,
  name,
  disabled,
  setClicked,
  clicked,
  question,
}: AnswerProps) => {
  const [open, setOpen] = useState(false)

  function handleClickOpen(event: ChangeEvent<HTMLInputElement>) {
    setClicked(name)
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  return (
    <Fragment>
      <FormControlLabel
        control={
          correct ? (
            <CorrectCheckbox
              checked={clicked}
              disabled={disabled && !clicked}
              onChange={handleClickOpen}
            />
          ) : (
            <WrongCheckbox
              checked={clicked}
              disabled={disabled && !clicked}
              onChange={handleClickOpen}
            />
          )
        }
        label={name}
      />
      {response ? (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            <Box clone display="flex" flexDirection="row">
              <DialogTitle disableTypography>
                {correct ? (
                  <Box clone color={green['A400']}>
                    <CheckCircle fontSize="large" />
                  </Box>
                ) : (
                  <Box clone color={red['A400']}>
                    <CloseCircle fontSize="large" />
                  </Box>
                )}
                <Box ml={3}>
                  <Typography>{question}</Typography>
                </Box>
              </DialogTitle>
            </Box>
            {response}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </Fragment>
  )
}
