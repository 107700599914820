import React, { forwardRef, ImgHTMLAttributes, Ref } from 'react'
import { makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  img: {
    maxWidth: '100%',
    objectFit: 'contain',
    display: 'block',
    margin: '0 auto',
  },
}))

export const Img = forwardRef(
  (props: ImgHTMLAttributes<HTMLElement>, ref: Ref<HTMLImageElement>) => {
    const classes = useStyles()
    const { src, alt, ...other } = props
    return (
      <Box my={2}>
        <img ref={ref} className={classes.img} src={src} alt={alt} {...other} />
      </Box>
    )
  },
)
