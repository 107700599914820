import React from 'react'
import { AdapterLink } from '..'
import { Button } from '@material-ui/core'

type InternalButtonLinkProps = {
  redirectUrl: string
  buttonText: string
}

export const InternalButtonLink = ({
  redirectUrl,
  buttonText,
}: InternalButtonLinkProps) => (
  <Button
    component={AdapterLink}
    href={redirectUrl}
    variant="contained"
    color="primary"
  >
    {buttonText}
  </Button>
)
