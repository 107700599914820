import React, { ChangeEventHandler, FunctionComponent, useContext } from 'react'
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import { ModuleControlContext } from '../../pages'
import { confidenceQuestions } from '.'

interface ConfidenceQuestionProps {
  id: string
  begin: boolean
}

export enum ConfidenceValues {
  notConfident = 'notConfident',
  littleConfident = 'littleConfident',
  somewhatConfident = 'somewhatConfident',
  veryConfident = 'veryConfident',
}

export const ConfidenceQuestion: FunctionComponent<ConfidenceQuestionProps> = (
  props,
) => {
  const context = useContext(ModuleControlContext)
  const key = `Confidence_${props.id}_${props.begin ? 'begin' : 'end'}`
  const keyData = context.moduleData?.[key] ?? ''
  const question = confidenceQuestions.find((value) => value.value === props.id)

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    context.Update({
      ...context.moduleData,
      [key]: event.currentTarget.value,
    })
  }

  return (
    <Box mb={3}>
      <Card>
        <CardContent>
          <Typography paragraph variant="h6">
            {props.begin
              ? "Next, before you complete this module, we'd like to know how confident you feel in this area of parenting. We'll ask you this question again at the end of the module."
              : "Now that you've completed this module, we'd like to know how confident you feel in this area of parenting your teenager."}
          </Typography>
          <Typography paragraph>{question?.label}</Typography>
          <RadioGroup
            row
            aria-label="confidence"
            name="radio"
            value={keyData}
            onChange={handleChange}
          >
            <FormControlLabel
              control={<Radio />}
              value={ConfidenceValues.notConfident}
              label="Not at all confident"
            />
            <FormControlLabel
              value={ConfidenceValues.littleConfident}
              control={<Radio />}
              label="A little confident"
            />
            <FormControlLabel
              value={ConfidenceValues.somewhatConfident}
              control={<Radio />}
              label="Somewhat confident"
            />
            <FormControlLabel
              value={ConfidenceValues.veryConfident}
              control={<Radio />}
              label="Very confident"
            />
          </RadioGroup>
        </CardContent>
      </Card>
    </Box>
  )
}
