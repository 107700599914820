import * as Yup from 'yup'
import React, { FunctionComponent } from 'react'
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { FormikTextField } from '../components'
import { gql } from 'graphql.macro'
import { LockReset } from 'mdi-material-ui'
import { loginUrl } from '../routes'
import { useNavigation } from 'react-navi'
import {
  useResetPasswordMutation,
  useResetPasswordTokenMutation,
} from '../types'
import { useSnackbar } from 'notistack'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}))

const LoginTokenValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/,
      'Password must contain at least one upper case character, one lower, and one number, with a minimum length of 10',
    )
    .required('Required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Password confirmation is required'),
})

const LoginValidationSchema = Yup.object().shape({
  current: Yup.string().required('Required'),
  newPassword: Yup.string()
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{10,}$/,
      'Password must contain at least one upper case character, one lower, and one number, with a minimum length of 10',
    )
    .required('Required'),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords must match')
    .required('Password confirmation is required'),
})

export const RESET_PASSWORD = gql`
  mutation ResetPassword($current: String!, $newPassword: String!) {
    resetPassword(current: $current, newPassword: $newPassword)
  }
`

export const RESET_PASSWORD_TOKEN = gql`
  mutation ResetPasswordToken($token: String!, $newPassword: String!) {
    resetPasswordToken(token: $token, newPassword: $newPassword)
  }
`

interface ResetPasswordProps {
  isLoggedIn: boolean
  token: string
}

export const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  isLoggedIn,
  token,
}) => {
  const classes = useStyles()
  const navigation = useNavigation()
  const { enqueueSnackbar } = useSnackbar()
  const [resetPassword] = useResetPasswordMutation()
  const [resetPasswordToken] = useResetPasswordTokenMutation()

  const InitialFormData = {
    token: token ? token : '',
    current: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  if (!token && !isLoggedIn) {
    return (
      <Typography>
        If you have forgotten your password. Click the 'I forgot my password'
        button on the login page for instructions on resetting your password.
      </Typography>
    )
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockReset />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Your Password
        </Typography>
        <Formik
          onSubmit={async ({ current, token, newPassword }) => {
            let response

            // choose mutation
            if (token) {
              response = await resetPasswordToken({
                variables: { token, newPassword },
              })
            } else {
              response = await resetPassword({
                variables: { current, newPassword },
              })
            }

            // print response
            if (response && response.errors) {
              enqueueSnackbar(response.errors[0].message, {
                variant: 'error',
              })
            }
            if (response && response.data) {
              enqueueSnackbar('Password Reset', { variant: 'success' })
              navigation.navigate(loginUrl)
            }
          }}
          initialValues={InitialFormData}
          validationSchema={
            token ? LoginTokenValidationSchema : LoginValidationSchema
          }
        >
          {({ isSubmitting, isValidating }) => (
            <Form className={classes.form}>
              <Box display="none">
                <Field
                  name="username"
                  label="username"
                  autoComplete="username"
                />
              </Box>
              {isLoggedIn && !token ? (
                <Field
                  as={FormikTextField}
                  variant="filled"
                  margin="normal"
                  required
                  fullWidth
                  name="current"
                  label="Current Password"
                  type="password"
                  autoComplete="current-password"
                />
              ) : null}
              <Field
                as={FormikTextField}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="New Password"
                type="password"
                autoComplete="new-password"
              />
              <Field
                as={FormikTextField}
                variant="filled"
                margin="normal"
                required
                fullWidth
                name="confirmNewPassword"
                label="Confirm New Password"
                type="password"
                autoComplete="new-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                className={classes.submit}
              >
                {isSubmitting || isValidating ? (
                  <CircularProgress size={24} />
                ) : (
                  'Reset Password'
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  )
}
