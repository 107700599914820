import React, { useContext } from 'react'
import { ModuleControlContext } from '../../pages'
import { Link, styled } from '@material-ui/core'

const CursorLink = styled(Link)({
  '&:hover': {
    cursor: 'pointer',
  },
})

export const GoTo = ({ page, children }) => {
  const context = useContext(ModuleControlContext)

  function onClick() {
    context.GoTo(page)
  }

  return <CursorLink onClick={onClick}>{children}</CursorLink>
}
