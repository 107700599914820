import { primaryColour, secondaryColour } from '../theme'

export type Colors =
  | 'blue'
  | 'darkCerulean'
  | 'deepPurple'
  | 'green'
  | 'irisBlue'
  | 'maroon'
  | 'orange'
  | 'pelorous'
  | 'purple'
  | 'purpleHeart'
  | 'teal'
  | 'themeMain'
  | 'themeSecondary'

export const customColorOptions: Colors[] = [
  'blue',
  'darkCerulean',
  'deepPurple',
  'green',
  'irisBlue',
  'maroon',
  'orange',
  'pelorous',
  'purple',
  'purpleHeart',
  'teal',
  'themeMain',
  'themeSecondary',
]

export function getColorHex(color: Colors) {
  switch (color) {
    case 'themeMain':
      return primaryColour
    case 'themeSecondary':
      return secondaryColour
    case 'blue':
      return '#1976d2'
    case 'darkCerulean':
      return '#0E4686'
    case 'pelorous':
      return '#1C80AD'
    case 'irisBlue':
      return '#03BDBD'
    case 'teal':
      return '#009aa0'
    case 'purple':
      return '#4b4bab'
    case 'maroon':
      return '#a0282e'
    case 'green':
      return '#005f56'
    case 'deepPurple':
      return '#4a126b'
    case 'purpleHeart':
      return '#6B2394'
    case 'orange':
      return '#af3711'
    default:
      return '#000'
  }
}
