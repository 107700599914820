import React, { useContext } from 'react'
import { Button, Box } from '@material-ui/core'
import { dashboardUrl, memberUrl } from '../../routes'
import { gql } from 'graphql.macro'
import { memberDashboardQueryName, ModuleControlContext } from '../../pages'
import { useFinishModuleMutation } from '../../types'
import { useNavigation } from 'react-navi'
import { useSnackbar } from 'notistack'

export const FINISH_MODULE = gql`
  mutation finishModule($moduleID: ID!) {
    moduleComplete(moduleID: $moduleID)
  }
`

export const FinishModule = () => {
  const context = useContext(ModuleControlContext)
  const snackbar = useSnackbar()
  const navigation = useNavigation()
  const [mutation] = useFinishModuleMutation({
    variables: { moduleID: context.moduleID ? context.moduleID : '' },
    onCompleted: (data) => {
      if (data.moduleComplete) {
        snackbar.enqueueSnackbar("You've completed a module", {
          variant: 'success',
        })
      }
      navigation.navigate(`${memberUrl}${dashboardUrl}`)
    },
    refetchQueries: [memberDashboardQueryName],
    awaitRefetchQueries: true,
  })

  return (
    <Box display="flex" justifyContent="center">
      <Button
        onClick={() => {
          mutation()
        }}
        variant="contained"
        color="primary"
        size="large"
      >
        Click here to finish module
      </Button>
    </Box>
  )
}
