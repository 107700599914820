import { gql } from 'graphql.macro'

export const SCHEDULED_SURVEYS_FRAGMENT = gql`
  fragment scheduledSurveys on ScheduledSurvey {
    id
    survey {
      id
      name
    }
  }
`

export const MODULES_FRAGMENT = gql`
  fragment moduleData on Module {
    id
    description
    imageUrl
    name
    number
    type
  }
`

export const MODULES_UPDATE_FRAGMENT = gql`
  fragment moduleUpdateFields on Module {
    id
    name
    content
    description
    imageUrl
  }
`

export const PAGE_DATA_FRAGMENT = gql`
  fragment pageData on Page {
    id
    content
    menuName
    route
    showInMenu
  }
`

export const LOG_EVENT = gql`
  mutation eventLog($name: String!, $message: String!) {
    logEvent(name: $name, message: $message)
  }
`
