import React, {
  createContext,
  FunctionComponent,
  useEffect,
  useState,
  useMemo,
} from 'react'
import {
  Box,
  Button,
  Container,
  createStyles,
  Divider,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  MobileStepper,
  Theme,
} from '@material-ui/core'
import { BusyCircular, MemoMDX } from '../components'
import { gql } from 'graphql.macro'
import {
  useEventLogMutation,
  useGetModuleStatusQuery,
  useSaveModuleMutation,
  useSetGoalMutation,
} from '../types'
import { useToggle } from '../utils'
import { Alert } from '@material-ui/lab'

const drawerWidth = 160

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar as any,
  }),
)

export const MODULE_STATUS = gql`
  query getModuleStatus($moduleID: ID!) {
    moduleStatus(moduleID: $moduleID) {
      data
      completed
      module {
        id
        name
        content
      }
    }
  }
`

export const SAVE_MODULE_DATA = gql`
  mutation saveModule($moduleID: ID!, $data: String!) {
    saveModuleData(moduleID: $moduleID, data: $data)
  }
`

export const SET_GOAL = gql`
  mutation setGoal($goal: SetGoalInput) {
    setGoal(goal: $goal)
  }
`

export interface ModuleControlContextProps {
  GoTo: (step: number) => void
  Update: (moduleState: ModuleState) => void
  SetGoal: (name: string, description: string) => void
  color: string
  activeStep: number
  moduleID: undefined | string
  moduleData: undefined | ModuleState
  moduleCompleted: boolean
}

export type ModuleKeyObject = { [childKey: string]: string }

export interface ModuleState {
  [dataKey: string]: string | ModuleKeyObject
}

export type ModulePage = {
  page: string
  unlockThreshold?: number
}

export type ModuleJSON = {
  name?: string
  color?: string
  backgroundUrl?: string
  pages?: ModulePage[]
}

interface ModuleProps {
  moduleID: string
}

export const ModuleControlContext = createContext<ModuleControlContextProps>({
  GoTo: () => {},
  Update: () => {},
  SetGoal: () => {},
  color: '#fff',
  activeStep: 0,
  moduleID: undefined,
  moduleData: undefined,
  moduleCompleted: false,
})

export const Module: FunctionComponent<ModuleProps> = ({ moduleID }) => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [moduleState, setModuleState] = useState<ModuleState>({})
  const [drawerOpen, toggleDrawerOpen] = useToggle(false)
  const { loading, data, error } = useGetModuleStatusQuery({
    variables: { moduleID },
    fetchPolicy: 'network-only',
  })
  const [saveModule] = useSaveModuleMutation()
  const [saveGoal] = useSetGoalMutation()
  const [eventLog] = useEventLogMutation({
    errorPolicy: 'ignore',
    variables: { name: 'module_opened', message: moduleID },
  })

  const moduleData = data?.moduleStatus?.data
  const moduleCompleted = data?.moduleStatus?.completed
  const moduleContent = useMemo(() => {
    const content = data?.moduleStatus?.module?.content
    if (content) {
      return JSON.parse(content) as ModuleJSON
    }
    return undefined
  }, [data])

  if (process.env.NODE_ENV === 'development') {
    console.log('key count', Object.keys(moduleState).length)
    console.log(
      'next page threshold',
      moduleContent?.pages?.[activeStep + 1]?.unlockThreshold,
    )
  }

  useEffect(() => {
    eventLog()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Load initial module state from api
  useEffect(() => {
    if (!loading) {
      const moduleJSON = moduleData ? JSON.parse(moduleData) : {}
      if (process.env.NODE_ENV !== 'production') {
        console.log('State reset from query')
        console.log(moduleJSON)
      }
      setModuleState(moduleJSON)
    }
  }, [moduleData, loading])

  // Reset scroll to the top of the page on a page transition to avoid users
  // starting at the bottom of the page. Especially on mobile. Popups interfere
  // with the reset at the moment
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  function handleNext() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  function handleGoTo(step: number) {
    if (
      moduleContent?.pages &&
      step >= 0 &&
      step < moduleContent?.pages?.length
    ) {
      setActiveStep(step)
    }
  }

  function unblocked(unblockThreshold: number | undefined) {
    return unblockThreshold
      ? Object.keys(moduleState).length >= unblockThreshold
      : true
  }

  function handleGoalSet(name: string, description: string) {
    saveGoal({ variables: { goal: { moduleID, name, description } } })
  }

  function handleModuleState(state: ModuleState) {
    setModuleState(state)
    saveModule({ variables: { moduleID, data: JSON.stringify(state) } })
  }

  if (error) {
    return <Alert severity="error">{error.message}</Alert>
  }

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  const drawerContents = (
    <List>
      <ListItem>
        <ListItemText primary={moduleContent?.name} />
      </ListItem>
      <Divider />
      {moduleContent?.pages?.map((page, index) => {
        const isUnblocked = unblocked(page.unlockThreshold)

        return (
          <ListItem
            button
            key={index}
            onClick={() => {
              handleGoTo(index)
              toggleDrawerOpen(false)
            }}
            disabled={!isUnblocked}
            selected={activeStep === index}
          >
            <ListItemText primary={index === 0 ? 'Home' : `Page ${index}`} />
          </ListItem>
        )
      })}
    </List>
  )

  return (
    <Box display="flex">
      <ModuleControlContext.Provider
        value={{
          GoTo: handleGoTo,
          Update: handleModuleState,
          SetGoal: handleGoalSet,
          activeStep,
          color: moduleContent?.color ?? '#fff',
          moduleID,
          moduleData: moduleState,
          moduleCompleted: moduleCompleted ? true : false,
        }}
      >
        <Hidden lgUp>
          <Drawer
            className={classes.drawer}
            variant="temporary"
            open={drawerOpen}
            classes={{ paper: classes.drawerPaper }}
            onClose={toggleDrawerOpen}
          >
            {drawerContents}
          </Drawer>
        </Hidden>
        <Hidden mdDown>
          <Drawer
            className={classes.drawer}
            variant="permanent"
            open={true}
            classes={{ paper: classes.drawerPaper }}
          >
            <div className={classes.toolbar} />
            {drawerContents}
          </Drawer>
        </Hidden>
        <Container>
          <Hidden lgUp>
            <Box mb={2}>
              <Button variant="outlined" onClick={toggleDrawerOpen}>
                Show Navigation
              </Button>
            </Box>
          </Hidden>
          <MemoMDX mdx={moduleContent?.pages?.[activeStep].page ?? ''} />
          <Box pt={2}>
            <MobileStepper
              position="static"
              variant="progress"
              square={false}
              steps={moduleContent?.pages?.length ?? 0}
              activeStep={activeStep}
              backButton={
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleBack}
                  disabled={
                    !unblocked(
                      moduleContent?.pages?.[activeStep - 1]?.unlockThreshold ??
                        0,
                    ) || activeStep === 0
                  }
                >
                  Back
                </Button>
              }
              nextButton={
                <Button
                  size="small"
                  variant="outlined"
                  color="primary"
                  onClick={handleNext}
                  disabled={
                    !unblocked(
                      moduleContent?.pages?.[activeStep + 1]?.unlockThreshold ??
                        0,
                    ) ||
                    (moduleContent?.pages &&
                      activeStep === moduleContent?.pages.length - 1)
                  }
                >
                  Next
                </Button>
              }
            />
          </Box>
        </Container>
      </ModuleControlContext.Provider>
    </Box>
  )
}
