import * as Yup from 'yup'
import React, { Fragment, FunctionComponent, lazy, useMemo } from 'react'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from '@material-ui/core'
import { BusyCircular, NoPermission } from '../components'
import { Controller, OnSubmit, useForm } from 'react-hook-form'
import { gql } from 'graphql.macro'
import { ModuleJSON, ModulePage } from '.'
import { PAGE_DATA_FRAGMENT, superUserID } from '../utils'
import {
  UpdatePageInput,
  useGetPageQuery,
  useUpdatePageMutation,
} from '../types'
import { useSnackbar } from 'notistack'

const ContentPagesField = lazy(() => import('../components/ContentPagesField'))

const pageValidation = Yup.object().shape({
  menuName: Yup.string().required(),
  route: Yup.string(),
  showInMenu: Yup.boolean(),
  content: Yup.object().shape({
    pages: Yup.array().of(
      Yup.object().shape({
        page: Yup.string().required(),
      }),
    ),
  }),
})

export const GET_PAGE = gql`
  query GetPage($id: ID!) {
    whoami {
      id
    }
    page(id: $id) {
      ...pageData
    }
  }
  ${PAGE_DATA_FRAGMENT}
`

// UpdatePageInput
export const UPDATE_PAGE = gql`
  mutation UpdatePage($id: ID!, $page: UpdatePageInput!) {
    updatePage(id: $id, page: $page) {
      ...pageData
    }
  }
  ${PAGE_DATA_FRAGMENT}
`

// Get data and custom modify data
export interface UpdatePageFormFields
  extends Omit<Required<UpdatePageInput>, 'content'> {
  pages: ModulePage[]
}

//Fetch data
export const EditPageFetch: FunctionComponent<{ id: string }> = ({ id }) => {
  const { data, loading, error } = useGetPageQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const page = data?.page

  const contentJson = useMemo(() => {
    if (page?.content) {
      return JSON.parse(page.content) as ModuleJSON
    }
    return undefined
  }, [page])

  return (
    <Fragment>
      {error ? <Alert title={error.message} /> : null}
      {loading ? (
        <BusyCircular isBusy={loading} delayMs={200} />
      ) : data?.whoami?.id !== superUserID ? (
        <NoPermission />
      ) : page ? (
        <EditPageForm
          id={id}
          data={{
            menuName: page.menuName ?? '',
            route: page.route ?? '',
            showInMenu: page.showInMenu ?? false,
            pages: contentJson?.pages ?? [],
          }}
        />
      ) : null}
    </Fragment>
  )
}

const EditPageForm: FunctionComponent<{
  id: string
  data: UpdatePageFormFields
}> = ({ id, data }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [
    updatePage,
    { loading: mutationLoading, error: mutationError },
  ] = useUpdatePageMutation({
    onCompleted: (data) => {
      if (data.updatePage?.id) {
        enqueueSnackbar('Page Updated', { variant: 'success' })
      }
    },
  })
  const { errors, control, handleSubmit, watch } = useForm<
    UpdatePageFormFields
  >({
    defaultValues: data,
    validationSchema: pageValidation,
  })

  const onSubmit: OnSubmit<UpdatePageFormFields> = async (data) => {
    const { pages, ...otherData } = data

    updatePage({
      variables: {
        id,
        page: {
          ...otherData,
          content: JSON.stringify({ pages }),
        },
      },
    })
  }

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        {mutationError ? (
          <Alert severity="error" title={mutationError.message} />
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Controller
              as={TextField}
              control={control}
              disabled={mutationLoading}
              error={errors.menuName ? true : false}
              fullWidth
              helperText={errors.menuName?.message ?? ' '}
              label="Page Name"
              name="menuName"
              variant="outlined"
            />
          </Grid>
          <Box display="none">
            <Grid item xs={6}>
              <Controller
                as={TextField}
                control={control}
                disabled={mutationLoading}
                error={errors.route ? true : false}
                fullWidth
                helperText={errors.route?.message ?? ' '}
                name="route"
                label="Route"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Controller
                    as={<Switch color="primary" />}
                    control={control}
                    name="showInMenu"
                    valueName="checked"
                    disabled={mutationLoading}
                    variant="outlined"
                  />
                }
                label="Display in Menu"
              />
            </Grid>
          </Box>
          <Grid item xs={6}>
            <Button type="submit" disabled={mutationLoading} size="large">
              Apply Changes
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ContentPagesField control={control} name="pages" watch={watch} />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
