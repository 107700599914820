import { ModuleControlContextProps } from '../pages'
import { format } from 'date-fns'

export function getRandomInt(min: number, max: number) {
  min = Math.ceil(min)
  max = Math.floor(max)
  return Math.floor(Math.random() * (max - min)) + min //The maximum is exclusive and the minimum is inclusive
}

export const ISOFormatString = "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"

export const addDateKey = (key: string, context: ModuleControlContextProps) => {
  context.Update({
    ...context.moduleData,
    [key]: format(new Date(), ISOFormatString),
  })
}

export const addDataKey = (
  key: string,
  data: string,
  context: ModuleControlContextProps,
) => {
  context.Update({
    ...context.moduleData,
    [key]: data,
  })
}

/**
 * Manually reorder an array by moving an item from startIndex to endIndex
 */
export function reorder<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

/**
 * MIN = Minimum expected value
 * MAX = Maximium expected value
 * Function to normalise the values (MIN / MAX could be integrated)
 */
export const normalise = (value: number, min: number, max: number) =>
  ((value - min) * 100) / (max - min)

/**
 * Generate a basic hash
 * @param str
 */
export function hashCode(str: string) {
  return str
    .split('')
    .reduce(
      (prevHash, currVal) =>
        ((prevHash << 5) - prevHash + currVal.charCodeAt(0)) | 0,
      0,
    )
}
