import React, { FunctionComponent, MouseEvent } from 'react'
import { adminUrl, moduleUrl } from '../routes'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core'
import { BusyCircular, NoPermission } from '../components'
import { gql } from 'graphql.macro'
import { MODULES_FRAGMENT, superUserID } from '../utils'
import { useGetAllModulesQuery } from '../types'
import { useNavigation } from 'react-navi'

export const GET_ALL_MODULES = gql`
  query GetAllModules {
    whoami {
      id
    }
    modules {
      ...moduleData
    }
  }
  ${MODULES_FRAGMENT}
`

export const EditModules: FunctionComponent = () => {
  const navigation = useNavigation()
  const { data, loading } = useGetAllModulesQuery()

  function goToModule(event: MouseEvent<HTMLButtonElement>) {
    navigation.navigate(
      `${adminUrl}${moduleUrl}/${encodeURIComponent(event.currentTarget.id)}`,
    )
  }

  if (data?.whoami?.id !== superUserID && !loading) {
    return <NoPermission />
  }

  return (
    <Box mb={3}>
      <BusyCircular isBusy={loading} delayMs={200} />
      <Grid container spacing={3}>
        {data?.modules?.map((module) => (
          <Grid key={module.id} item xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea id={module.id} onClick={goToModule}>
                {module.imageUrl ? (
                  <CardMedia
                    height={30}
                    component="img"
                    image={module.imageUrl}
                    title={`${module.name} image`}
                  />
                ) : null}
                <CardContent>
                  <Typography variant="h5">{module.name}</Typography>
                  <Typography color="textSecondary" variant="body2">
                    {module.description}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  )
}
