import React from 'react'
import { Box, Grid, Typography } from '@material-ui/core'
import { GridSize } from '@material-ui/core/Grid'
import { Img } from '.'

interface ImageGridItems {
  src: string
  alt?: string
  text?: string
  fullWidth?: boolean
}

interface ImageGridProps {
  /** Defines how much space a grid item should take up at md size (about laptop
   * size)*/
  mdGrid?: GridSize
  /** Defines how much space a grid item should take up at sm size (about tablet
   * size)*/
  smGrid?: GridSize
  list: ImageGridItems[]
}

/**
 * React component for placing a single image in the document. Should scale the
 * image to fit the screen width if the screen is smaller than the image.
 */
export const ImageGrid = ({ list, mdGrid, smGrid }: ImageGridProps) => {
  return (
    <Grid alignItems="center" justify="space-evenly" container spacing={5}>
      {list.map((image, index) => (
        <Grid
          key={index}
          xs={12}
          sm={image.fullWidth ? undefined : smGrid ? smGrid : 6}
          md={image.fullWidth ? undefined : mdGrid ? mdGrid : 4}
          item
        >
          {image.text ? (
            <Box p={1}>
              <Typography align="center" variant="h6">
                {image.text}
              </Typography>
            </Box>
          ) : null}
          {image.src ? (
            <Img
              alt={image.alt ? image.alt : `Image in grid ${index}`}
              src={image.src}
            />
          ) : null}
        </Grid>
      ))}
    </Grid>
  )
}
