import React, { Component, ErrorInfo } from 'react'
import { Box, Card, CardContent, Typography } from '@material-ui/core'

interface IErrorState {
  hasError: boolean
  error: Error | null
}

/**
 * ErrorBoundary Component for reporting errors when attempting to render an mdx
 * page preview
 */
export class ModuleErrorBoundary extends Component<{}, IErrorState> {
  constructor(props: {}) {
    super(props)
    this.state = { hasError: false, error: null }
  }

  public componentDidCatch(error: Error, info: ErrorInfo) {
    this.setState({ hasError: true, error })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <Box mb={4}>
          <Card>
            <CardContent>
              <Typography variant="h6" paragraph={true}>
                The markdown code is invalid and we were unable to parse the
                page.
              </Typography>
              {this.state.error !== null ? (
                <Box mt={2}>
                  <Typography>Here is the error that was reported:</Typography>
                  <pre>Error: {this.state.error.stack}</pre>
                </Box>
              ) : null}
            </CardContent>
          </Card>
        </Box>
      )
    }

    return this.props.children
  }
}
