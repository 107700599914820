import HelmetProvider from 'react-navi-helmet'
import React, { Suspense } from 'react'
import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { ApolloProvider } from '@apollo/react-hooks'
import { BusyCircular, ErrorBoundary } from './components'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory'
import { MuiThemeProvider } from '@material-ui/core'
import { onError } from 'apollo-link-error'
import { Router, View } from 'react-navi'
import { routes } from './routes'
import { SnackbarProvider } from 'notistack'
import { theme } from './theme'

const client = new ApolloClient({
  defaultOptions: {
    watchQuery: { fetchPolicy: 'cache-and-network', errorPolicy: 'all' },
    query: { fetchPolicy: 'network-only', errorPolicy: 'all' },
    mutate: { errorPolicy: 'none' },
  },
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (process.env.NODE_ENV !== 'production') {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.log(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          )
        if (networkError) console.log(`[Network error]: ${networkError}`)
      }
    }),
    new HttpLink({
      uri: `${process.env.REACT_APP_API}${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
      credentials: 'include',
    }),
  ]),
  cache: new InMemoryCache(),
})

export interface AppNaviContext {
  client: ApolloClient<NormalizedCacheObject>
}

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <HelmetProvider>
            <SnackbarProvider maxSnack={1}>
              <Suspense fallback={<BusyCircular isBusy={true} delayMs={200} />}>
                <Router routes={routes} context={{ client } as AppNaviContext}>
                  <View />
                </Router>
              </Suspense>
            </SnackbarProvider>
          </HelmetProvider>
        </ApolloProvider>
      </ErrorBoundary>
    </MuiThemeProvider>
  )
}

export default App
