import { addDataKey } from '../../utils'
import { ModuleControlContext } from '../../pages'
import { TextField } from '@material-ui/core'
import React, { ChangeEvent, FunctionComponent, useContext } from 'react'

interface TextInputProps {
  id: string
}

export const TextInput: FunctionComponent<TextInputProps> = ({ id }) => {
  const context = useContext(ModuleControlContext)
  const key = `TextInput_${id}`
  const keyData = context.moduleData?.[key]

  function change(event: ChangeEvent<HTMLTextAreaElement>) {
    addDataKey(key, event.currentTarget.value, context)
  }

  return (
    <TextField
      onChange={change}
      defaultValue={keyData}
      id="outlined-textarea"
      label="Enter text here"
      multiline
      rows="3"
      margin="normal"
      variant="filled"
      fullWidth={true}
    />
  )
}
