import * as Sentry from '@sentry/browser'
import React, { Dispatch, Fragment, useState } from 'react'
import { Alert } from '@material-ui/lab'
import { Box, Typography } from '@material-ui/core'
import { BusyCircular, BusyLinear } from './LoadingIndicators'
import { dashboardUrl, howToUrl, feedbackUrl, logoutUrl } from '../routes'
import { feedbackSurveyID, participantMemberTypeChild } from '../utils'
import { gql } from 'graphql.macro'
import {
  useGetPlainSurveyQuery,
  useGetSurveyQuery,
  useSaveSurveyMutation,
} from '../types'
import { SetStateAction } from 'react'
import { Survey, SurveyModel } from 'survey-react'
import { useNavigation } from 'react-navi'
import { useSnackbar } from 'notistack'
import '../assets/survey.css'

export const GET_SURVEY = gql`
  query getSurvey($survey: ID!) {
    survey(id: $survey) {
      id
      content
      name
      version
      allowedAttempts
    }
    loadSurveyData(id: $survey) {
      id
      data
    }
    surveyStats(surveyID: $survey) {
      id
      completions
    }
    myParticipation {
      id
      members {
        id
        person {
          id
          firstName
          gender {
            id
            code
          }
        }
        participantMemberType {
          id
          name
        }
      }
    }
  }
`

export const GET_PLAIN_SURVEY = gql`
  query getPlainSurvey($survey: ID!) {
    survey(id: $survey) {
      id
      content
      name
      version
    }
  }
`

export const SAVE_SURVEY = gql`
  mutation saveSurvey(
    $surveyVersionID: ID!
    $data: String!
    $completed: Boolean!
  ) {
    saveSurveyData(
      surveyVersionID: $surveyVersionID
      data: $data
      completed: $completed
    )
  }
`

export const pronounSubstitutes: {
  name: string
  singular: string
  plural: string
}[] = [
  {
    name: 'has_have',
    singular: 'has',
    plural: 'have',
  },
  {
    name: 'is_are',
    singular: 'is',
    plural: 'are',
  },
  {
    name: 'wants_want',
    singular: 'wants',
    plural: 'want',
  },
  {
    name: 'learns_learn',
    singular: 'learns',
    plural: 'learn',
  },
  {
    name: 'hasnt_havent',
    singular: 'hasn’t',
    plural: 'haven’t',
  },
  {
    name: 'trusts_trust',
    singular: 'trusts',
    plural: 'trust',
  },
  {
    name: 'faces_face',
    singular: 'faces',
    plural: 'face',
  },
  {
    name: 'doesnt_dont',
    singular: 'doesn’t',
    plural: 'don’t',
  },
  {
    name: 'finds_find',
    singular: 'finds',
    plural: 'find',
  },
  {
    name: 'self_selves',
    singular: 'self',
    plural: 'selves',
  },
  {
    name: 'sees_see',
    singular: 'sees',
    plural: 'see',
  },
  {
    name: 'realise_realises',
    singular: 'realises',
    plural: 'realise',
  },

  {
    name: 'was_were',
    singular: 'was',
    plural: 'were',
  },
  {
    name: 'thinks_think',
    singular: 'thinks',
    plural: 'think',
  },
  {
    name: 'needs_need',
    singular: 'needs',
    plural: 'need',
  },
]

function setSingular(object: { [key: string]: string }) {
  pronounSubstitutes.forEach(
    (pronounObject) => (object[pronounObject.name] = pronounObject.singular),
  )
  return object
}

function setPlural(object: { [key: string]: string }) {
  pronounSubstitutes.forEach(
    (pronounObject) => (object[pronounObject.name] = pronounObject.plural),
  )
  return object
}

type SurveyWrapperProps = {
  surveyName: string
}

function SurveyWrapper({ surveyName }: SurveyWrapperProps) {
  const { enqueueSnackbar } = useSnackbar()
  const navigation = useNavigation()
  const [surveyAnswersJSON, setSurveyAnswersJSON] = useState<
    undefined | { [key: string]: string }
  >(undefined)
  const { data, loading, error } = useGetSurveyQuery({
    variables: { survey: surveyName },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      function setChild(object: { [key: string]: string }) {
        const members = data.myParticipation?.members
        let child
        if (members) {
          child = members.find(
            (element) =>
              element.participantMemberType?.id === participantMemberTypeChild,
          )
        }

        const childName = child?.person?.firstName
        const childGender = child?.person?.gender?.code

        if (childName) {
          object['child_name'] = childName
        }

        switch (childGender) {
          case 'F':
            object['child_him_her'] = 'her'
            object['child_his_her'] = 'her'
            object['child_he_she'] = 'she'
            object = setSingular(object)
            break
          case 'M':
            object['child_him_her'] = 'him'
            object['child_his_her'] = 'his'
            object['child_he_she'] = 'he'
            object = setSingular(object)
            break
          default:
            object['child_him_her'] = 'them'
            object['child_his_her'] = 'their'
            object['child_he_she'] = 'they'
            object = setPlural(object)
        }
        return object
      }

      let json = {}
      try {
        json = JSON.parse(data.loadSurveyData?.data ?? '{}')
      } catch (err) {
        Sentry.captureException(err)
      }
      setSurveyAnswersJSON(setChild(json))
    },
  })
  const [
    saveSurvey,
    { loading: loadingSave, error: errorSave },
  ] = useSaveSurveyMutation()

  const surveyVersionID = data?.survey.version
  const surveyContent = data?.survey.content
  const surveyCompletions = data?.surveyStats.completions ?? 0
  const surveyAllowedAttempts = data?.survey.allowedAttempts ?? 1

  async function uploadSurvey(complete: boolean, sender: SurveyModel) {
    if (!surveyVersionID) {
      enqueueSnackbar(
        "Data is missing. We can't save survey results to the server.",
        { variant: 'error' },
      )
      return
    }

    let result

    try {
      result = await saveSurvey({
        awaitRefetchQueries: true,
        refetchQueries: ['getMyParticipation'],
        variables: {
          completed: complete,
          data: JSON.stringify(sender.data),
          surveyVersionID: surveyVersionID,
        },
      })
    } catch (error) {
      if (error.message.includes('401')) {
        enqueueSnackbar(
          "We couldn't save your progress. You're login session has expired.",
          {
            variant: 'error',
          },
        )
        navigation.navigate(logoutUrl)
      } else {
        enqueueSnackbar('error.message', {
          variant: 'error',
        })
      }
    }

    return result?.data?.saveSurveyData
  }

  function saveIncompleteSurvey(sender: SurveyModel) {
    uploadSurvey(false, sender)
  }

  function completeSurvey(sender: SurveyModel) {
    const saveSurveyData = uploadSurvey(true, sender)

    if (saveSurveyData) {
      enqueueSnackbar('You’ve Completed the Parent Survey', {
        variant: 'success',
      })
      const redirectUrl =
        data?.survey.id === feedbackSurveyID
          ? process.env.REACT_APP_HOW_TO === 'true'
            ? `..${howToUrl}`
            : `..${feedbackUrl}`
          : `..${dashboardUrl}`
      navigation.navigate(redirectUrl)
    }
  }

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  if (surveyCompletions >= surveyAllowedAttempts) {
    return (
      <Box p={2}>
        <Typography paragraph variant="h4">
          You have already completed this survey
        </Typography>
      </Box>
    )
  }

  return (
    <Fragment>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      {errorSave ? <Alert severity="error">{errorSave.message}</Alert> : null}
      <BusyLinear isBusy={loadingSave} delayMs={200} />
      {surveyContent && surveyAnswersJSON ? (
        <Survey
          json={surveyContent}
          data={surveyAnswersJSON}
          onComplete={completeSurvey}
          onCurrentPageChanged={saveIncompleteSurvey}
        />
      ) : (
        <Typography paragraph>No Survey Content Found</Typography>
      )}
    </Fragment>
  )
}

interface SurveyDataWrapperProps {
  surveyName: string
  setData: Dispatch<SetStateAction<string>>
}

export function SurveyDataWrapper({
  surveyName,
  setData,
}: SurveyDataWrapperProps) {
  const { data, loading, error } = useGetPlainSurveyQuery({
    variables: { survey: surveyName },
    fetchPolicy: 'network-only',
  })

  function returnData(sender: SurveyModel) {
    setData(JSON.stringify(sender.data))
  }

  if (error) {
    return <div>Error! {error.message}</div>
  }

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  const surveyContent = data?.survey.content

  return surveyContent ? (
    <Survey json={surveyContent} onComplete={returnData} />
  ) : (
    <Typography>Survey Content Not Found. Try refreshing the page.</Typography>
  )
}

export default SurveyWrapper
