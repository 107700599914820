import React, { FunctionComponent, Fragment } from 'react'
import pip_logo from '../assets/pip_logo.svg'
import pip_logo_small from '../assets/pip_logo_small.svg'
import {
  activeModuleLinks,
  LinkInfo,
  memberUrl,
  moduleLinks,
  moduleUrl,
  noUserLinks,
  userLinks,
} from '../routes'
import { AdapterLink } from './CustomLink'
import {
  AppBar,
  Box,
  Button,
  makeStyles,
  Menu,
  MenuItem,
  Toolbar,
  useMediaQuery,
  useTheme,
  Tooltip,
} from '@material-ui/core'
import {
  bindMenu,
  bindTrigger,
  usePopupState,
} from 'material-ui-popup-state/hooks'
import {
  ModuleType,
  useGetWhoamiQuery,
  useDynamicHeaderLinksQuery,
} from '../types'
import { useActive } from 'react-navi'
import { UserType } from '../utils'
import { gql } from 'graphql.macro'
import { BusyLinear } from '.'
import { Alert } from 'mdi-material-ui'

export const DYNAMIC_HEADER_LINKS = gql`
  query DynamicHeaderLinks {
    pages {
      id
      menuName
      route
      showInMenu
    }
  }
`

const useStyles = makeStyles(({ zIndex }) => ({
  appBar: {
    zIndex: zIndex.drawer + 1,
  },
}))

interface LinkRenderProps {
  links: LinkInfo[]
}

const DesktopLinks: FunctionComponent<LinkRenderProps> = ({ links }) => {
  return (
    <Box display={{ xs: 'none', md: 'flex' }}>
      {links.map((link) => (
        <Button
          key={link.title}
          color="inherit"
          component={AdapterLink}
          prefetch={false}
          href={link.href}
        >
          {link.title}
        </Button>
      ))}
    </Box>
  )
}

const MobileLinks: FunctionComponent<LinkRenderProps> = ({ links }) => {
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'mobileLinksMenu',
  })
  return (
    <Box display={{ xs: 'block', md: 'none' }}>
      <Button color="inherit" {...bindTrigger(popupState)}>
        Pages
      </Button>
      <Menu {...bindMenu(popupState)}>
        {links.map((link) => (
          <MenuItem
            key={link.title}
            onClick={popupState.close}
            component={AdapterLink}
            href={link.href}
          >
            {link.title}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}

export const HeaderBar: FunctionComponent = () => {
  const classes = useStyles()
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const isSmallest = useMediaQuery(theme.breakpoints.down('xs'))
  const isActiveModule = useActive(`${memberUrl}${moduleUrl}`, {
    exact: false,
  })
  const customLogo = process.env.REACT_APP_CUSTOM_SITE_LOGO
  const { data } = useGetWhoamiQuery()
  const { data: linksData, loading, error } = useDynamicHeaderLinksQuery()

  const links: LinkInfo[] = []
  const logoHeight = isSmall ? 48 : 56
  const userType = data?.whoami?.userType?.name
  const moduleType = data?.myParticipation?.moduleType
  const dynamicLinks: LinkInfo[] =
    linksData?.pages
      ?.filter((link) =>
        link?.showInMenu && link.menuName && link.route ? true : false,
      )
      .map((link) => ({
        title: link?.menuName ?? '',
        href: link?.route ?? '',
      })) ?? []

  if (isActiveModule) {
    links.push(...activeModuleLinks[ModuleType.Module])
  } else {
    links.push(...dynamicLinks)

    switch (moduleType) {
      case ModuleType.Factsheet:
        links.push(...moduleLinks[ModuleType.Factsheet])
        break
      case ModuleType.Module:
        links.push(...moduleLinks[ModuleType.Module])
        break
    }

    switch (userType) {
      case UserType.Admin:
        links.push(...userLinks[UserType.Admin])
        break
      case UserType.Participant:
        links.push(...userLinks[UserType.Participant])
        break
      default:
        links.push(...noUserLinks)
        break
    }
  }

  return (
    <AppBar className={classes.appBar} position="static">
      <BusyLinear isBusy={loading} delayMs={200} />
      <Box clone display="flex">
        <Toolbar>
          <img
            height={logoHeight}
            src={customLogo ? customLogo : pip_logo}
            alt="Site Logo"
          />
          {!isSmallest && customLogo && moduleType === ModuleType.Module ? (
            <Fragment>
              <Box clone ml={1}>
                <img
                  height={logoHeight}
                  src={isSmall ? pip_logo_small : pip_logo}
                  alt="Partners in Parenting Logo"
                />
              </Box>
            </Fragment>
          ) : null}
          <Box flexGrow={1} />
          {error ? (
            <Tooltip title={error.message}>
              <Alert />
            </Tooltip>
          ) : null}
          <DesktopLinks links={links} />
          <MobileLinks links={links} />
        </Toolbar>
      </Box>
    </AppBar>
  )
}
