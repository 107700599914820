import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Box, Fade, Grid, Paper } from '@material-ui/core'
import {
  Colors,
  customColorOptions,
  getRandomInt,
  useInterval,
  ISOFormatString,
} from '../../utils'
import { ModuleControlContext } from '../../pages'
import { TextCustom } from '.'
import { useContext } from 'react'
import { format } from 'date-fns'
import { PaperProps } from '@material-ui/core/Paper'

interface TextStepperItemProps {
  long?: boolean
  text: string | JSX.Element
  color: Colors
}

interface TextStepperProps extends PaperProps {
  id: string
  sequenceSpeed: number
  fadeSpeed: number
  textVariant?: any
  list: TextStepperItemProps[]
}

interface RandomItem {
  colors: number[]
}

/**
 * React component for rendering a grid list of text where each item will fade in after the previous one.
 */
export const TextStepper = ({
  id,
  list,
  sequenceSpeed,
  fadeSpeed,
  textVariant,
  ...PaperProps
}: TextStepperProps) => {
  const context = useContext(ModuleControlContext)
  const key = `TextStepper_${id}`
  const keyData = context.moduleData?.[key]
  const [count, setCount] = React.useState(keyData ? list.length : 1)

  const randomItemLists: RandomItem = useMemo(() => {
    const randomColorNumbers: number[] = []
    list.forEach(() => {
      randomColorNumbers.push(getRandomInt(0, customColorOptions.length))
    })

    return { colors: randomColorNumbers }
  }, [list])

  useInterval(() => {
    if (count <= list.length) {
      setCount(count + 1)
    }
    if (count === list.length && !keyData) {
      context.Update({
        [key]: format(new Date(), ISOFormatString),
        ...context.moduleData,
      })
    }
  }, sequenceSpeed)

  return (
    <Box clone my={1} p={3}>
      <Paper {...PaperProps}>
        <Grid container spacing={3}>
          {list.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={item.long ? undefined : 6}
              md={item.long ? undefined : 4}
            >
              <Fade in={index < count} timeout={fadeSpeed}>
                {typeof item.text === 'string' ? (
                  <TextCustom
                    variant={textVariant ?? 'h5'}
                    customColor={
                      item.color
                        ? item.color
                        : customColorOptions[randomItemLists.colors[index]]
                    }
                  >
                    {item.text}
                  </TextCustom>
                ) : (
                  item.text
                )}
              </Fade>
            </Grid>
          ))}
        </Grid>
      </Paper>
    </Box>
  )
}

TextStepper.propTypes = {
  /** How long before the next sentence starts fading in. In Milliseconds. */
  sequenceSpeed: PropTypes.number,
  /** How long the fade effect takes to transition from invisible to fully visible. In milliseconds. */
  fadeSpeed: PropTypes.number,
  variant: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      long: PropTypes.bool,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
        .isRequired,
      color: PropTypes.oneOf([
        'red',
        'green',
        'lightGreen',
        'yellow',
        'blue',
        'purple',
        'orange',
      ]),
    }),
  ),
}
