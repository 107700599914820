import * as Sentry from '@sentry/browser'
import ApolloClient from 'apollo-client'
import { AppNaviContext } from '../App'
import { GetWhoamiQuery, ModuleType, Maybe } from '../types'
import { gql } from 'graphql.macro'
import { loginUrl } from '../routes'
import { map, Matcher, redirect } from 'navi'
import { OptionsObject } from 'notistack'
import { participantUser } from './variables'
import { ReactNode } from 'react'

const GET_WHOAMI = gql`
  query getWhoami {
    whoami {
      id
      email
      userType {
        id
        name
      }
    }
    myParticipation {
      id
      moduleType
    }
  }
`

export const getUserInfo = (client: ApolloClient<object>) => {
  return client.query<GetWhoamiQuery>({
    query: GET_WHOAMI,
  })
}

export function userAuthentication(type: string, matcher: Matcher<any, any>) {
  return map(async (request, context: AppNaviContext) => {
    const userQuery = await getUserInfo(context.client).catch(() => {})
    let userType = undefined
    if (userQuery) {
      userType = userQuery.data.whoami?.userType?.name
    }

    return userType === type
      ? matcher
      : redirect(
          `${loginUrl}?redirectTo=${encodeURIComponent(
            request.mountpath + request.search,
          )}`,
          { exact: false },
        )
  })
}

export function participantAuthentication(
  type: string,
  matcher: Matcher<any, any>,
) {
  return map(async (request, context: AppNaviContext) => {
    const userQuery = await getUserInfo(context.client).catch(() => {})
    let userType: Maybe<string> = undefined
    let moduleType: Maybe<ModuleType> = undefined
    if (userQuery) {
      userType = userQuery.data.whoami?.userType?.name
      moduleType = userQuery.data.myParticipation?.moduleType
    }

    return userType === participantUser && moduleType === type
      ? matcher
      : redirect(
          `${loginUrl}?redirectTo=${encodeURIComponent(
            request.mountpath + request.search,
          )}`,
          { exact: false },
        )
  })
}

export function withAuthentication(type: string, matcher: Matcher<any, any>) {
  return map(async (request, context: AppNaviContext) => {
    const userQuery = await getUserInfo(context.client).catch(() => {})
    let userType: Maybe<string> = undefined
    if (userQuery) {
      userType = userQuery.data.whoami?.userType?.name
    }

    return userType === type
      ? matcher
      : redirect(
          `${loginUrl}?redirectTo=${encodeURIComponent(
            request.mountpath + request.search,
          )}`,
        )
  })
}

interface SnackbarResult {
  message: string | ReactNode
  options?: OptionsObject
}

export async function fetchLogin(url: string): Promise<SnackbarResult> {
  try {
    const result = await fetch(url, { credentials: 'include', mode: 'cors' })

    if (result.status === 200 && result.ok) {
      //Valid result
      return {
        message: 'Logged In',
        options: {
          variant: 'success',
        },
      }
    } else {
      //Api error
      try {
        const resText = await result.text()
        return { message: resText, options: { variant: 'error' } }
      } catch (e) {
        Sentry.captureException(e)
        return {
          message: `${result.status} -> An Error Occurred`,
          options: {
            variant: 'error',
          },
        }
      }
    }
  } catch (e) {
    //Network error
    return { message: e.stack || e.message, options: { variant: 'error' } }
  }
}
