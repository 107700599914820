import React, { Fragment, FunctionComponent, useContext, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import { format } from 'date-fns'
import { ISOFormatString } from '../../utils'
import { ModuleControlContext } from '../../pages'

interface ButtonDialogProps {
  /**
   * If id is set then this dialog will save its opened timestamp to moduleData
   * */
  id?: string
  /** The text displayed by the button. */
  linkText: string
  /** The Text displayed in the title of the dialog. */
  title?: string
  /**
   * If set to true then the dialog will always open in fullscreen mode. Note:
   * if fullScreen is undefined on mobile the dialog will always open in full
   * screen mode. */
  fullScreen?: boolean
  /**
   * If set to true then the dialog will always goto the next page on close or
   * on clicking the action button instead of just closing */
  goToNext?: boolean
  disableButton?: boolean
  onClick?: () => void
}

export const ButtonDialog: FunctionComponent<ButtonDialogProps> = ({
  linkText,
  title,
  children,
  fullScreen,
  goToNext,
  disableButton,
  onClick,
  id,
}) => {
  const [open, setOpen] = useState(false)
  const context = useContext(ModuleControlContext)
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const key = `ButtonDialog_${id}`
  const shouldFullscreen =
    fullScreen === undefined || fullScreen === null ? isSm : fullScreen

  function handleClickOpen() {
    if (onClick) {
      onClick()
    }
    if (id) {
      context.Update({
        [key]: format(new Date(), ISOFormatString),
        ...context.moduleData,
      })
    }
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
    if (goToNext) {
      context.GoTo(context.activeStep + 1)
    }
  }

  return (
    <Fragment>
      <Box mb={3}>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          color="primary"
          disabled={disableButton}
        >
          {linkText}
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose} fullScreen={shouldFullscreen}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {goToNext ? 'Next Page' : 'Close'}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
