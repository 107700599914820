import MaterialTable from 'material-table'
import React from 'react'
import { Alert } from '@material-ui/lab'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core'
import { feedbackSurveyID } from '../utils'
import { format } from 'date-fns'
import { gql } from 'graphql.macro'
import { InitialSurveyInfo } from '../components'
import { Reload } from 'mdi-material-ui'
import { useAdminDashboardQuery } from '../types'

export const USER_QUERY = gql`
  query AdminDashboard {
    users {
      id
      email
      verified
      userType {
        id
        name
      }
      person {
        firstName
      }
      participant {
        id
        members {
          person {
            firstName
            lastName
            gender {
              id
              name
            }
          }
          participantMemberType {
            id
            name
          }
        }
        moduleType
        data
      }
      goals {
        id
        completed
        module {
          id
          name
        }
        name
        description
      }
      modulesStatus {
        id
        completed
        selected
        unlocked
        locked_in
        module {
          id
          name
        }
      }
      surveysStats {
        id
        completions
        surveyID
      }
    }
  }
`

type ParticipantData = {
  registrationForm?: {
    [formKey: string]: string | number | (string | number)[]
  }
}

export const AdminDashboard = () => {
  const { data, refetch, error, loading } = useAdminDashboardQuery()

  const jsonKeys = new Set<string>()
  const result =
    data?.users?.map((user) => {
      const first = user.person?.firstName
      const userType = user.userType?.name
      const moduleType = user.participant?.moduleType
      const feedbackSurvey =
        user.surveysStats &&
        user.surveysStats.find((survey) => {
          if (survey) {
            return survey.surveyID === feedbackSurveyID
          }
          return false
        })
      const moduleLockInTime = user.modulesStatus?.find((module) =>
        module.locked_in ? true : false,
      )?.locked_in

      const participantDataJSON: ParticipantData = JSON.parse(
        user.participant?.data ?? '{}',
      )

      Object.keys(participantDataJSON.registrationForm ?? {}).forEach((key) =>
        jsonKeys.add(key),
      )

      return {
        id: user.id,
        email: user.email ? user.email : '',
        name: first,
        user: userType,
        module: moduleType,
        moduleStatus: user.modulesStatus,
        moduleLocked: moduleLockInTime,
        goals: user.goals,
        pradas: feedbackSurvey && feedbackSurvey.completions > 0,
        participantType: userType,
        verified: user.verified,
        ...participantDataJSON.registrationForm,
      }
    }) ?? []

  const participantResult = result.filter(function (result) {
    return result.module !== undefined
  })

  return (
    <Box mb={2}>
      <Typography variant="h5">Welcome to the admin dashboard</Typography>
      <Typography paragraph>
        Here you can view a list of registered users and their progress in the
        program.
      </Typography>
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      <Box my={2}>
        <MaterialTable
          isLoading={loading}
          columns={
            process.env.REACT_APP_TNO_MODE === 'true'
              ? [
                  { title: 'tno', field: 'tno' },
                  { title: 'User Type', field: 'user' },
                  { title: 'Module Type', field: 'module' },
                  {
                    title: 'PRADAS Complete',
                    field: 'pradas',
                    type: 'boolean',
                  },
                ]
              : [
                  { title: 'ID', field: 'id' },
                  { title: 'Email', field: 'email' },
                  { title: 'Name', field: 'name' },
                  { title: 'User Type', field: 'user' },
                  { title: 'Module Type', field: 'module' },
                  {
                    title: 'PRADAS Complete',
                    field: 'pradas',
                    type: 'boolean',
                  },
                ]
          }
          title="User program-related data"
          data={result.map((row) => ({ ...row }))}
          options={{
            exportAllData: true,
            pageSizeOptions: [5, 10, 20, 50, 100],
            exportButton:
              process.env.REACT_APP_CSV_EXPORT === 'true' ? true : false,
          }}
          actions={[
            {
              icon: () => <Reload />,
              tooltip: 'Refresh',
              isFreeAction: true,
              onClick: () => refetch(),
            },
          ]}
          detailPanel={[
            {
              tooltip: 'PRADAS Progress',
              render: (rowData) => <InitialSurveyInfo userID={rowData.id} />,
            },
            {
              tooltip: 'Modules',
              render: (rowData) => {
                if (rowData.moduleStatus && rowData.moduleStatus.length > 0) {
                  return rowData.moduleStatus.map((module) => {
                    const moduleName = module.module?.name
                    return (
                      <List key={module.id}>
                        <ListItem disabled={!module.selected}>
                          <ListItemText
                            primary={moduleName}
                            secondary={
                              module.completed
                                ? `Completed on: ${format(
                                    new Date(module.completed),
                                    'yyyy/MM/dd HH:mm',
                                  )}`
                                : 'Not Completed'
                            }
                          />
                        </ListItem>
                      </List>
                    )
                  })
                }
                return (
                  <List>
                    <ListItem>
                      <ListItemText primary="No Modules for this user" />
                    </ListItem>
                  </List>
                )
              },
            },
            {
              tooltip: 'Goals',
              render: (rowData) => {
                if (rowData.goals && rowData.goals.length > 0) {
                  return rowData.goals.map((goal) => {
                    return (
                      <List key={goal.id}>
                        <ListItem>
                          <ListItemText
                            primary={`${goal.module.name} => ${goal.description}`}
                            secondary={
                              goal.completed
                                ? `Completed on: ${format(
                                    new Date(goal.completed),
                                    'yyyy/MM/dd HH:mm',
                                  )}`
                                : 'Not Completed'
                            }
                          />
                        </ListItem>
                      </List>
                    )
                  })
                }
                return (
                  <List key={rowData.id}>
                    <ListItem>
                      <ListItemText primary="No Goals for this user" />
                    </ListItem>
                  </List>
                )
              },
            },
          ]}
        />
      </Box>

      {process.env.REACT_APP_TNO_MODE !== 'true' ? (
        <Box my={2}>
          <MaterialTable
            title="Participants registration information"
            isLoading={loading}
            columns={[
              { title: 'ID', field: 'id' },
              { title: 'Email', field: 'email' },
              { title: 'Email Verified', field: 'verified', type: 'boolean' },
              {
                title: 'Modules Locked In',
                field: 'moduleLocked',
                type: 'datetime',
              },
              ...Array.from(jsonKeys).map((key) => ({
                title: key,
                field: key,
              })),
            ]}
            data={participantResult}
            options={{
              exportAllData: true,
              pageSizeOptions: [5, 10, 20, 50, 100],
              exportButton:
                process.env.REACT_APP_CSV_EXPORT === 'true' ? true : false,
            }}
            actions={[
              {
                icon: () => <Reload />,
                tooltip: 'Refresh',
                isFreeAction: true,
                onClick: () => refetch(),
              },
            ]}
          />
        </Box>
      ) : null}
    </Box>
  )
}
