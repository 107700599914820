import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link } from '@material-ui/core'

export const BasicAudio = ({ src, type, preload }) => (
  <Box clone display="block" my={2}>
    <audio controls preload={preload} controlsList="nodownload">
      <source src={src} type={type} />
      <Typography>
        Your browser doesn't support HTML5 audio. Here is a{' '}
        <Link href={src}>link to the audio</Link> instead.
      </Typography>
    </audio>
  </Box>
)

BasicAudio.propTypes = {
  /** Url of the audio file you want to play*/
  src: PropTypes.string,
  /** Audio codec the audio file uses. This is important to set so the browser can tell if it knows how to play the given file. Some common formats are listed as options for this prop. */
  type: PropTypes.oneOf([
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'audio/aac',
    'audio/opus',
    'audio/vorbis',
  ]),
  preload: PropTypes.oneOf(['none', 'metadata', 'auto']),
}
