import React from 'react'
import { makeStyles, createStyles, Button } from '@material-ui/core'
import { useToggle } from '../../utils'

const useStyles = makeStyles(
  createStyles({
    containedPrimary: {
      background: `linear-gradient(45deg, #ff8a23 30%, #ff7801 90%)`,
    },
    label: {
      textTransform: 'none',
    },
  }),
)

export const SelectBox = (props) => {
  const classes = useStyles()
  const [clicked, toggle] = useToggle(false)
  const { children, color, variant, ...other } = props

  return (
    <Button
      {...other}
      color={clicked ? 'primary' : 'default'}
      classes={{
        containedPrimary: classes.containedPrimary,
        label: classes.label,
      }}
      variant="contained"
      onClick={toggle}
    >
      {children}
    </Button>
  )
}
