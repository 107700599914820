import React, { FunctionComponent, useContext } from 'react'
import { Box, Grid } from '@material-ui/core'
import { Fragment } from 'react'
import { Img, TextCustom } from '.'
import { ModuleControlContext } from '../../pages'

interface GoalDialogProps {
  id: string
}

export const SelectedGoal: FunctionComponent<GoalDialogProps> = (props) => {
  const context = useContext(ModuleControlContext)
  const key = `Goal_${context.moduleID}`
  const keyData = context.moduleData?.[key]
  const thisGoalSelected = keyData === props.id

  if (thisGoalSelected) {
    return (
      <Fragment>
        <TextCustom variant="h1" paragraph>
          The goal you have selected for this week is…
        </TextCustom>
        <TextCustom variant="h6" customColor="blue" paragraph>
          {props.children}
        </TextCustom>
        <Grid container>
          <Grid item xs={10} sm={5}>
            <h3>Think about:</h3>
            <Box fontSize="h6.fontSize">
              <ul>
                <li>When will this happen?</li>
                <li>Who will be involved?</li>
                <li>What might get in the way of this happening?</li>
              </ul>
            </Box>
          </Grid>
          <Grid item xs={8} sm={3}>
            <Img
              src="https://storage.googleapis.com/pip-artifacts/svg/all/50_new_goal_sticky.svg"
              alt="set goal"
            />
          </Grid>
        </Grid>
      </Fragment>
    )
  }

  return null
}
