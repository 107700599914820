import React from 'react'
import { Toolbar, Typography, Box } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { version } from '../../package.json'

/**
 * FooterBar can be used to display anything that needs to be in the footer of
 * the app. It is designed to be a sticky footer but in order to do so the root
 * element of the app should contain:
 * @example
 * display: flex;
 * min-height: 100vh;
 * flex-direction: column;
 */
export function FooterBar() {
  return (
    <Box mt="auto" component="footer">
      <Box clone display="flex" bgcolor={grey[100]}>
        <Toolbar>
          <Box flexGrow="1">
            <Typography variant="subtitle2" align="center">
              Version {version} | Made by Episub
            </Typography>
          </Box>
        </Toolbar>
      </Box>
    </Box>
  )
}
