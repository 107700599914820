import React, { useContext, ChangeEvent } from 'react'
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core'
import { ConfidenceValues } from './ConfidenceQuestion'
import { ModuleControlContext, ModuleKeyObject } from '../../pages'

type PreviousModuleQuestionProps = {
  id: string
}

type confidenceQuestion = {
  value: string
  moduleName: string
  label: string
}

export const confidenceQuestions: confidenceQuestion[] = [
  {
    value: 'first',
    moduleName: 'Not applicable - this is my first module',
    label: '',
  },
  {
    value: 'TeenagerRelationship',
    moduleName: 'Connect',
    label:
      'How confident do you feel about your ability to build and maintain a close relationship with your teenager?',
  },
  {
    value: 'Balance',
    moduleName: 'Nurture roots & inspire wings',
    label:
      'How confident do you feel about your ability to find a balance between being involved in your teenager’s life and encouraging age-appropriate independence?',
  },
  {
    value: 'FamilyRules',
    moduleName: 'Raising good kids into great adults',
    label:
      'How confident do you feel about your ability to establish family rules and consequences?',
  },
  {
    value: 'SolveConflict',
    moduleName: 'Calm versus conflict',
    label:
      'How confident do you feel about your ability to solve conflicts with your teenager in a constructive manner?',
  },
  {
    value: 'TeenagerSkill',
    moduleName: 'Good friends, supportive relationships',
    label:
      'How confident do you feel about your ability to help your teenager to build their social skills?',
  },
  {
    value: 'HealthyLifestyle',
    moduleName: 'Good health habits for good mental health',
    label:
      'How confident do you feel about your ability to influence your teenager to make healthy lifestyle choices?',
  },
  {
    value: 'TeenagerProblems',
    moduleName: 'Partners in problem solving',
    label:
      'How confident do you feel about your ability to support your teenager when they face problems in their life?',
  },
  {
    value: 'TeenagerAnxiety',
    moduleName:
      'From surviving to thriving: Helping your teenager deal with anxiety',
    label:
      'How confident do you feel about your ability to help your teenager cope with anxiety?',
  },
  {
    value: 'ProfessionalHelp',
    moduleName: 'When things aren’t okay: Getting professional help',
    label:
      'If you noticed a persistent change in your teenager’s mood or behaviour, how confident do you feel about your ability to help your teenager seek appropriate professional help?',
  },
]

export const PreviousModuleQuestion = ({ id }: PreviousModuleQuestionProps) => {
  const context = useContext(ModuleControlContext)
  const key = `PreviousModule_${id}`
  const keyData = (context.moduleData?.[key] as ModuleKeyObject) ?? {}

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (typeof keyData === 'object' && typeof event.target.value === 'string') {
      context.Update({
        ...context.moduleData,
        [key]: {
          ...keyData,
          answer: event.target.value as string,
        },
      })
    }
  }

  const handleModuleChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (typeof keyData === 'object' && typeof event.target.value === 'string') {
      context.Update({
        ...context.moduleData,
        [key]: {
          ...keyData,
          moduleChosen: event.target.value,
        },
      })
    }
  }

  return (
    <Box my={3}>
      <Card>
        <CardContent>
          <Typography variant="h6" paragraph>
            We'd like to check in about your confidence levels. First, please
            select the title of your last module from the list below:
          </Typography>
          <Box mb={2}>
            <Select
              onChange={handleModuleChange}
              value={keyData.moduleChosen ?? ''}
              variant="filled"
              fullWidth={true}
            >
              {confidenceQuestions.map((previousModule) => (
                <MenuItem
                  key={previousModule.value}
                  value={previousModule.value}
                >
                  {previousModule.moduleName}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Typography paragraph>
            {
              confidenceQuestions.find(
                (PopConfidenceValue) =>
                  PopConfidenceValue.value === keyData.moduleChosen,
              )?.label
            }
          </Typography>
          {keyData.moduleChosen && keyData.moduleChosen !== 'first' ? (
            <RadioGroup
              row
              aria-label="confidence"
              name="radio"
              value={keyData.answer ?? ''}
              onChange={handleChange}
            >
              <FormControlLabel
                control={<Radio />}
                value={ConfidenceValues.notConfident}
                label="Not at all confident"
              />
              <FormControlLabel
                value={ConfidenceValues.littleConfident}
                control={<Radio />}
                label="A little confident"
              />
              <FormControlLabel
                value={ConfidenceValues.somewhatConfident}
                control={<Radio />}
                label="Somewhat confident"
              />
              <FormControlLabel
                value={ConfidenceValues.veryConfident}
                control={<Radio />}
                label="Very confident"
              />
            </RadioGroup>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  )
}
