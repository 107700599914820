import React, { FunctionComponent, Suspense } from 'react'
import { Box, CssBaseline } from '@material-ui/core'
import { BusyCircular } from './LoadingIndicators'
import { BusyLinear, FooterBar, HeaderBar } from '.'
import { NotFoundBoundary, useLoadingRoute, View } from 'react-navi'
import { RenderNotFound } from '../pages'

export const PageLayout: FunctionComponent = (props) => {
  const loadingRoute = useLoadingRoute()

  return (
    <Box display="flex" flexDirection="column" minHeight="100vh">
      <CssBaseline />
      <BusyLinear color="secondary" isBusy={!!loadingRoute} delayMs={200} />
      <HeaderBar />
      <Box clone pt={3}>
        <main>
          <Suspense fallback={<BusyCircular isBusy={true} delayMs={200} />}>
            <NotFoundBoundary render={RenderNotFound}>
              <View />
            </NotFoundBoundary>
          </Suspense>
        </main>
      </Box>
      <FooterBar />
    </Box>
  )
}
