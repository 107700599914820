import React, { FunctionComponent } from 'react'
import { TextCustom } from './TextCustom'

export interface QuoteProps {
  quote: string
  author: string
}

export const Quote: FunctionComponent<QuoteProps> = ({ quote, author }) => (
  <blockquote>
    <TextCustom variant="h6" paragraph align="right">
      {quote}
    </TextCustom>
    <TextCustom variant="h6" align="right">
      {author}
    </TextCustom>
  </blockquote>
)
