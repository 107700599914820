import * as Yup from 'yup'
import React, { Fragment, FunctionComponent } from 'react'
import { AdapterLink, BusyCircular, FeedbackNode } from '../components'
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  CardActions,
  TextField,
  Typography,
  CircularProgress,
} from '@material-ui/core'
import { dashboardUrl, memberUrl } from '../routes'
import { gql } from 'graphql.macro'
import { useGetSettingsQuery, useGetFeedbackLazyQuery } from '../types'
import { Alert } from '@material-ui/lab'
import { useForm, OnSubmit, Controller } from 'react-hook-form'

export const GET_FEEDBACK = gql`
  query getFeedback($password: String) {
    feedbackResult(password: $password) {
      data
    }
  }
`

export const GET_SETTINGS = gql`
  query getSettings {
    getSettings {
      key
      value
    }
  }
`

const validationSchema = Yup.object().shape({
  password: Yup.string().required('Required'),
})

type LoginForm = Yup.InferType<typeof validationSchema>

export const Feedback: FunctionComponent = () => {
  const [
    getFeedback,
    { data, loading: feedbackLoading, error: feedbackError },
  ] = useGetFeedbackLazyQuery({ fetchPolicy: 'no-cache' })
  const {
    data: settingsData,
    loading: settingsLoading,
    error: loadingError,
  } = useGetSettingsQuery({
    onCompleted: (data) => {
      const feedbackRequirePassword = data?.getSettings?.find(
        (keyValue) => keyValue?.key === 'FeedbackRequirePassword',
      )
      if (feedbackRequirePassword?.value === 'false') {
        getFeedback()
      }
    },
  })
  const { errors, control, handleSubmit } = useForm<LoginForm>({
    defaultValues: { password: '' },
    validationSchema,
  })

  const feedbackRequirePassword = settingsData?.getSettings?.find(
    (keyValue) => keyValue?.key === 'FeedbackRequirePassword',
  )
  const feedbackResult = data?.feedbackResult?.data
  const memberDashboardHref = `${memberUrl}${dashboardUrl}`

  const onSubmit: OnSubmit<LoginForm> = (data) => {
    getFeedback({ variables: { ...data } })
  }

  const queryErrors = (
    <Fragment>
      {feedbackError ? (
        <Alert severity="error">{feedbackError.message}</Alert>
      ) : null}
      {loadingError ? (
        <Alert severity="error">{loadingError.message}</Alert>
      ) : null}
    </Fragment>
  )

  if (settingsLoading) {
    return <BusyCircular isBusy={settingsLoading} delayMs={200} />
  }

  if (
    feedbackRequirePassword?.value === 'true' &&
    feedbackResult === undefined
  ) {
    return (
      <Container maxWidth="sm">
        <Card>
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
              {queryErrors}
              <Typography paragraph>
                Enter your password to view your feedback.
              </Typography>
              <Box display="none">
                <input name="username" autoComplete="username" />
              </Box>
              <Controller
                as={TextField}
                autoComplete="current-password"
                control={control}
                disabled={feedbackLoading}
                error={errors.password ? true : false}
                fullWidth
                helperText={errors.password?.message}
                label="Password"
                name="password"
                type="password"
                variant="outlined"
              />
            </CardContent>
            <CardActions>
              <Button component={AdapterLink} href={memberDashboardHref}>
                Close
              </Button>
              <Button type="submit" disabled={feedbackLoading}>
                {feedbackLoading ? <CircularProgress size={24} /> : 'Submit'}
              </Button>
            </CardActions>
          </form>
        </Card>
      </Container>
    )
  }

  return (
    <Fragment>
      {queryErrors}
      {feedbackLoading ? (
        <BusyCircular isBusy={feedbackLoading} delayMs={200} />
      ) : null}
      <FeedbackNode {...JSON.parse(feedbackResult ?? '{}')} />
      <Box my={2}>
        <Button
          component={AdapterLink}
          href={memberDashboardHref}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
        >
          View Modules
        </Button>
      </Box>
    </Fragment>
  )
}
