import React, { FunctionComponent } from 'react'
import { Box, Typography } from '@material-ui/core'
import { Colors, getColorHex } from '../../utils'
import { TypographyProps } from '@material-ui/core/Typography'

export interface TextCustomProps extends TypographyProps<'div'> {
  customColor?: Colors
}

export const TextCustom: FunctionComponent<TextCustomProps> = ({
  customColor,
  children,
  ...other
}) => {
  return (
    <Typography {...other} component="div">
      <Box color={customColor ? getColorHex(customColor) : undefined}>
        {children}
      </Box>
    </Typography>
  )
}
