import { gql } from 'graphql.macro'
import { FunctionComponent, useEffect } from 'react'

export const SUBMIT_REGISTRATION = gql`
  mutation submitRegistration($user: RegisterUser!) {
    registerUser(user: $user)
  }
`

interface RegisterProps {
  code: string
  email: string
  tno: string
  ex: string
}

export const Register: FunctionComponent<RegisterProps> = () => {
  //  redirect to new website
  useEffect(() => {
    window.location.replace('https://partnersinparenting.com.au/auth/signup')
  })

  return null
}
