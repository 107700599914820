import React from 'react'
import { useCurrentRoute } from 'react-navi'

const SurveyWrapper = React.lazy(() => import('../components/SurveyWrapper'))

export const Survey = () => {
  const route = useCurrentRoute()

  return <SurveyWrapper surveyName={route.data.id} />
}
