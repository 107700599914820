import React, { FunctionComponent, MouseEvent } from 'react'
import { adminUrl, pageUrl } from '../routes'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Card,
  Container,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core'
import { BusyCircular, NoPermission } from '../components'
import { gql } from 'graphql.macro'
import { PAGE_DATA_FRAGMENT, superUserID } from '../utils'
import { useGetAllPagesQuery } from '../types'
import { useNavigation } from 'react-navi'

export const GET_ALL_PAGES = gql`
  query GetAllPages {
    whoami {
      id
    }
    pages {
      ...pageData
    }
  }
  ${PAGE_DATA_FRAGMENT}
`

export const EditPages: FunctionComponent = () => {
  const navigation = useNavigation()
  const { data, loading, error } = useGetAllPagesQuery()

  function goToPage(event: MouseEvent<HTMLDivElement>) {
    navigation.navigate(
      `${adminUrl}${pageUrl}/${encodeURIComponent(event.currentTarget.id)}`,
    )
  }

  if (data?.whoami?.id !== superUserID && !loading) {
    return <NoPermission />
  }

  return (
    <Container maxWidth="sm">
      <Box mb={3}>
        <BusyCircular isBusy={loading} delayMs={200} />
        {error ? <Alert title={error.message} /> : null}
        <Card>
          <List>
            {data?.pages?.map((page) => (
              <ListItem key={page?.id} id={page?.id} button onClick={goToPage}>
                <ListItemText
                  primary={page?.menuName}
                  secondary={page?.route}
                />
              </ListItem>
            ))}
          </List>
        </Card>
      </Box>
    </Container>
  )
}
