import React, { FunctionComponent, useContext } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { Paper } from '.'
import { ClipboardCheck } from 'mdi-material-ui'
import { green } from '@material-ui/core/colors'
import { ModuleControlContext } from '../../pages'

interface GoalDialogProps {
  id: string
  description: string
}

export const GoalDialog: FunctionComponent<GoalDialogProps> = (props) => {
  const context = useContext(ModuleControlContext)
  const key = `Goal_${context.moduleID}`
  const keyData = context.moduleData?.[key]
  const thisGoalSelected = keyData === props.id

  function handleSelect() {
    context.SetGoal(props.id, props.description)
    context.Update({
      ...context.moduleData,
      [key]: props.id,
    })
  }

  return (
    <Paper>
      <Typography paragraph>{props.children}</Typography>
      <Box display="flex">
        <Button
          variant="contained"
          color="primary"
          onClick={handleSelect}
          disabled={context.moduleCompleted}
        >
          Select
        </Button>
        {thisGoalSelected ? (
          <Box display="flex">
            <ClipboardCheck htmlColor={green['A400']} fontSize="large" />
            <Typography>You've selected this goal.</Typography>
          </Box>
        ) : null}
      </Box>
    </Paper>
  )
}
