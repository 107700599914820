import Carousel from 'react-multi-carousel'
import React, { Fragment, FunctionComponent, MouseEvent, useMemo } from 'react'
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
} from '@material-ui/core'
import { Check } from 'mdi-material-ui'
import {
  GoalStatus,
  useGetMyGoalsQuery,
  useSetGoalStatusMutation,
} from '../types'
import { gql } from 'graphql.macro'
import { green } from '@material-ui/core/colors'
import { normalise } from '../utils'
import { Skeleton } from '@material-ui/lab'

export const getMyGoalsQueryName = 'GetMyGoals'

export const GET_MY_GOALS = gql`
  query GetMyGoals {
    modulesStatus {
      id
      position
      module {
        id
      }
    }
    goals {
      id
      completed
      description
      module {
        id
        name
      }
    }
  }
`

export const SET_GOAL_STATUS = gql`
  mutation setGoalStatus($goalID: ID!, $status: GoalStatus!) {
    setGoalStatus(goalID: $goalID, status: $status)
  }
`

export const GoalsList: FunctionComponent = () => {
  const theme = useTheme()
  const { loading, data } = useGetMyGoalsQuery()
  const [setGoal] = useSetGoalStatusMutation({
    refetchQueries: [getMyGoalsQueryName],
  })

  const goals = data?.goals
  const modules = data?.modulesStatus
  const completeGoals = countCompletedGoals()
  const filteredGoals = useMemo(() => {
    if (goals && modules) {
      return goals.sort((a, b) => {
        const aModule = modules.find(
          (module) => module.module?.id === a.module.id,
        )
        const bModule = modules.find(
          (module) => module.module?.id === b.module.id,
        )
        if (aModule?.position && bModule?.position) {
          if (aModule.position < bModule.position) {
            return -1
          }
          if (aModule.position > bModule.position) {
            return 1
          }
        }
        return 0
      })
    }
    return undefined
  }, [goals, modules])

  function countCompletedGoals() {
    let count = 0
    if (goals) {
      goals.forEach((goal) => {
        if (goal.completed) {
          count = count + 1
        }
      })
    }
    return count
  }

  function setGoalStatus(event: MouseEvent<HTMLButtonElement>) {
    setGoal({
      variables: {
        goalID: event.currentTarget.value,
        status: GoalStatus.Complete,
      },
    })
  }

  if (loading) {
    return (
      <Box mb={2}>
        <Typography variant="h5" paragraph>
          Your Goals
        </Typography>
        <Grid container>
          <Grid item xs={6}>
            <Card>
              <CardHeader title={<Skeleton width="20%" height={10} />} />
              <CardContent>
                <Skeleton height={8} />
                <Skeleton height={8} />
                <Skeleton height={8} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    )
  }

  if (!filteredGoals || !goals) {
    return null
  }

  return (
    <Box mb={2}>
      <Typography variant="h5" paragraph>
        Your Goals
      </Typography>
      {filteredGoals.length > 0 ? (
        <Fragment>
          <Box mb={2}>
            <LinearProgress
              variant="determinate"
              value={normalise(completeGoals, 0, goals.length)}
            />
            <Typography variant="overline">{`${completeGoals}/${goals.length} goals complete`}</Typography>
          </Box>
          <Carousel
            showDots={true}
            swipeable={false}
            draggable={false}
            responsive={{
              sm: {
                breakpoint: { max: theme.breakpoints.width('sm'), min: 1 },
                items: 1,
              },
              md: {
                breakpoint: {
                  max: theme.breakpoints.width('md'),
                  min: theme.breakpoints.width('sm') + 1,
                },
                items: 2,
                slidesToSlide: 2,
              },
              lg: {
                breakpoint: {
                  max: theme.breakpoints.width('lg'),
                  min: theme.breakpoints.width('md') + 1,
                },
                items: 3,
                slidesToSlide: 3,
              },
              xl: {
                breakpoint: {
                  max: 12000,
                  min: theme.breakpoints.width('lg') + 1,
                },
                items: 4,
                slidesToSlide: 4,
              },
            }}
          >
            {filteredGoals.map((goal) => (
              <Box
                key={goal.id}
                bgcolor={goal.completed ? green[50] : undefined}
                clone
                m={1}
              >
                <Card>
                  <Box minHeight={250}>
                    <CardHeader title={goal.module.name} />
                    <Box flexGrow={1}>
                      <CardContent>
                        <Typography>{goal.description}</Typography>
                      </CardContent>
                    </Box>
                  </Box>
                  <CardActions>
                    {goal.completed ? (
                      <Box ml="auto" mr="auto">
                        <Check />
                      </Box>
                    ) : (
                      <Button value={goal.id} onClick={setGoalStatus}>
                        Mark As Complete
                      </Button>
                    )}
                  </CardActions>
                </Card>
              </Box>
            ))}
          </Carousel>
        </Fragment>
      ) : (
        <Typography paragraph>You haven't selected any goals yet</Typography>
      )}
    </Box>
  )
}
