import React, { Fragment, useEffect } from 'react'
import { AdapterLink } from '../components'
import { Alert } from '@material-ui/lab'
import {
  Avatar,
  Button,
  CircularProgress,
  Container,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core'
import { gql } from 'graphql.macro'
import { LockReset } from 'mdi-material-ui'
import { loginUrl } from '../routes'
import { useVerifyAccountMutation } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
}))

export const VERIFY_ACCOUNT_TOKEN = gql`
  mutation VerifyAccount($token: String!) {
    verifyAccount(token: $token)
  }
`

interface VerifyAccountProps {
  token?: string
}

export const VerifyAccount = ({ token }: VerifyAccountProps) => {
  const classes = useStyles()
  const [verifyAccount, { loading, data, error }] = useVerifyAccountMutation()

  useEffect(() => {
    if (token) {
      verifyAccount({ variables: { token } })
    }
  }, [verifyAccount, token])

  return (
    <Container maxWidth="sm">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockReset />
        </Avatar>
        {!token ? (
          <Typography variant="h4">
            Accounts must be verified with a special url
          </Typography>
        ) : loading ? (
          <Fragment>
            <Typography variant="h4">Verifying Account...</Typography>
            <CircularProgress size={24} />
          </Fragment>
        ) : data?.verifyAccount ? (
          <Fragment>
            <Typography variant="h4" paragraph>
              Account Verified
            </Typography>
            <Button component={AdapterLink} href={loginUrl}>
              Go to login
            </Button>
          </Fragment>
        ) : error?.message.includes('has already been verified') ? (
          <Fragment>
            <Typography variant="h4" paragraph>
              This email has already been verified
            </Typography>
            <Button component={AdapterLink} href={loginUrl}>
              Go to login
            </Button>
          </Fragment>
        ) : (
          <Alert severity="error">{error?.message}</Alert>
        )}
      </div>
    </Container>
  )
}
