import React, { FunctionComponent, useRef } from 'react'
import ReactPlayer, { ReactPlayerProps } from 'react-player'
import useComponentSize from '@rehooks/component-size'

const defaultWidth = 960
const defaultHeight = 540

/**
 * Simple Wrapper around ReactPlayer to provide some automatic resizing at small
 * screen sizes. The video will center itself inside its parent and if the
 * parent is smaller than 960 it will also resize to that smaller size while
 * maintaining the aspect ratio of 16:9
 */
export const BasicVideo: FunctionComponent<ReactPlayerProps> = ({
  width,
  height,
  ...other
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const size = useComponentSize(ref)

  return (
    <div ref={ref}>
      <ReactPlayer
        controls
        light
        width={size.width < defaultWidth ? size.width : defaultWidth}
        height={
          size.width < defaultWidth ? size.width / (16 / 9) : defaultHeight
        }
        style={{
          marginTop: 20,
          marginBottom: 20,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        {...other}
      />
    </div>
  )
}
