import React, { Fragment, FunctionComponent } from 'react'
import {
  AdapterLink,
  BusyCircular,
  ModulesList,
  ModulesSelect,
  ScheduledSurveyList,
} from '../components'
import { Alert } from '@material-ui/lab'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import {
  feedbackSurveyID,
  SCHEDULED_SURVEYS_FRAGMENT,
  useToggle,
} from '../utils'
import {
  feedbackUrl,
  memberUrl,
  surveyInstructionsUrl,
  surveyUrl,
} from '../routes'
import { GoalsList } from '../components'
import { gql } from 'graphql.macro'
import { useGetMyParticipationQuery } from '../types'

export const memberDashboardQueryName = 'getMyParticipation'

export const GET_MY_PARTICIPATION = gql`
  query getMyParticipation($surveyID: ID!) {
    whoami {
      id
      person {
        id
        firstName
      }
    }
    surveyStats(surveyID: $surveyID) {
      id
      completions
    }
    modulesStatus {
      id
    }
    scheduledSurveys {
      ...scheduledSurveys
    }
  }
  ${SCHEDULED_SURVEYS_FRAGMENT}
`

export const MemberDashboard: FunctionComponent = () => {
  const [notification, toggleNotification] = useToggle(false)
  const { data, loading, error } = useGetMyParticipationQuery({
    variables: { surveyID: feedbackSurveyID },
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const notInitialSurvey = data.surveyStats.completions > 0
      const scheduledSurveys = (data.scheduledSurveys?.length ?? 0) > 0
      if (notInitialSurvey && scheduledSurveys) {
        toggleNotification()
      }
    },
  })

  const surveyCount = data?.surveyStats.completions
  const scheduledSurveys = data?.scheduledSurveys

  // State trackers
  const activeModulesLength = data?.modulesStatus?.length ?? 0
  const selectModulesRequired =
    surveyCount && surveyCount > 0 && activeModulesLength === 0
  const modulesActive =
    surveyCount && surveyCount > 0 && activeModulesLength > 0

  const pageTitle = (
    <Typography color="primary" variant="h4" paragraph>
      Welcome to your personal dashboard, {data?.whoami?.person?.firstName}
    </Typography>
  )

  if (loading) {
    return <BusyCircular isBusy={loading} delayMs={200} />
  }

  if (
    process.env.REACT_APP_LEGACY_SURVEY_INSTRUCTIONS === 'true' &&
    surveyCount === 0
  ) {
    return (
      <Box py="2">
        {pageTitle}
        <Typography paragraph>
          To get started you need to complete a survey.
        </Typography>
        <Button
          color="primary"
          component={AdapterLink}
          fullWidth
          href={`${memberUrl}${surveyInstructionsUrl}`}
          size="large"
          variant="contained"
        >
          Click here to start the survey
        </Button>
      </Box>
    )
  }

  return (
    <Box mb={2}>
      {pageTitle}
      {error ? <Alert severity="error">{error.message}</Alert> : null}
      <ScheduledSurveyList baseUrl={memberUrl} surveys={scheduledSurveys} />
      <Dialog onClose={toggleNotification} open={notification}>
        <DialogTitle>New Survey Available</DialogTitle>
        <DialogContent>
          We'd love to hear about your experience of the PiP program in our
          follow-up survey. This will help us to evaluate and improve the
          program in future. If you're happy to complete the survey, please
          click the 'start survey' button.
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleNotification}>Close</Button>
          {scheduledSurveys?.[0] ? (
            <Button
              component={AdapterLink}
              href={`${memberUrl}${surveyUrl}/${scheduledSurveys[0].survey.id}`}
            >
              Start Survey
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      {selectModulesRequired ? <ModulesSelect /> : null}
      {modulesActive ? (
        <Fragment>
          <Typography variant="h5" paragraph>
            Welcome back to your Partners in Parenting program.
          </Typography>
          <Typography paragraph>
            This is where you will find all of the modules in your personalised
            parenting program. When you complete your program for the first
            time, you will have access to one module every 7 days, until you
            complete all the modules in your program. We encourage you to work
            through the modules in the order they are presented. After
            completing all modules in your program, you can access any of the 9
            modules in any order, as many times as you like.
          </Typography>
          <Typography paragraph>
            Below you will also see a list of goals you have selected from each
            of your completed modules (one per module). You can mark your goals
            as complete at any time – just click the button under each goal.
          </Typography>
          <Typography paragraph>
            You can also re-visit your personalised parenting feedback at any
            time. Just click the button below to view your feedback.
          </Typography>
          <Box pb={3}>
            <Button
              component={AdapterLink}
              href={`${memberUrl}${feedbackUrl}`}
              variant="contained"
              color="primary"
              size="large"
            >
              View Your Feedback
            </Button>
          </Box>
          <Box pb={2}>
            <GoalsList />
          </Box>
          <Box pb={2}>
            <ModulesList />
          </Box>
        </Fragment>
      ) : null}
    </Box>
  )
}
