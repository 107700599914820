import React, { FunctionComponent, useState } from 'react'
import { Card, CardContent } from '@material-ui/core'
import { ModuleControlContext, ModuleState } from '../pages'
import { MemoMDX } from '.'
import { Alert, AlertTitle } from '@material-ui/lab'

export const DummyModuleState: FunctionComponent<{ page: string }> = ({
  page,
}) => {
  const [moduleState, setModuleState] = useState<ModuleState>({})

  function handleModuleState(state: ModuleState) {
    setModuleState(state)
  }

  return (
    <Card>
      <CardContent>
        <ModuleControlContext.Provider
          value={{
            GoTo: () => {},
            SetGoal: () => {},
            Update: handleModuleState,
            activeStep: 0,
            color: '#fff',
            moduleCompleted: false,
            moduleData: moduleState,
            moduleID: undefined,
          }}
        >
          <MemoMDX mdx={page} />
        </ModuleControlContext.Provider>
        <Alert severity="info">
          <AlertTitle>Page State</AlertTitle>
          <code>{JSON.stringify(moduleState, null, 2)}</code>
        </Alert>
      </CardContent>
    </Card>
  )
}
