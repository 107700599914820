import * as Yup from 'yup'
import React, { FunctionComponent, lazy, useMemo } from 'react'
import { Alert } from '@material-ui/lab'
import { Box, Button, Container, Grid, TextField } from '@material-ui/core'
import { BusyCircular, NoPermission } from '../components'
import { Controller, OnSubmit, useForm } from 'react-hook-form'
import { gql } from 'graphql.macro'
import { ModuleJSON } from './Module'
import { MODULES_UPDATE_FRAGMENT, superUserID } from '../utils'
import {
  UpdateModuleInput,
  useGetModuleQuery,
  useUpdateModuleMutation,
} from '../types'
import { useSnackbar } from 'notistack'

const ContentPagesField = lazy(() => import('../components/ContentPagesField'))

const moduleValidation = Yup.object().shape({
  name: Yup.string(),
  description: Yup.string(),
  imageUrl: Yup.string(),
  moduleName: Yup.string(),
  pages: Yup.array().of(
    Yup.object().shape({
      page: Yup.string().required(),
      unlockThreshold: Yup.number(),
    }),
  ),
})

export const GET_MODULE = gql`
  query GetModule($id: ID!) {
    whoami {
      id
    }
    module(id: $id) {
      ...moduleUpdateFields
    }
  }
  ${MODULES_UPDATE_FRAGMENT}
`

export const UPDATE_MODULE = gql`
  mutation UpdateModule($id: ID!, $changes: UpdateModuleInput!) {
    updateModule(id: $id, changes: $changes) {
      ...moduleUpdateFields
    }
  }
  ${MODULES_UPDATE_FRAGMENT}
`

export interface UpdateModuleFormFields
  extends Omit<Required<UpdateModuleInput>, 'content'>,
    Omit<Required<ModuleJSON>, 'name'> {
  moduleName: string
}

export const EditModuleFetch: FunctionComponent<{ id: string }> = ({ id }) => {
  const { data, loading } = useGetModuleQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const contentJson = useMemo(() => {
    if (data?.module?.content) {
      return JSON.parse(data.module.content) as ModuleJSON
    }
    return undefined
  }, [data])

  return loading ? (
    <BusyCircular isBusy={loading} delayMs={200} />
  ) : data?.whoami?.id !== superUserID ? (
    <NoPermission />
  ) : (
    <EditModuleForm
      id={id}
      data={{
        backgroundUrl: contentJson?.backgroundUrl ?? '',
        color: contentJson?.color ?? '',
        description: data?.module?.description ?? '',
        imageUrl: data?.module?.imageUrl ?? '',
        moduleName: contentJson?.name ?? '',
        name: data?.module?.name ?? '',
        pages: contentJson?.pages ?? [],
      }}
    />
  )
}

export const EditModuleForm: FunctionComponent<{
  id: string
  data: UpdateModuleFormFields
}> = ({ id, data }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [updateModule, { loading: mutationLoading }] = useUpdateModuleMutation()
  const { errors, control, handleSubmit, watch } = useForm<
    UpdateModuleFormFields
  >({
    defaultValues: data,
    validationSchema: moduleValidation,
  })

  const onSubmit: OnSubmit<UpdateModuleFormFields> = async (data) => {
    const { name, description, imageUrl, moduleName, ...moduleData } = data
    const key = enqueueSnackbar('Updating Module', { persist: true })

    try {
      const response = await updateModule({
        variables: {
          id,
          changes: {
            name,
            description,
            imageUrl,
            content: JSON.stringify({ ...moduleData, name: moduleName }),
          },
        },
      })

      if (key) {
        closeSnackbar(key)
      }

      if (response.data?.updateModule?.id) {
        enqueueSnackbar('Module Updated', { variant: 'success' })
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' })
    }
  }

  return (
    <Container maxWidth="lg">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid container spacing={3} item direction="column" xs={6}>
            <Grid item>
              <Controller
                as={
                  <TextField
                    disabled={mutationLoading}
                    fullWidth
                    label="Name"
                    variant="outlined"
                  />
                }
                name="name"
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    disabled={mutationLoading}
                    fullWidth
                    label="Description"
                    multiline
                    rowsMax={4}
                    variant="outlined"
                  />
                }
                name="description"
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    disabled={mutationLoading}
                    fullWidth
                    label="Image URL"
                    variant="outlined"
                  />
                }
                name="imageUrl"
                control={control}
              />
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    disabled={mutationLoading}
                    fullWidth
                    label="Module name"
                    variant="outlined"
                  />
                }
                name="moduleName"
                control={control}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            {Object.keys(errors).length > 0 ? (
              <Box mb={2}>
                <Alert severity="error">Validation Errors Detected</Alert>
              </Box>
            ) : null}
            <Button
              size="large"
              variant="outlined"
              type="submit"
              disabled={mutationLoading}
            >
              Apply Changes
            </Button>
          </Grid>
          <Grid item xs={12}>
            <ContentPagesField control={control} name="pages" watch={watch} />
          </Grid>
        </Grid>
      </form>
    </Container>
  )
}
